export const allNotifications = [
  {
    notificationId: 1,
    notificationText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    notificationTime: "1h Ago",
  },
  {
    notificationId: 2,
    notificationText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    notificationTime: "1h Ago",
  },
  {
    notificationId: 3,
    notificationText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    notificationTime: "1h Ago",
  },
  {
    notificationId: 4,
    notificationText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    notificationTime: "1h Ago",
  },
  {
    notificationId: 5,
    notificationText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    notificationTime: "1h Ago",
  },
];
