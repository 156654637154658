export const modesMonthList = [
  {
    label: "Jan",
    value: "1",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Feb",
    value: "2",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Mar",
    value: "3",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Apr",
    value: "4",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "May",
    value: "5",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Jun",
    value: "6",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Jul",
    value: "7",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Aug",
    value: "8",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Sep",
    value: "9",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Oct",
    value: "10",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Nov",
    value: "11",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "Dec",
    value: "12",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
];
