import styled from "@emotion/styled";
import { themePalette } from "../../../constants/Theme/colors";

export const ScrollBox = styled.div(({ theme }) => {
  return {
    height: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& .MuiBox-root .MuiBox-root:last-child .MuiDivider-root": {
      display: "none",
    },
  };
});

export const DatePickerText = styled.div(({ theme }) => {
  return {
    "& .MuiFormLabel-root": {
      fontSize: 16,
    },

    "& .MuiInputBase-root": {
      height: 42,
    },

    "& .MuiOutlinedInput-root": {
      fontFamily: '"ArchivoMedium", "sans-serif" !important',
      background: themePalette.palette.common.white,
      width: "190px",
    },

    "& .MuiOutlinedInput-root fieldset": {
      border: `2px solid ${themePalette.palette.common.dropdownBorder}`,
    },

    "& .rdrMonthAndYearPickers": {
      fontFamily: "'AsapRegular', sans-serif",
      fontSize: 16,
      color: themePalette.palette.text.greenValue,
    },

    "& .rdrCalendarWrapper": {
      position: "absolute",
      zIndex: 1,
      transform: "translate(-43%, 10px)",
    },

    "& .rdrMonthAndYearWrapper": {
      paddingTop: 0,
    },

    "& .rdrPprevButton i": {
      borderColor: `transparent ${themePalette.palette.primary.main} transparent transparent`,
    },

    "& .rdrNextButton i": {
      borderColor: `transparent transparent transparent ${themePalette.palette.primary.main}`,
    },

    "& .rdrNextPrevButton": {
      background: "none",
    },

    "& .rdrMonthName": {
      fontFamily: "'AsapRegular', sans-serif",
      fontSize: 15,
      color: themePalette.palette.text.darkSecondary,
      padding: "0 8px 0 8px",
    },

    "& .rdrWeekDays": {
      fontFamily: "'AsapMedium', sans-serif",
      fontSize: 15,

      "& .rdrWeekDay": {
        color: themePalette.palette.text.darkSecondary,
      },
    },

    "& .rdrDay": {
      fontFamily: "'AsapMedium', sans-serif",
      fontSize: 15,
    },

    "& .rdrDay .rdrDayNumber span": {
      color: themePalette.palette.common.notificationTime,
    },

    "& .rdrDayPassive .rdrDayNumber span": {
      color: `${themePalette.palette.common.nextMonthDatesColor} !important`,
    },

    "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span": {
      color: themePalette.palette.common.notificationTime,
    },

    "& .rdrStartEdge": {
      background: themePalette.palette.primary.main,
    },

    "& .rdrEndEdge": {
      background: themePalette.palette.primary.main,
    },
  };
});
