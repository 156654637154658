import axios from "axios";
import * as url from "../utils/urls";

export default {
  adminLogin: params => {
    return axios.post(url.loginUrl(), params);
  },

  forgotPassword: params => {
    return axios.post(url.forgotPasswordUrl(), params);
  },

  verifyForgotToken: verifyToken => {
    return axios.post(url.verifyForgotTokenUrl(verifyToken));
  },

  resetPassword: params => {
    return axios.post(url.resetPasswordUrl(), params);
  },

  changePassword: params => {
    return axios.post(url.changePasswordUrl(), params);
  },

  getUsersList: (rowsPerPage, page, sortDirection) => {
    return axios.get(url.fetchUserListUrl(rowsPerPage, page, sortDirection));
  },

  singleUser: userId => {
    return axios.get(url.singleUserUrl(userId));
  },

  changeUserActiveStatus: params => {
    return axios.post(url.userActiveStatus(), params);
  },
  getFAQList: () => {
    return axios.get(url.fetchFAQListUrl());
  },

  addFAQ: params => {
    return axios.post(url.addFAQUrl(), params);
  },

  updateFAQ: params => {
    return axios.post(url.updateFAQUrl(), params);
  },

  deleteFAQ: params => {
    return axios.post(url.deleteFAQUrl(), params);
  },

  updateFAQPosition: params => {
    return axios.post(url.updateFAQPositionUrl(), params);
  },

  topPerformers: () => {
    return axios.get(url.topPerformersUrl());
  },

  searchUser: params => {
    return axios.post(url.searchUserUrl(), params);
  },

  dashboardChart: params => {
    return axios.post(url.dashboardChartUrl(), params);
  },

  modeChart: params => {
    return axios.post(url.modeChartUrl(), params);
  },

  carOwners: () => {
    return axios.post(url.carOwnersUrl());
  },

  pointsChart: params => {
    return axios.post(url.pointsChartUrl(), params);
  },

  getTotalUsersAndTrees: () => {
    return axios.get(url.totalUsersAndTrees());
  },

  getTodaysStats: () => {
    return axios.get(url.todaysStats());
  },
};
