import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Button,
  FormControl,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import services from "../../services";
import { PasswordFieldWrapper } from "./styles";
import { isValidPassword } from "../../utils/inputvalidation";
import { messages } from "../../constants/message/messages";
import theme from "../../constants/Theme";
import CommonDialog from "../../common/commonDialog/CommonDialog";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const PasswordResetPage = () => {
  const [loader, setLoader] = useState(false);
  const [values, setValues] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    error: true,
    isNewPasswordError: false,
    isOldPasswordError: false,
    isConfirmPasswordError: false,
    newPasswordErrorMsg: "",
    oldPasswordErrorMsg: "",
    confirmPasswordErrorMsg: "",
  });
  const [isPreviewShown, setPreviewShown] = useState(false);

  const handleClickClose = () => {
    setPreviewShown(false);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const userInfo = useSelector(state => state.auth.userData);
  const adminEmail = userInfo[0]["email"];

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoader(true);
      let { newPassword, oldPassword } = values;
      let params = {
        email: adminEmail,
        oldPassword: oldPassword.trim(),
        newPassword: newPassword.trim(),
      };

      services
        .changePassword(params)
        .then(res => {
          setLoader(false);
          setPreviewShown(true);
          setValues({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        })
        .catch(error => console.log(error));
    }
  };

  const validate = () => {
    let { newPassword, oldPassword, confirmPassword } = values;

    if (oldPassword.trim() === "") {
      handleChangeValue("isOldPasswordError", true);
      handleChangeValue("oldPasswordErrorMsg", messages.passwordRequired);
      return false;
    } else if (!isValidPassword(oldPassword)) {
      handleChangeValue("isOldPasswordError", true);
      handleChangeValue("oldPasswordErrorMsg", messages.validPasswordRequired);
      return false;
    }
    if (newPassword.trim() === "") {
      handleChangeValue("isNewPasswordError", true);
      handleChangeValue("newPasswordErrorMsg", messages.passwordRequired);
      return false;
    } else if (!isValidPassword(newPassword)) {
      handleChangeValue("isNewPasswordError", true);
      handleChangeValue("newPasswordErrorMsg", messages.validPasswordRequired);
      return false;
    } else if (oldPassword === newPassword) {
      handleChangeValue("isNewPasswordError", true);
      handleChangeValue("newPasswordErrorMsg", messages.samePassword);
      return false;
    }

    if (confirmPassword.trim() === "") {
      handleChangeValue("isConfirmPasswordError", true);
      handleChangeValue("confirmPasswordErrorMsg", messages.passwordRequired);
      return false;
    } else if (!isValidPassword(confirmPassword)) {
      handleChangeValue("isConfirmPasswordError", true);
      handleChangeValue(
        "confirmPasswordErrorMsg",
        messages.validPasswordRequired
      );
      return false;
    } else if (confirmPassword !== newPassword) {
      handleChangeValue("isConfirmPasswordError", true);
      handleChangeValue(
        "confirmPasswordErrorMsg",
        messages.passwordNotMatching
      );
      return false;
    } else {
      return true;
    }
  };

  return (
    <DashboardLayout>
      <Backdrop
        sx={{
          color: theme => theme.palette.common.white,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          p: 1,
          pt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
        }}
      >
        <Stack>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Stack sx={styles.centeredContent}>
                <Box
                  sx={{
                    width: 1,
                    maxWidth: theme.breakpoints.values.lg,
                  }}
                >
                  <Grid container>
                    <Grid item xl={6} lg={5} md={8} sm={12} xs={12}>
                      <Stack direction="column" sx={{ width: 1 }}>
                        <PasswordFieldWrapper>
                          <form onSubmit={handleSubmit}>
                            <FormControl sx={{ width: 1 }}>
                              <Typography
                                variant="heading_regular"
                                sx={{
                                  mb: "5px",
                                  mt: 2,
                                  color: theme =>
                                    theme.palette.text.darkSecondary,
                                }}
                              >
                                Old Password
                              </Typography>
                              <TextField
                                variant="outlined"
                                type="password"
                                value={values.oldPassword}
                                onFocus={() => {
                                  handleChangeValue(
                                    "isOldPasswordError",
                                    false
                                  );
                                  handleChangeValue("oldPasswordErrorMsg", "");
                                }}
                                onChange={handleChange("oldPassword")}
                                error={values.isOldPasswordError}
                                helperText={values.oldPasswordErrorMsg}
                                placeholder="Enter old password here"
                                sx={{ mb: 4 }}
                              />

                              <Typography
                                variant="heading_regular"
                                sx={{
                                  mb: "5px",
                                  color: theme =>
                                    theme.palette.text.darkSecondary,
                                }}
                              >
                                New Password
                              </Typography>
                              <TextField
                                variant="outlined"
                                type="password"
                                value={values.newPassword}
                                onFocus={() => {
                                  handleChangeValue(
                                    "isNewPasswordError",
                                    false
                                  );
                                  handleChangeValue("newPasswordErrorMsg", "");
                                }}
                                onChange={handleChange("newPassword")}
                                error={values.isNewPasswordError}
                                helperText={values.newPasswordErrorMsg}
                                placeholder="Enter new password here"
                                sx={{ mb: 2 }}
                              />

                              <Typography
                                variant="heading_regular"
                                sx={{
                                  mb: "5px",
                                  mt: 2,
                                  color: theme =>
                                    theme.palette.text.darkSecondary,
                                }}
                              >
                                Confirm Password
                              </Typography>
                              <TextField
                                variant="outlined"
                                type="password"
                                value={values.confirmPassword}
                                fullWidth
                                onFocus={() => {
                                  handleChangeValue(
                                    "isConfirmPasswordError",
                                    false
                                  );
                                  handleChangeValue(
                                    "confirmPasswordErrorMsg",
                                    ""
                                  );
                                }}
                                onChange={handleChange("confirmPassword")}
                                error={values.isConfirmPasswordError}
                                helperText={values.confirmPasswordErrorMsg}
                                placeholder="Enter confirm password here"
                                sx={{ mb: 6 }}
                              />
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ py: 3 }}
                              >
                                Change Password
                              </Button>
                            </FormControl>
                          </form>
                        </PasswordFieldWrapper>
                      </Stack>

                      <CommonDialog
                        open={isPreviewShown}
                        title={"Password Changed Successfully!"}
                        subtext={"You have changed the password successfully."}
                        iconSpacing={"24px"}
                        dialogSize={"xs"}
                        dialogIcon={
                          <CheckCircleOutlinedIcon
                            sx={{
                              fontSize: 28,
                              color: theme => theme.palette.primary.main,
                              opacity: 0.8,
                            }}
                          />
                        }
                        dialogAction={
                          <React.Fragment>
                            <Button
                              variant="contained"
                              sx={{ fontSize: 19, py: 3 }}
                              onClick={handleClickClose}
                            >
                              Close
                            </Button>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={7}
                      md={4}
                      sm={12}
                      xs={12}
                      hidden
                    ></Grid>
                  </Grid>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </DashboardLayout>
  );
};

export default PasswordResetPage;
