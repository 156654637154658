import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import ROUTES_PATH from "../constants/routes";

export const PublicRoute = () => {
  const authData = useSelector(state => state?.auth?.isLoggedIn);
  if (authData) {
    return <Navigate to={ROUTES_PATH.DASHBOARD + "/dashboard"} replace />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};

export const ProtectedRoute = () => {
  const authData = useSelector(state => state?.auth?.isLoggedIn);
  if (!authData) {
    return <Navigate to={ROUTES_PATH.LOG_IN} replace />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};
