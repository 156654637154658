import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    height: "64px",
  },
  tooltip: {
    marginTop: `${0} !important`,
  },
}));
