import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Button,
  TextField,
  Container,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { messages } from "../../constants/message/messages";
import { themePalette } from "../../constants/Theme/colors";
import { PasswordFieldWrapper } from "../ChangePassword/styles";
import login_poster from "../../assets/images/City-driver-pana.svg";
import { isValidEmail, isValidPassword } from "../../utils/inputvalidation";
import services from "../../services";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/slice/auth-slice";
import { Block } from "@mui/icons-material";
import VerifyEmail from "../VerifyEmail";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LogInPage = () => {
  const [values, setValues] = React.useState({
    password: "",
    email: "",
    showPassword: false,
    error: true,
    isEmailError: false,
    isPasswordError: false,
    emailErrorMsg: "",
    passwordErrorMsg: "",
  });
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [showVerify, setShowVerify] = React.useState(false);
  const [urlToRedirect, setUrlToRedirect] = React.useState("");
  const [tokenMobile, setTokenMobile] = React.useState("");
  const [keyMobile, setKeyMobile] = React.useState("");

  useEffect(() => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const tokenVal = urlParams.get("token");
    const keyVal = urlParams.get("key");

    if (tokenVal && keyVal) {
      setTokenMobile(tokenVal);
      setKeyMobile(keyVal);
      setShowVerify(true);
      setUrlToRedirect(
        `https://admin.dev.travelrite.org?token=${tokenVal}&key=${keyVal}`
      );
    }
  }, []);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      let { email, password } = values;
      let params = {
        email: email.trim(),
        password: password.trim(),
        isAdmin : true
      };
      setLoader(true);
      services
        .adminLogin(params)
        .then(response => {
          if (response?.data?.statusCode === 200) {
            const token = response?.data?.data?.authToken;
            const emailAddress = response?.data?.data?.userData?.email;
            const fullName =
              response?.data?.data?.userData?.firstName +
              " " +
              response?.data?.data?.userData?.lastName;

            dispatch(
              authActions.loggedInUser({
                emailAddress,
                token,
                fullName,
              })
            );
            dispatch(authActions.validToken({ currToken: true }));
            setLoader(false);
          } else {
            setOpen(true);
            setLoader(false);
          }
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            setOpen(true);
            setLoader(false);
          }
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
    let { email, password } = values;
    if (email.trim() === "") {
      handleChangeValue("isEmailError", true);
      handleChangeValue("emailErrorMsg", messages.emailRequired);
      return false;
    } else if (!isValidEmail(email)) {
      handleChangeValue("isEmailError", true);
      handleChangeValue("emailErrorMsg", messages.validEmailRequired);
      return false;
    } else if (password.trim() === "") {
      handleChangeValue("isPasswordError", true);
      handleChangeValue("passwordErrorMsg", messages.passwordRequired);
      return false;
    } else if (!isValidPassword(password)) {
      handleChangeValue("isPasswordError", true);
      handleChangeValue("passwordErrorMsg", messages.validPasswordRequired);
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: theme => theme.palette.common.white,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {showVerify ? (
        <VerifyEmail
          url={urlToRedirect}
          tokenMobile={tokenMobile}
          keyMobile={keyMobile}
        />
      ) : (
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            display={{
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            }}
          >
            <Box
              sx={{
                background: themePalette.palette.secondary.main,
                minHeight: "100vh",
                display: "grid",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                flexFlow: "row wrap",
                width: 1,
                webkitBoxPack: "center",
                justifyContent: "center",
              }}
            >
              <Container maxWidth="sm">
                <Box
                  sx={{
                    px: { xl: 6, lg: 6, md: 6 },
                    pt: { xl: 7, lg: 7, md: 5 },
                    pb: { xl: 8, lg: 8, md: 5 },
                  }}
                  variant="heading1_xlarge"
                >
                  <Typography
                    sx={{
                      color: themePalette.palette.primary.main,
                    }}
                    variant="heading1_xlarge"
                  >
                    TravelRite
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "16px",
                      backgroundColor: "background.main",
                      display: "block",
                      m: "0 auto",
                      width: "63%",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url(${login_poster})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: "340px",
                      }}
                    ></Box>
                  </Box>

                  <Box sx={{ mt: 8 }}>
                    <Typography
                      variant="heading2"
                      sx={{
                        color: "text.main",
                      }}
                    >
                      Track Users, Emissions etc.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "block",
                      m: "0 auto",
                      width: "80%",
                      marginTop: 2,
                      marginBottom: 7,
                    }}
                  >
                    <Typography
                      variant="body_large"
                      sx={{
                        color: themePalette.palette.text.secondary,
                      }}
                    >
                      Lorem ipsum dolor sit amet. In lobortis ex id ante
                      maximus, quis varius elit suscipit.
                    </Typography>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sx={{
              backgroundColor: themePalette.palette.background.tertiary,
              minHeight: "100vh",
              display: "grid",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              flexFlow: "column wrap",
              width: 1,
              webkitBoxPack: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: themePalette.palette.primary.main,
                my: 3,
                display: { xs: "block", md: "none" },
              }}
              variant="heading1_xlarge"
            >
              TravelRite
            </Typography>

            <Container maxWidth="sm" sx={{ mt: { xs: 2, md: 0 } }}>
              <Box
                sx={{
                  pt: { xl: 2, lg: 2 },
                  pb: { xl: 0, lg: 0, md: 0, sm: 6, xs: 5 },
                  pl: { xl: 9, lg: 9, md: 7, sm: 0, xs: 2 },
                  pr: { xl: 13, lg: 13, md: 7, sm: 0, xs: 2 },
                }}
              >
                <Typography
                  sx={{ color: themePalette.palette.text.secondary, mb: 1 }}
                  variant="body_large"
                  component="div"
                >
                  Welcome
                </Typography>
                <Typography
                  sx={{ mb: 5 }}
                  variant="heading3_large"
                  color="text.main"
                  component="div"
                >
                  Log in into your account
                </Typography>

                <form onSubmit={handleSubmit}>
                  <FormControl variant="standard" sx={{ width: 1 }}>
                    <PasswordFieldWrapper>
                      <Box sx={{ mb: 1 }}>
                        <Typography
                          color="text.darkSecondary"
                          variant="heading_regular"
                        >
                          Email Address
                        </Typography>
                      </Box>
                      <TextField
                        variant="outlined"
                        placeholder="Enter email address here"
                        sx={{ mb: 3 }}
                        type="email"
                        value={values.email}
                        fullWidth
                        onFocus={() => {
                          handleChangeValue("isEmailError", false);
                          handleChangeValue("emailErrorMsg", "");
                        }}
                        onChange={handleChange("email")}
                        error={values.isEmailError}
                        helperText={values.emailErrorMsg}
                      />

                      <Box sx={{ mb: 1 }}>
                        <Typography
                          color="text.darkSecondary"
                          variant="heading_regular"
                        >
                          Password
                        </Typography>
                      </Box>

                      <TextField
                        variant="outlined"
                        placeholder="Enter password here"
                        sx={{ mb: 2 }}
                        type="password"
                        value={values.password}
                        onChange={handleChange("password")}
                        fullWidth
                        error={values.isPasswordError}
                        helperText={values.passwordErrorMsg}
                        onFocus={() => {
                          handleChangeValue("isPasswordError", false);
                          handleChangeValue("passwordErrorMsg", "");
                        }}
                      />
                    </PasswordFieldWrapper>

                    <Link to={"/forgotpassword"}>
                      <Box sx={{ textAlign: "right", mb: 5 }}>
                        <Typography
                          color="text.blueValue"
                          variant="body_medium"
                        >
                          Forgot Password?
                        </Typography>
                      </Box>
                    </Link>

                    <Button type="submit" variant="contained">
                      Sign In
                    </Button>
                  </FormControl>
                </form>
              </Box>
            </Container>

            <Snackbar
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {messages.invalidCredentials}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LogInPage;
