import {
  AppBar,
  Box,
  Typography,
  IconButton,
  Toolbar,
  Avatar,
  Tooltip,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { themePalette } from "../../../constants/Theme/colors";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { useState } from "react";

const Topbar = ({ isMobile, setIsMobile }) => {
  const [tab, setTab] = useState("");
  const handleDrawerToggle = () => {
    setIsMobile(!isMobile);
  };

  const userInfo = useSelector(state => state?.auth?.userData);
  const name = userInfo[0]["fullName"];
  const firstLetters = name
    .split(" ")
    .map(word => word[0])
    .join("");

  const location = useLocation();
  const formatTab = () => {
    const text = location.pathname.split("/");
    const newText = text[2];
    const splitCamelCase = newText.replace(/([a-z])([A-Z])/g, "$1 $2");
    const new_Text =
      splitCamelCase.charAt(0).toUpperCase() + splitCamelCase.slice(1);
    if (new_Text === "Faq") {
      setTab("FAQ");
    } else {
      setTab(new_Text);
    }
    //setTab(new_Text);
  };
  useEffect(() => {
    formatTab();
  }, []);
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: { lg: "calc(100% - 238px) !important", xs: "100% !important" },
        ml: { lg: "238px" },
        mt: 0,
        backgroundColor: "background.main",
        color: "text.primary",
        maxHeight: "64px",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <Menu />
        </IconButton>
        <Typography
          sx={
            tab === "Dashboard"
              ? { flexGrow: 1 }
              : { flexGrow: 1, color: themePalette.palette.primary.main }
          }
          variant="heading3"
        >
          {tab}
        </Typography>
        <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center", mr: 2 }}>
          {/*<Box>
            <Tooltip
              title={
                <Typography
                  fontSize={15}
                  fontFamily={"'ArchivoMedium', sans-serif"}
                >
                  Coming Soon
                </Typography>
              }
              arrow
            >
              <NotificationsNoneIcon
                sx={{
                  //color: themePalette.palette.primary.main,
                  color: themePalette.palette.text.secondary,
                  fontSize: 28,
                  mr: 2,
                  display: "inline-block",
                  position: "relative",
                  top: "-10px",
                }}
              />
            </Tooltip>

            <Box
              sx={theme => ({
                display: "inline-block",
                justifyContent: "center",
                alignItems: "center",
                borderRight: `2px solid ${theme.palette.common.headerDivider}`,
                mr: "20px",
                mt: 1,
                height: "45px",
              })}
            ></Box>
          </Box>*/}
          <Avatar
            sx={{
              my: 2,
              width: "38px",
              height: "38px",
              backgroundColor:
                themePalette.palette.common.profileIconBackground,
              color: themePalette.palette.common.profileIconColor,
            }}
          >
            <Typography variant="heading4" sx={{ textTransform: "uppercase" }}>
              {firstLetters}
            </Typography>
          </Avatar>
          <Typography
            variant="heading_regular"
            sx={{ ml: 2, textTransform: "capitalize" }}
          >
            {userInfo[0]["fullName"]}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
