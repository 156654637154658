import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeButtons = createTheme(themePalette, {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: "contained",
        color: "primary",
      },
      styleOverrides: {
        root: {
          borderRadius: "4px",
          fontFamily: '"ArchivoMedium", "sans-serif"',
          fontSize: "16px",
          lineHeight: "21px",
          width: "100%",
          height: "48px",
        },
      },
      variants: [
        {
          props: {
            variant: "contained",
            color: "primary",
          },

          style: {
            //fontSize: "17px !important",
            backgroundColor: themePalette.palette.primary.main,
            color: themePalette.palette.common.white,
            textTransform: "capitalize",
          },
        },

        {
          props: { variant: "outlined", color: "error" },
          style: {
            fontFamily: '"ArchivoMedium", "sans-serif"',
            textTransform: "capitalize",
            fontSize: 18,
            border: `1px solid ${themePalette.palette.button.secondary}`,
            backgroundColor: "unset !important",
            color: themePalette.palette.button.secondary,
            "&:hover": {
              backgroundColor: `${themePalette.palette.button.secondary} !important`,
              color: themePalette.palette.common.white,
              border: `1px solid ${themePalette.palette.button.secondary}`,
            },
          },
        },

        {
          props: { variant: "outlined", color: "primary" },
          style: {
            fontFamily: '"ArchivoMedium", "sans-serif"',
            textTransform: "capitalize",
            fontSize: 18,
            border: `1px solid ${themePalette.palette.primary.main}`,
            color: themePalette.palette.primary.main,
            "&:hover": {
              backgroundColor: themePalette.palette.primary.main,
              color: themePalette.palette.common.white,
              border: `1px solid ${themePalette.palette.primary.main}`,
            },
          },
        },

        {
          props: { variant: "text", color: "error" },
          style: {
            fontFamily: '"ArchivoRegular", "sans-serif"',
            textTransform: "capitalize",
            fontSize: 16,
            color: themePalette.palette.button.secondary,
          },
        },

        {
          props: { variant: "text", color: "primary" },
          style: {
            fontFamily: '"ArchivoRegular", "sans-serif"',
            textTransform: "capitalize",
            fontSize: 16,
            color: themePalette.palette.primary.main,
          },
        },
      ],
    },
  },
});
