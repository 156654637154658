import { useState, useEffect, useRef } from "react";
import useDebounce from "./usedebounce";
import * as React from "react";
import { styled } from "@mui/material/styles";
import theme from "../../constants/Theme";
import services from "../../services";
import ROUTES_PATH from "../../constants/routes";
import { messages } from "../../constants/message/messages";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Tabs,
  Tab,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Snackbar,
  Backdrop,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TablePaginationActions from "../../components/Table/TablePaginationActions";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { tableCols } from "../../constants/Table JSON/TableColumns";
import defaultuser from "./assets/defaultuser.png";
import {
  GridWrapper,
  SearchFieldWrapper,
  DropdownWrapper,
  BoxWrapper,
  UserEmail,
} from "./styles";
import MuiAlert from "@mui/material/Alert";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { SkeletonTable } from "./skeleton/skeletion";
import CommonDialog from "../../common/commonDialog/CommonDialog";
import BlockIcon from "@mui/icons-material/Block";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Function for handling Tab Panel Properties
const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

// Function for assigning properties to Tab Panel
const tabProps = index => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

// Styles for Table Cell and Table Row being displayed
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.tableEvenRow,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Users = () => {
  const [value, setValue] = React.useState(0);
  const [searchedVal, setSearchedVal] = useState("");
  const [blockedSearchedVal, setBlockedSearchedVal] = useState("");
  const [activeUsers, setActiveUsers] = useState([""]);
  const [blockedUsers, setBlockedUsers] = useState([""]);
  const [results, setResults] = useState([]);
  const [blockResults, setBlockResults] = useState([]);
  const [loader, setLoader] = useState(false);
  const [blockUserDialog, setBlockUserDialog] = useState({
    id: "",
    status: false,
  });
  const [unblockUserDialog, setUnblockUserDialog] = useState({
    id: "",
    status: false,
  });
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [blockedPage, setBlockedPage] = React.useState(0);
  const [blockedRowsPerPage, setBlockedRowsPerPage] = React.useState(10);

  const [sortDirection, setSortDirection] = React.useState("asc");
  const [errorSnackbar, setErrorSnackBar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalBlockedUsers, setTotalBlockedUsers] = useState(0);

  const [imageLoading, setImageLoading] = useState(true);
  const [loadSearchImage, setLoadSearchImage] = useState(true);

  const [values, setValues] = React.useState({
    reasonText: "",
    error: true,
    isReasonError: false,
    reasonErrorMsg: "",
  });

  const debouncedSearchTerm = useDebounce(searchedVal, 500);
  const debouncedBlockSearch = useDebounce(blockedSearchedVal, 500);

  const handleReasonChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleReasonChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const navigate = useNavigate();

  const clearSearch = () => {
    setSearchedVal("");
  };

  const clearBlockedSearch = () => {
    setBlockedSearchedVal("");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleBlockedChangePage = (event, newPage) => {
    setBlockedPage(newPage);
  };

  const counter = useRef(0);

  const imageLoad = user => {
    counter.current += 1;
    if (counter.current >= user.length - 1) {
      setImageLoading(false);
      counter.current = 0;
    }
  };

  const searchImageLoad = () => {
    setLoadSearchImage(false);
  };

  const handleSingleUser = user => {
    setLoader(true);
    const userId = user?.user?.id;
    navigate(ROUTES_PATH.DASHBOARD + "/users/profile", {
      state: { user: userId },
    });
    setLoader(false);
  };

  const handleSingleSearchedUser = user => {
    setLoader(true);
    const userId = user?.id;
    navigate(ROUTES_PATH.DASHBOARD + "/users/profile", {
      state: { user: userId },
    });
    setLoader(false);
  };

  const changeloadingStatus = statusState => {
    setLoadingStatus(statusState);
  };

  //handle blocking of users
  const handleBlock = id => {
    if (validate()) {
      changeloadingStatus(true);
      const filteredUsers = activeUsers.reduce(
        (acc, obj) => {
          if (obj.user.id === id) {
            acc.filtered.push(obj);
          } else {
            acc.unfiltered.push(obj);
          }

          return acc;
        },
        { filtered: [], unfiltered: [] }
      );

      let blockedUser = {
        id: `${id}`,
        status: true,
        reason: values.reasonText,
      };

      services.changeUserActiveStatus(blockedUser).then(res => {
        if (res.status === 200 || res.status === 201) {
          setActiveUsers(filteredUsers.unfiltered);

          const _newBlockedUser = filteredUsers.filtered[0];
          const newBlockedUser = {
            ..._newBlockedUser,
            user: { ..._newBlockedUser?.user, blocked: true },
          };
          setBlockedUsers(prevObjects => {
            const obj = [...prevObjects];
            obj.unshift(newBlockedUser);
            return obj;
          });

          setBlockUserDialog({ id: "", status: false });
          changeloadingStatus(false);
          setTotalActiveUsers(totalActiveUsers - 1);
          setTotalBlockedUsers(totalBlockedUsers + 1);
          setValues(prev => ({ ...prev, reasonText: "" }));
        } else {
          setErrorSnackBar(true);
          changeloadingStatus(false);
          setBlockUserDialog({
            id: "",
            status: false,
          });
        }
      });
    }
  };

  //handling unblocking of users
  const handleUnblock = id => {
    changeloadingStatus(true);
    const filteredUsers = blockedUsers.reduce(
      (acc, obj) => {
        if (obj?.user.id === id) {
          acc.filtered.push(obj);
        } else {
          acc.unfiltered.push(obj);
        }

        return acc;
      },
      { filtered: [], unfiltered: [] }
    );

    let unBlockedUser = {
      id: `${id}`,
      status: false,
    };

    services.changeUserActiveStatus(unBlockedUser).then(res => {
      if (res.status === 200 || res.status === 201) {
        setBlockedUsers(filteredUsers.unfiltered);

        const _newActiveUser = filteredUsers.filtered[0];
        const newActiveUser = {
          ..._newActiveUser,
          user: { ..._newActiveUser?.user, blocked: false },
        };
        setActiveUsers(prevObjects => {
          const obj = [...prevObjects];
          obj.unshift(newActiveUser);
          return obj;
        });
        setUnblockUserDialog({ id: "", status: false });
        changeloadingStatus(false);
        setTotalActiveUsers(totalActiveUsers + 1);
        setTotalBlockedUsers(totalBlockedUsers - 1);
      } else {
        setErrorSnackBar(true);
        changeloadingStatus(false);
        setUnblockUserDialog({
          id: "",
          status: false,
        });
      }
    });
  };

  const validate = () => {
    let { reasonText } = values;
    if (reasonText.trim() === "") {
      handleReasonChangeValue("isReasonError", true);
      handleReasonChangeValue("reasonErrorMsg", messages.reasonRequired);
      return false;
    } else if (reasonText.trim().length < 3) {
      handleReasonChangeValue("isReasonError", true);
      handleReasonChangeValue("reasonErrorMsg", messages.validReasonRequired);
      return false;
    } else if (reasonText.trim().length > 300) {
      handleReasonChangeValue("isReasonError", true);
      handleReasonChangeValue("reasonErrorMsg", messages.validReasonRequired);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const activeUser = [];

    if (value === 0) {
      setIsLoading(true);
      setImageLoading(true);
      services
        .getUsersList(rowsPerPage, page === 0 ? 1 : page + 1, sortDirection)
        .then(res => {
          res?.data?.data?.activeUsers.map(item => {
            activeUser.push(item);
          });

          setTotalActiveUsers(res?.data?.data?.activeUsersCount);
          setTotalBlockedUsers(res?.data?.data?.blockedUsersCount);

          setActiveUsers(activeUser);
          setIsLoading(false);
        })

        .catch(error => console.log(error));
    }
  }, [page, sortDirection, totalActiveUsers, totalBlockedUsers, value]);

  useEffect(() => {
    const blockUser = [];

    if (value === 1) {
      setIsLoading(true);
      setImageLoading(true);
      services
        .getUsersList(
          blockedRowsPerPage,
          blockedPage === 0 ? 1 : blockedPage + 1,
          sortDirection
        )
        .then(res => {
          res?.data?.data?.blockedUsers.map(item => {
            blockUser.push(item);
          });

          setBlockedUsers(blockUser);
          setIsLoading(false);
        })
        .catch(error => console.log(error));
    }
  }, [blockedPage, sortDirection, value]);

  useEffect(() => {
    const filteredActiveUsers = [];
    let params = {
      userName: debouncedSearchTerm,
      active: true,
    };

    if (debouncedSearchTerm) {
      setIsLoading(true);
      setLoadSearchImage(true);
      services
        .searchUser(params)
        .then(res => {
          res?.data?.data?.userList.map(item => {
            filteredActiveUsers.push(item);
          });

          setResults(filteredActiveUsers);
          setIsLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else if (debouncedSearchTerm === "") {
      setResults([]);
      setActiveUsers(activeUsers);
    }
  }, [debouncedSearchTerm, activeUsers]);

  useEffect(() => {
    const filteredBlockedUsers = [];
    let params = {
      userName: debouncedBlockSearch,
      active: false,
    };

    if (debouncedBlockSearch) {
      setIsLoading(true);
      setLoadSearchImage(true);
      services
        .searchUser(params)
        .then(res => {
          res?.data?.data?.userList.map(item => {
            filteredBlockedUsers.push(item);
          });
          setBlockResults(filteredBlockedUsers);
          setIsLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else if (debouncedBlockSearch === "") {
      setBlockResults([]);
      setBlockedUsers(blockedUsers);
    }
  }, [debouncedBlockSearch, blockedUsers]);

  return (
    <DashboardLayout>
      <Backdrop
        sx={{
          color: theme => theme.palette.common.white,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ minHeight: "100vh" }}>
        <Stack sx={styles.centeredContent}>
          <Stack
            pt={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 4 }}
            px={1}
            sx={{
              width: 1,
              maxWidth: theme.breakpoints.values.lg,
            }}
          >
            <GridWrapper>
              <Grid container spacing={2} sx={{ ml: 0 }}>
                <Grid
                  item
                  xl={5}
                  lg={6}
                  md={7}
                  sm={9}
                  xs={11}
                  sx={{
                    backgroundColor: theme =>
                      theme.palette.common.tabTableBackground,
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                  >
                    <Tab
                      label={"Active Users" + " (" + totalActiveUsers + ")"}
                      {...tabProps(0)}
                    />
                    <Tab
                      label={"Blocked Users" + " (" + totalBlockedUsers + ")"}
                      {...tabProps(1)}
                    />
                  </Tabs>
                </Grid>
                <Grid item xl={7} lg={6} md={6} sm={3} xs={6} hidden></Grid>
              </Grid>
            </GridWrapper>

            <BoxWrapper>
              <Box sx={{ mt: 3 }}>
                <GridWrapper>
                  <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <TabPanel value={value} index={0}>
                        <Box
                          sx={{
                            backgroundColor: theme =>
                              theme.palette.common.tabTableBackground,
                            px: 4,
                            pt: 4,
                            pb: 6,
                            borderRadius: "10px",
                          }}
                        >
                          <Box>
                            <Grid container spacing={3}>
                              <Grid item xl={4} lg={4} md={5} sm={5} xs={5}>
                                <SearchFieldWrapper>
                                  <Box sx={{ pl: 3 }}>
                                    <TextField
                                      placeholder="Search for Users"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      value={searchedVal}
                                      sx={{ my: 3 }}
                                      onChange={e =>
                                        setSearchedVal(e.target.value)
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <SearchOutlinedIcon />
                                          </InputAdornment>
                                        ),

                                        endAdornment: searchedVal && (
                                          <InputAdornment position="end">
                                            <ClearIcon
                                              onClick={clearSearch}
                                              sx={{ cursor: "pointer" }}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                </SearchFieldWrapper>
                              </Grid>
                              <Grid item xl={8} lg={8} md={7} sm={7} xs={7}>
                                <DropdownWrapper>
                                  <Box sx={{ mr: 4 }}>
                                    <FormControl
                                      sx={{
                                        my: 3,
                                        minWidth: "160px",
                                        float: "right",
                                        background: theme =>
                                          theme.palette.common.white,
                                      }}
                                      size="small"
                                    >
                                      <InputLabel id="sortUsersByRank">
                                        Sort by Rank
                                      </InputLabel>

                                      <Select
                                        labelId="sortUsersByRank"
                                        id="sortUsersByRank"
                                        label="Sort by Rank"
                                        defaultValue={sortDirection}
                                        onChange={e =>
                                          setSortDirection(e.target.value)
                                        }
                                      >
                                        <MenuItem
                                          value={"asc"}
                                          sx={{
                                            "&:hover": {
                                              color: theme =>
                                                theme.palette.primary.main,
                                            },
                                          }}
                                        >
                                          Low to High
                                        </MenuItem>
                                        <MenuItem
                                          value={"desc"}
                                          sx={{
                                            "&:hover": {
                                              color: theme =>
                                                theme.palette.primary.main,
                                            },
                                          }}
                                        >
                                          High to Low
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </DropdownWrapper>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box sx={{ overflow: "auto" }}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "table",
                                tableLayout: "fixed",
                              }}
                            >
                              <TableContainer
                                component={Paper}
                                sx={{
                                  backgroundColor: theme =>
                                    theme.palette.common.tabTableBackground,
                                  mr: 4,
                                  borderRadius: "10px",
                                  width: "unset",
                                }}
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {tableCols.map(colName => (
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colName}
                                        </StyledTableCell>
                                      ))}
                                      <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {results.length ? (
                                      results.map(user => (
                                        <StyledTableRow
                                          onClick={() =>
                                            handleSingleSearchedUser(user)
                                          }
                                          sx={{
                                            textDecoration: "unset !important",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            <List>
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    alt="User Icon"
                                                    onLoad={() =>
                                                      searchImageLoad()
                                                    }
                                                    src={
                                                      loadSearchImage
                                                        ? defaultuser
                                                        : user?.profileImage ||
                                                          defaultuser
                                                    }
                                                  />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary={
                                                    user?.firstName +
                                                    " " +
                                                    user?.lastName
                                                  }
                                                />
                                              </ListItem>
                                            </List>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {user?.userRank}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            <Tooltip
                                              title={
                                                user?.email ||
                                                "kevincaroll@gmail.com"
                                              }
                                              arrow
                                            >
                                              <UserEmail>
                                                {user?.email ||
                                                  "kevincaroll@gmail.com"}
                                              </UserEmail>
                                            </Tooltip>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {user?.carbonEmission + " Kg" ||
                                              "1 Kg"}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {user?.carbonOffset + " Kg" ||
                                              "1 Kg"}
                                          </StyledTableCell>
                                          {/*<StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {user?.carbonCredits}
                                          </StyledTableCell>*/}
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            sx={{ pr: 1 }}
                                          >
                                            <Button
                                              variant="text"
                                              color="error"
                                              sx={{ p: 3 }}
                                              onClick={e => {
                                                e.stopPropagation();
                                                setBlockUserDialog({
                                                  id: user?.id,
                                                  status: true,
                                                });
                                              }}
                                            >
                                              Block
                                            </Button>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))
                                    ) : isLoading ? (
                                      <SkeletonTable user="active" />
                                    ) : (debouncedSearchTerm &&
                                        results.length === 0) ||
                                      activeUsers.length === 0 ? (
                                      <StyledTableRow>
                                        <StyledTableCell colSpan={12}>
                                          <Typography
                                            variant="label_small"
                                            sx={{ px: 2, opacity: 0.7 }}
                                          >
                                            No Records Found!
                                          </Typography>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ) : (
                                      activeUsers.map(user => {
                                        if (user?.user?.blocked === false) {
                                          return (
                                            <StyledTableRow
                                              key={user?.id}
                                              onClick={() =>
                                                handleSingleUser(user)
                                              }
                                              sx={{
                                                textDecoration:
                                                  "unset !important",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <List>
                                                  <ListItem>
                                                    <ListItemAvatar>
                                                      <Avatar
                                                        sx={
                                                          imageLoading
                                                            ? {
                                                                display: "none",
                                                              }
                                                            : {
                                                                display:
                                                                  "block",
                                                              }
                                                        }
                                                        alt="User Icon"
                                                        onLoad={() =>
                                                          imageLoad(activeUsers)
                                                        }
                                                        src={
                                                          user?.user
                                                            ?.profileImage ||
                                                          defaultuser
                                                        }
                                                      />
                                                      <Skeleton
                                                        sx={
                                                          imageLoading
                                                            ? {
                                                                display:
                                                                  "block",
                                                              }
                                                            : {
                                                                display: "none",
                                                              }
                                                        }
                                                        variant="circular"
                                                        height="36px"
                                                        width="36px"
                                                        animation="wave"
                                                      />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                      primary={
                                                        user?.user?.firstName +
                                                        " " +
                                                        user?.user?.lastName
                                                      }
                                                    />
                                                  </ListItem>
                                                </List>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {user?.userRank}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <Tooltip
                                                  title={
                                                    user?.user?.email ||
                                                    "kevincaroll@gmail.com"
                                                  }
                                                  arrow
                                                >
                                                  <UserEmail>
                                                    {user?.user?.email ||
                                                      "kevincaroll@gmail.com"}
                                                  </UserEmail>
                                                </Tooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {user?.carbonEmission + " Kg" ||
                                                  "1 Kg"}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {user?.carbonOffset + " Kg" ||
                                                  "1 Kg"}
                                              </StyledTableCell>
                                              {/*<StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {user?.carbonCredits}
                                              </StyledTableCell>*/}
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                                sx={{ pr: 1 }}
                                              >
                                                <Button
                                                  variant="text"
                                                  color="error"
                                                  sx={{ p: 3 }}
                                                  onClick={e => {
                                                    e.stopPropagation();
                                                    setBlockUserDialog({
                                                      id: user?.user.id,
                                                      status: true,
                                                    });
                                                  }}
                                                >
                                                  Block
                                                </Button>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      })
                                    )}
                                  </TableBody>

                                  {debouncedSearchTerm &&
                                  debouncedSearchTerm.length ? (
                                    ""
                                  ) : activeUsers.length === 0 ? (
                                    ""
                                  ) : (
                                    <TableFooter>
                                      <TableRow>
                                        <TablePagination
                                          colSpan={12}
                                          count={totalActiveUsers}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onPageChange={handleChangePage}
                                          rowsPerPageOptions={[]}
                                          ActionsComponent={
                                            TablePaginationActions
                                          }
                                        />
                                      </TableRow>
                                    </TableFooter>
                                  )}
                                </Table>
                              </TableContainer>
                            </Box>
                          </Box>
                        </Box>
                      </TabPanel>

                      <TabPanel value={value} index={1}>
                        <Box
                          sx={{
                            backgroundColor: theme =>
                              theme.palette.common.tabTableBackground,
                            px: 4,
                            pt: 4,
                            pb: 6,
                            borderRadius: "5px",
                          }}
                        >
                          <Box>
                            <Grid container spacing={3}>
                              <Grid item xl={4} lg={4} md={5} sm={5} xs={5}>
                                <SearchFieldWrapper>
                                  <Box sx={{ pl: 3 }}>
                                    <TextField
                                      placeholder="Search for Users"
                                      type="text"
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      value={blockedSearchedVal}
                                      sx={{ my: 3 }}
                                      onChange={e =>
                                        setBlockedSearchedVal(e.target.value)
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <SearchOutlinedIcon />
                                          </InputAdornment>
                                        ),

                                        endAdornment: blockedSearchedVal && (
                                          <InputAdornment position="end">
                                            <ClearIcon
                                              onClick={clearBlockedSearch}
                                              sx={{ cursor: "pointer" }}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                </SearchFieldWrapper>
                              </Grid>
                              <Grid item xl={8} lg={8} md={7} sm={7} xs={7}>
                                <DropdownWrapper>
                                  <Box sx={{ mr: 4 }}></Box>
                                </DropdownWrapper>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box sx={{ overflow: "auto" }}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "table",
                                tableLayout: "fixed",
                              }}
                            >
                              <TableContainer
                                component={Paper}
                                sx={{
                                  backgroundColor: theme =>
                                    theme.palette.common.tabTableBackground,
                                  mr: 4,
                                  borderRadius: "10px",
                                  width: "unset",
                                }}
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {tableCols.map(colName => (
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          {colName}
                                        </StyledTableCell>
                                      ))}
                                      <StyledTableCell></StyledTableCell>
                                      <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {blockResults.length ? (
                                      blockResults.map(user => {
                                        if (user?.blocked === true) {
                                          return (
                                            <StyledTableRow
                                              onClick={() =>
                                                handleSingleSearchedUser(user)
                                              }
                                              sx={{
                                                textDecoration:
                                                  "unset !important",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <List>
                                                  <ListItem>
                                                    <ListItemAvatar>
                                                      <Avatar
                                                        alt="User Icon"
                                                        onLoad={() =>
                                                          searchImageLoad()
                                                        }
                                                        src={
                                                          loadSearchImage
                                                            ? defaultuser
                                                            : user?.profileImage ||
                                                              defaultuser
                                                        }
                                                      />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                      primary={
                                                        user?.firstName +
                                                        " " +
                                                        user?.lastName
                                                      }
                                                    />
                                                  </ListItem>
                                                </List>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {"--"}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <Tooltip
                                                  title={
                                                    user?.email ||
                                                    "kevincaroll@gmail.com"
                                                  }
                                                  arrow
                                                >
                                                  <UserEmail>
                                                    {user?.email ||
                                                      "kevincaroll@gmail.com"}
                                                  </UserEmail>
                                                </Tooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {user?.carbonEmission + " Kg" ||
                                                  "1 Kg"}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {user?.carbonOffset + " Kg" ||
                                                  "1 Kg"}
                                              </StyledTableCell>
                                              {/*<StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {user?.carbonCredits}
                                              </StyledTableCell>*/}
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                                sx={{ pr: 1 }}
                                              >
                                                <Button
                                                  variant="text"
                                                  color="primary"
                                                  sx={{
                                                    p: 3,
                                                  }}
                                                  onClick={e => {
                                                    e.stopPropagation();
                                                    setUnblockUserDialog({
                                                      id: user?.id,
                                                      status: true,
                                                    });
                                                  }}
                                                >
                                                  Unblock
                                                </Button>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <Tooltip
                                                  title={user?.deletedReason}
                                                  arrow
                                                >
                                                  <InfoOutlinedIcon
                                                    style={{
                                                      position: "relative",
                                                      top: "2px",
                                                    }}
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                    }}
                                                  />
                                                </Tooltip>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          );
                                        }
                                      })
                                    ) : isLoading ? (
                                      <SkeletonTable user="blocked" />
                                    ) : (debouncedBlockSearch &&
                                        blockResults.length === 0) ||
                                      blockedUsers.length === 0 ? (
                                      <StyledTableRow>
                                        <StyledTableCell colSpan={12}>
                                          <Typography
                                            variant="label_small"
                                            sx={{ px: 2, opacity: 0.7 }}
                                          >
                                            {" "}
                                            No Records Found!
                                          </Typography>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ) : (
                                      blockedUsers.map(user => (
                                        <StyledTableRow
                                          onClick={() => handleSingleUser(user)}
                                          sx={{
                                            textDecoration: "unset !important",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            <List>
                                              <ListItem>
                                                <ListItemAvatar>
                                                  <Avatar
                                                    sx={
                                                      imageLoading
                                                        ? { display: "none" }
                                                        : { display: "block" }
                                                    }
                                                    alt="User Icon"
                                                    onLoad={() =>
                                                      imageLoad(blockedUsers)
                                                    }
                                                    src={
                                                      user?.user
                                                        ?.profileImage ||
                                                      defaultuser
                                                    }
                                                  />
                                                  <Skeleton
                                                    sx={
                                                      imageLoading
                                                        ? { display: "block" }
                                                        : { display: "none" }
                                                    }
                                                    variant="circular"
                                                    height="36px"
                                                    width="36px"
                                                    animation="wave"
                                                  />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary={
                                                    user?.user?.firstName +
                                                    " " +
                                                    user?.user?.lastName
                                                  }
                                                />
                                              </ListItem>
                                            </List>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {"--"}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            <Tooltip
                                              title={
                                                user?.user?.email ||
                                                "kevincaroll@gmail.com"
                                              }
                                              arrow
                                            >
                                              <UserEmail>
                                                {user?.user?.email ||
                                                  "kevincaroll@gmail.com"}
                                              </UserEmail>
                                            </Tooltip>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {user?.carbonEmission + " Kg" ||
                                              "1 Kg"}
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {user?.carbonOffset + " Kg" ||
                                              "1 Kg"}
                                          </StyledTableCell>
                                          {/*<StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            {user?.carbonCredits}
                                          </StyledTableCell>*/}
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                            sx={{ pr: 1 }}
                                          >
                                            <Button
                                              variant="text"
                                              color="primary"
                                              sx={{
                                                p: 3,
                                              }}
                                              onClick={e => {
                                                e.stopPropagation();
                                                setUnblockUserDialog({
                                                  id: user?.user.id,
                                                  status: true,
                                                });
                                              }}
                                            >
                                              Unblock
                                            </Button>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            component="th"
                                            scope="row"
                                          >
                                            <Tooltip
                                              title={user?.user?.deletedReason}
                                              arrow
                                            >
                                              <InfoOutlinedIcon
                                                style={{
                                                  position: "relative",
                                                  top: "2px",
                                                }}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                }}
                                              />
                                            </Tooltip>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      ))
                                    )}
                                  </TableBody>

                                  {debouncedBlockSearch &&
                                  debouncedBlockSearch.length ? (
                                    ""
                                  ) : blockedUsers.length === 0 ? (
                                    ""
                                  ) : (
                                    <TableFooter>
                                      <TableRow>
                                        <TablePagination
                                          colSpan={12}
                                          count={totalBlockedUsers}
                                          rowsPerPage={blockedRowsPerPage}
                                          page={blockedPage}
                                          onPageChange={handleBlockedChangePage}
                                          rowsPerPageOptions={[]}
                                          ActionsComponent={
                                            TablePaginationActions
                                          }
                                        />
                                      </TableRow>
                                    </TableFooter>
                                  )}
                                </Table>
                              </TableContainer>
                            </Box>
                          </Box>
                        </Box>
                      </TabPanel>
                    </Grid>
                  </Grid>
                </GridWrapper>
              </Box>
            </BoxWrapper>
          </Stack>
        </Stack>
        <CommonDialog
          open={blockUserDialog.status}
          title={"Block User"}
          subtext={"Are you sure you want to block the user?"}
          reasonTitle={
            <TextField
              id="outlined-multiline-static"
              label="Reason"
              multiline
              rows={2}
              inputProps={{ maxLength: 300 }}
              sx={{
                mt: 3,
                width: 1,

                "& .MuiFormHelperText-root": {
                  textAlign: values.reasonErrorMsg ? "left" : "right",
                  color: values.reasonErrorMsg
                    ? theme => theme.palette.error.main
                    : theme => theme.palette.text.secondary,
                },
              }}
              value={values.reasonText}
              onFocus={() => {
                handleReasonChangeValue("isReasonError", false);
                handleReasonChangeValue("reasonErrorMsg", "");
              }}
              onChange={handleReasonChange("reasonText")}
              error={values.isReasonError}
              helperText={
                values.reasonErrorMsg
                  ? values.reasonErrorMsg
                  : `${values.reasonText.length}/300`
              }
            />
          }
          iconSpacing={"24px"}
          dialogSize={"xs"}
          dialogIcon={
            <BlockIcon
              sx={{
                fontSize: 28,
                color: theme => theme.palette.primary.main,
                opacity: 0.8,
              }}
            />
          }
          dialogAction={
            <React.Fragment>
              <Button
                disabled={loadingStatus}
                variant="outlined"
                sx={{
                  border: `1px solid ${theme => theme.palette.primary.main}`,
                  background: theme => theme.palette.common.white,
                  fontSize: 18,
                  py: 3,
                  mr: 3,
                }}
                onClick={() => (
                  setBlockUserDialog({ id: "", status: false }),
                  setValues({
                    reasonText: "",
                    error: true,
                    isReasonError: false,
                    reasonErrorMsg: "",
                  })
                )}
              >
                No
              </Button>

              <Button
                onClick={() => {
                  handleBlock(blockUserDialog.id);
                }}
                variant="contained"
                sx={{ fontSize: 18, py: 3 }}
              >
                {loadingStatus ? (
                  <>
                    <CircularProgress
                      size="1rem"
                      color="inherit"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Yes
                  </>
                ) : (
                  "Yes"
                )}
              </Button>
            </React.Fragment>
          }
        />
        <CommonDialog
          open={unblockUserDialog.status}
          title={"Unblock User"}
          subtext={"Are you sure you want to unblock the user?"}
          iconSpacing={"24px"}
          dialogSize={"xs"}
          dialogIcon={
            <BlockIcon
              sx={{
                fontSize: 28,
                color: theme => theme.palette.primary.main,
                opacity: 0.8,
              }}
            />
          }
          dialogAction={
            <React.Fragment>
              <Button
                disabled={loadingStatus}
                variant="outlined"
                sx={{
                  border: `1px solid ${theme => theme.palette.primary.main}`,
                  background: theme => theme.palette.common.white,
                  fontSize: 18,
                  py: 3,
                  mr: 3,
                }}
                onClick={() => setUnblockUserDialog({ id: "", status: false })}
              >
                No
              </Button>

              <Button
                onClick={() => handleUnblock(unblockUserDialog.id)}
                variant="contained"
                sx={{ fontSize: 18, py: 3 }}
              >
                {loadingStatus ? (
                  <>
                    <CircularProgress
                      size="1rem"
                      color="inherit"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Yes
                  </>
                ) : (
                  "Yes"
                )}
              </Button>
            </React.Fragment>
          }
        />

        <Snackbar
          open={errorSnackbar}
          autoHideDuration={4000}
          onClose={() => setErrorSnackBar(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={() => setErrorSnackBar(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {messages.somethingWentWrong}
          </Alert>
        </Snackbar>
      </Box>
    </DashboardLayout>
  );
};

export default Users;
