import axios from "axios";
import { authActions } from "../redux/slice/auth-slice";

let store;

export const createInstanceandInjectStore = _store => {
  store = _store;

  const instance = axios.create({
    baseURL: `${window.location.protocol}//`,
  });

  instance.all = axios.all;
  instance.spread = axios.spread;

  axios.interceptors.request.use(
    config => {
      const accessToken = store?.getState()?.auth?.token.token;
      if (accessToken) {
        config.headers["Authorization"] = "Bearer " + accessToken;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      if (error?.response?.status === 401) {
        store?.dispatch(authActions.validToken({ currToken: false }));
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
