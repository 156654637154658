import React from "react";
import { Routes, Route } from "react-router-dom";
import ROUTES_PATH from "../constants/routes";
import DashboardDashboard from "../pages/dashboardDashboard/DashboardDashboard";
import Users from "../pages/Users";
import UserProfile from "../pages/Users/Profile";
import Notifications from "../pages/Notifications";
import ChangePassword from "../pages/ChangePassword";
import FAQList from "../pages/FAQ";
import FAQAdd from "../pages/FAQ/AddFAQ/index";
import FAQEdit from "../pages/FAQ/EditFAQ/index";
import Support from "../pages/Support";
import Resources from "../pages/Resources";
import { ProtectedRoute } from "./publicPrivateRoute";

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/dashboard"}
          element={<DashboardDashboard />}
        />
        <Route
          path={ROUTES_PATH.DASHBOARD + "/users"}
          element={<Users />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/users/profile"}
          element={<UserProfile />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/notification"}
          element={<Notifications />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/changePassword"}
          element={<ChangePassword />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/faq"}
          element={<FAQList />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/faq/add"}
          element={<FAQAdd />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/faq/update"}
          element={<FAQEdit />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/support"}
          element={<Support />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/resources"}
          element={<Resources />}
        ></Route>
      </Route>
    </Routes>
  );
};

export default DashboardRoutes;
