import { Skeleton, Box, Grid, Typography } from "@mui/material";
import { themePalette } from "../../../../constants/Theme/colors";

export const ChartSkeleton = () => {
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              width: 1,
              backgroundColor: themePalette.palette.common.tabTableBackground,
              px: 3,
              py: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: 1,
                mb: 4,
              }}
            >
              <Skeleton animation="wave" width="30%">
                <Typography variant="titleLarge">.</Typography>
              </Skeleton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "70%",
                }}
              >
                <Skeleton animation="wave" width="30%">
                  <Typography variant="titleLarge">.</Typography>
                </Skeleton>
                <Skeleton animation="wave" width="40%" sx={{ ml: 1 }}>
                  <Typography variant="titleLarge">.</Typography>
                </Skeleton>
              </Box>
            </Box>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="100%"
              height="360px"
              sx={{ borderRadius: "4px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
