import React, { useState } from "react";
import { Box, CssBaseline, Drawer, Toolbar } from "@mui/material";
import LeftSidebar from "../../components/dashboard/sideBar/LeftSidebar";
import Topbar from "../../components/dashboard/topBar/Topbar";
// import { useStyles } from "./style";
const drawerWidth = 238;
const DashboardLayout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const handleDrawerToggle = () => {
    setIsMobile(!isMobile);
  };
  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar isMobile={isMobile} setIsMobile={setIsMobile} />
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={isMobile}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            m: 0,
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "drawerWidth",

              m: 0,
            },
          }}
        >
          <LeftSidebar />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              m: 0,
            },
          }}
          open
        >
          <LeftSidebar />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={theme => ({
          flexGrow: 1,
          px: { xl: 3, lg: 3, md: 2, sm: 2, xs: 2 },
          py: { xl: 3, lg: 3, md: 3, sm: 3, xs: 2 },
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          minHeight: "100vh",
          backgroundColor: "background.tertiary",
        })}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
