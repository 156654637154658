import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeTabs = createTheme(themePalette, {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "'AsapRegular', sans-serif",
          fontSize: 20,
          textTransform: "capitalize",
        },
      },
    },
  },
});
