import { Box, Typography, Divider, Tooltip } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { themePalette } from "../../../../constants/Theme/colors";
import { CenterFlexBox } from "../../../../common/StyledComponents/Flexbox";
import defaultuser from "../../../Users/assets/defaultuser.png";
import PropTypes from "prop-types";
import services from "../../../../services";
import TopPerformersSkeleton from "../skeletons/TopPerformersSkeleton";

/**
 * This is the top performers component, which shows us the Top Performers by credits. Here you can customize fonts color, background color and give border to the component.
 */

const TopPerformers = ({
  borderColor,
  borderWidth,
  borderRadius,
  backgroundColor,
  titleFontColor,
  creditFontColor,
}) => {
  const [topPerformers, setTopPerformers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const counter = useRef(0);

  const numFormatter = num => {
    if (num) {
      if (num > 9999 && num < 999999) {
        return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k";
      }

      if (num > 999999 && num < 999999999) {
        return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "M";
      }

      if (num > 999999999) {
        return Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(1) + "B";
      }

      return num;
    }

    return num;
  };

  const loadImage = () => {
    counter.current += 1;
    if (counter.current >= topPerformers.length - 1) {
      setImageLoading(false);
    }
  };

  const getTopPerformersList = () => {
    setIsLoading(true);
    services.topPerformers().then(res => {
      if (res?.status === 200) {
        setTopPerformers(res?.data?.data?.topusersList);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getTopPerformersList();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        // backgroundColor: "background.secondary",
        backgroundColor: `${backgroundColor}`,
        border: `${borderWidth} solid ${borderColor}`,
        borderRadius: `${borderRadius}`,
        pt: 2,
        height: "100%",
      }}
    >
      {isLoading ? (
        <TopPerformersSkeleton />
      ) : (
        <Box>
          <CenterFlexBox
            sx={{
              width: 1,
              boxSizing: "border-box",
              pl: 2,
              mb: 1,
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="heading3"
              sx={{
                color: `${titleFontColor}`,
              }}
            >
              Top Performers
            </Typography>
            <Typography
              variant="heading6"
              sx={{
                color: `${creditFontColor}`,
                whiteSpace: "nowrap",
                pr: 1,
              }}
            >
              Co2 Saved (Kg)
            </Typography>
          </CenterFlexBox>

          <Box>
            <Box>
              {topPerformers?.map(item => {
                return (
                  <Box
                    key={item?.id}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CenterFlexBox
                      sx={{
                        width: 1,
                        my: { md: 1.5, xs: 2, sm: 2 },
                        px: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <CenterFlexBox sx={{ width: { xs: "unset", md: "65%" } }}>
                        <Box
                          component={"img"}
                          sx={{ mr: 1, borderRadius: "100%" }}
                          src={
                            imageLoading
                              ? defaultuser
                              : item?.user?.profileImage || defaultuser
                          }
                          onLoad={() => loadImage()}
                          width={"26px"}
                          height={"26px"}
                          alt={
                            item?.user?.firstName + " " + item?.user?.lastName
                          }
                        />
                        <Tooltip
                          title={
                            item?.user?.firstName +
                              " " +
                              item?.user?.lastName || "kevin caroll"
                          }
                          arrow
                        >
                          <Box
                            sx={{
                              width: "80%",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              color: "text.secondary",
                            }}
                          >
                            <Typography
                              variant="body_medium"
                              sx={{ color: "text.secondary" }}
                            >
                              {item?.user?.firstName +
                                " " +
                                item?.user?.lastName}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </CenterFlexBox>

                      <Tooltip arrow title={item?.carbonOffset + " Kg" || null}>
                        <Typography
                          variant="heading_default"
                          sx={{ color: "text.greenValue" }}
                        >
                          {numFormatter(Math.round(item?.carbonOffset))}
                        </Typography>
                      </Tooltip>
                    </CenterFlexBox>
                    <Divider
                      sx={{
                        width: 1,
                        borderColor: themePalette.palette.secondary.main,
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

TopPerformers.propTypes = {
  /**
   * change the border radius of the component
   */
  borderRadius: PropTypes.string,
  /**
   * change the border color of the component
   */
  borderColor: PropTypes.string,
  /**
   * change the border width of the component
   */
  borderWidth: PropTypes.string,
  /**
   * change the background color of the component
   */
  backgroundColor: PropTypes.string,
  /**
   * change the title font color
   */
  titleFontColor: PropTypes.string,
  /**
   * change the color of body fonts
   */
  bodyFontColor: PropTypes.string,
  /**
   * change the color of body fonts
   */
  creditFontColor: PropTypes.string,
  /**
   * change the color of body fonts
   */
  dividerColor: PropTypes.string,
};

TopPerformers.defaultProps = {
  borderRadius: "0px",
  borderWidth: "0px",
  borderColor: "transparent",
  backgroundColor: "background.secondary",
  titleFontColor: "text.main",
  bodyFontColor: "text.secondary",
  creditFontColor: "text.greenValue",
  dividerColor: `${themePalette.palette.secondary.main}`,
};

export default TopPerformers;
