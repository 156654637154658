import { Skeleton, Box, Grid, Typography, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddFAQWrapper } from "../../../FAQ/styles";
import { Link } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";

export const PanelSkeleton = () => {
  return (
    <Grid container>
      <Grid item xl={9} lg={9} md={6} sm={6} xs={8}>
        <Box sx={{ display: "flex" }}>
          <Link to={ROUTES_PATH.DASHBOARD + "/users"}>
            <ArrowBackIcon
              sx={{
                fontSize: 30,
                position: "relative",
                top: "5px",
                color: theme => theme.palette.primary.main,
                mr: 2,
              }}
            />
          </Link>
          <Skeleton animation="wave" width="150px">
            <Typography
              variant="heading2"
              sx={{
                mb: 1,
                ml: 4,
              }}
            >
              .
            </Typography>
          </Skeleton>
        </Box>
      </Grid>

      <Grid
        item
        xl={3}
        lg={3}
        md={6}
        sm={6}
        xs={4}
        sx={{ display: "flex", justifyContent: "flex-end", pr: { lg: 3 } }}
      >
        <AddFAQWrapper>
          <Skeleton animation="wave" variant="rectangular">
            <Button
              variant="outlined"
              color="error"
              sx={{
                py: 3,
                width: "120px",
                fontSize: 18,
                float: {
                  xl: "right",
                  lg: "right",
                  md: "right",
                  sm: "right",
                  xs: "none",
                },
                mt: 0,
              }}
            >
              .
            </Button>
          </Skeleton>
        </AddFAQWrapper>
      </Grid>
    </Grid>
  );
};
