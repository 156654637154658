import styled from "@emotion/styled";
import { themePalette } from "../../constants/Theme/colors";

export const LegendWrapper = styled.div(({ theme }) => {
  return {
    "& .apexcharts-legend": {
      width: "100%",
      left: `${0} !important`,
    },
  };
});
