export const daysList = [
  { label: "01", value: "01", dataset: [] },
  { label: "02", value: "02", dataset: [] },
  { label: "03", value: "03", dataset: [] },
  { label: "04", value: "04", dataset: [] },
  { label: "05", value: "05", dataset: [] },
  { label: "06", value: "06", dataset: [] },
  { label: "07", value: "07", dataset: [] },
  { label: "08", value: "08", dataset: [] },
  { label: "09", value: "09", dataset: [] },
  { label: "10", value: "10", dataset: [] },
  { label: "11", value: "11", dataset: [] },
  { label: "12", value: "12", dataset: [] },
  { label: "13", value: "13", dataset: [] },
  { label: "14", value: "14", dataset: [] },
  { label: "15", value: "15", dataset: [] },
  { label: "16", value: "16", dataset: [] },
  { label: "17", value: "17", dataset: [] },
  { label: "18", value: "18", dataset: [] },
  { label: "19", value: "19", dataset: [] },
  { label: "20", value: "20", dataset: [] },
  { label: "21", value: "21", dataset: [] },
  { label: "22", value: "22", dataset: [] },
  { label: "23", value: "23", dataset: [] },
  { label: "24", value: "24", dataset: [] },
  { label: "25", value: "25", dataset: [] },
  { label: "26", value: "26", dataset: [] },
  { label: "27", value: "27", dataset: [] },
  { label: "28", value: "28", dataset: [] },
  { label: "29", value: "29", dataset: [] },
  { label: "30", value: "30", dataset: [] },
  { label: "31", value: "31", dataset: [] },
];
