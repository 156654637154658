import { Skeleton, Grid, Box, Typography, Button } from "@mui/material";
import theme from "../../../constants/Theme";

export const SkeletonLoad = props => {
  const total = props.count;
  const skeletonArray = Array(5).fill("");

  return skeletonArray.map(() => (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={4}>
      <Box
        sx={{
          backgroundColor: theme => theme.palette.common.white,
          border: `1px solid ${theme.palette.text.greenValue}`,
          borderRadius: "5px",
          p: 2,
          width: 1,
        }}
      >
        <Grid container>
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Skeleton width="70%" sx={{ mb: 1 }}>
              <Typography>.</Typography>
            </Skeleton>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Skeleton
              variant="rectangular"
              width="50%"
              sx={{
                mt: { sm: 1, xs: 1 },
                display: "inline-block",
                mt: 0,
              }}
            >
              <Button></Button>
            </Skeleton>
            <Skeleton
              variant="rectangular"
              width="22%"
              sx={{
                float: "right",
                mt: { sm: 1, xs: 1 },
                display: "inline-block",
                mt: 0,
              }}
            >
              <Button></Button>
            </Skeleton>
            <Skeleton
              variant="rectangular"
              width="22%"
              sx={{
                float: "right",
                mt: { sm: 1, xs: 1 },
                display: "inline-block",
                mr: 1,
                mt: 0,
              }}
            >
              <Button></Button>
            </Skeleton>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Skeleton
              width="100%"
              height={"120px"}
              sx={{ mt: { xl: 2, lg: 2, md: 2, sm: 2, xs: 0 } }}
            >
              <Typography>.</Typography>
            </Skeleton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  ));
};
