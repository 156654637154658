import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import ROUTES_PATH from "../../../constants/routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import AddFAQ from "./AddFAQ";

const FAQAdd = () => {
  return (
    <DashboardLayout>
      <Box
        sx={{
          p: 1,
          pt: { xl: 1, lg: 1, md: 1, sm: 1, xs: 3 },
        }}
      >
        <Grid container>
          <Grid item xl={9} lg={9} md={6} sm={6} xs={12}>
            <Box sx={{ display: "inline-block" }}>
              <Link to={ROUTES_PATH.DASHBOARD + "/faq"}>
                <ArrowBackIcon
                  sx={{
                    fontSize: 30,
                    position: "relative",
                    top: "5px",
                    color: theme => theme.palette.text.primary,
                  }}
                />
              </Link>
            </Box>
            <Typography
              variant="label_xlarge"
              sx={{
                mb: 1,
                ml: 3,
              }}
            >
              Add FAQ
            </Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12} hidden></Grid>
        </Grid>
        <AddFAQ></AddFAQ>
      </Box>
    </DashboardLayout>
  );
};

export default FAQAdd;
