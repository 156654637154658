import React from "react";
import { Routes, Route } from "react-router-dom";
import ROUTES_PATH from "../constants/routes";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
import LogInPage from "../pages/LoginPage/LogInPage";
import PasswordResetPage from "../pages/passwordResetPassword/PasswordResetPage";
import TermsServices from "../pages/TermsService";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import { PublicRoute } from "./publicPrivateRoute";

const MainRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<LogInPage />}></Route>
        <Route path={ROUTES_PATH.LOG_IN} element={<LogInPage />}></Route>
        <Route
          path={ROUTES_PATH.FORGOT_PASSWORD}
          element={<ForgotPasswordPage />}
        ></Route>
        <Route
          path={ROUTES_PATH.RESET_PASSWORD}
          element={<PasswordResetPage />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/terms"}
          element={<TermsServices />}
        ></Route>
        <Route
          path={ROUTES_PATH.DASHBOARD + "/privacy"}
          element={<PrivacyPolicy />}
        ></Route>
      </Route>
    </Routes>
  );
};

export default MainRoutes;
