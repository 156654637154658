import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { themePalette } from "../../../constants/Theme/colors";
import { monthsList } from "../../../constants/staticData/monthlyList";
import { daysList } from "../../../constants/staticData/daysList";

const UserAdminGraph = props => {
  const { info, interval, startWeek, endWeek, monthInfo, yearInfo } = props;
  const [yearData, setYearData] = useState(monthsList);
  const [monthData, setMonthData] = useState(daysList);
  const [weekData, setWeekData] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [noData, setNoData] = useState("");

  const startWeekDate = new Date(startWeek);
  const startEndDate = new Date(endWeek);

  useEffect(() => {
    if (interval == "Yearly") {
      setNoData("");
      if (info?.length == 0) {
        //setYearData(prev =>
        //  prev.map(item => {
        //    return {
        //      ...item,
        //      dataset: 0,
        //    };
        //  })
        //);
        setNoData("No data present!");
      } else {
        setNoData("");
        setYearData(monthsList);
        info.map(item2 => {
          setYearData(prev =>
            prev.map(item => {
              if (item?.value == item2?.month) {
                return {
                  ...item,
                  dataset: [item2?.users],
                };
              }
              return item;
            })
          );
        });
      }
    } else if (interval == "Monthly") {
      setNoData("");
      if (info?.length == 0) {
        //setMonthData(prev =>
        //  prev.map(item => {
        //    return {
        //      ...item,
        //      dataset: 0,
        //    };
        //  })
        //);
        setNoData("No data present!");
      } else {
        setNoData("");
        setMonthData(daysList);
        info.map(item2 => {
          setMonthData(prev =>
            prev.map(item => {
              if (item?.value == new Date(item2?.date).getDate()) {
                return {
                  ...item,
                  dataset: [item2?.users],
                };
              }
              return item;
            })
          );
        });
      }
    } else if (interval == "Weekly") {
      setNoData("");
      //const startRange = new Date(startWeek);
      //const endRange = new Date(endWeek);
      if (info?.length == 0) {
        //const emptyWeek = [];

        //for (var i = startRange; i <= endRange; i.setDate(i.getDate() + 1)) {
        //  emptyWeek.push(0);
        //}
        //setWeekData(emptyWeek);
        setNoData("No data present!");
      } else {
        setNoData("");
        const filterWeek = [];

        dateList.map(item => {
          filterWeek.push({ id: item, value: null });
        });
        setWeekData(filterWeek);

        info.map(item2 => {
          setWeekData(prev =>
            prev.map(item => {
              if (item?.id == item2?.date) {
                return {
                  ...item,
                  value: item2?.users,
                };
              }
              return item;
            })
          );
        });
      }
    }
  }, [info, interval]);

  useEffect(() => {
    const allDates = [];
    for (
      var day = startWeekDate;
      day <= startEndDate;
      day.setDate(day.getDate() + 1)
    ) {
      allDates.push(day.getDate());
    }
    setDateList(allDates);
  }, [startWeek, endWeek]);

  return (
    <Box sx={{ mt: 3 }}>
      {noData ? (
        <Box
          sx={{
            height: "350px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="heading3_large"
            sx={{ color: themePalette.palette.text.secondary }}
          >
            {noData}
          </Typography>
        </Box>
      ) : (
        <Chart
          height="360px"
          options={{
            chart: {
              type: "line",

              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
              width: 1,
            },
            markers: {
              size: 3,
            },
            grid: {
              row: {
                colors: ["transparent"],
                opacity: 0.5,
              },
            },
            xaxis: {
              categories:
                interval == "Yearly"
                  ? yearData.map(val => val?.label)
                  : interval == "Monthly"
                  ? monthData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.label)
                  : interval == "Weekly"
                  ? dateList.map(val => val)
                  : "",
            },
            yaxis: {
              show: true,
              axisBorder: {
                show: true,
                color: "#d4d4d4",
                offsetX: -2,
                offsetY: 0,
              },
              labels: {
                formatter: function (val) {
                  return Math.floor(val);
                },
              },
            },
            markers: {
              size: 6,
            },
          }}
          series={[
            {
              name: "Users",
              data:
                interval == "Yearly"
                  ? yearData.map(val =>
                      val?.dataset?.length && val?.dataset[0] !== undefined
                        ? val?.dataset[0]
                        : null
                    )
                  : interval == "Monthly"
                  ? monthData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val =>
                        val?.dataset?.length && val?.dataset[0] !== undefined
                          ? val?.dataset[0]
                          : null
                      )
                  : interval == "Weekly"
                  ? weekData.map(item =>
                      item?.value !== null ? item?.value : null
                    )
                  : "",
            },
          ]}
        />
      )}
      {weekData.map(item => {
        console.log(item);
      })}
    </Box>
  );
};

export default UserAdminGraph;
