import styled from "@emotion/styled";
import { themePalette } from "../../constants/Theme/colors";

export const PasswordFieldWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      fontFamily: "'ArchivoRegular', sans-serif",
      background: themePalette.palette.common.white,
    },

    "& .MuiOutlinedInput-root fieldset": {
      border: `1px solid ${themePalette.palette.primary.main}`,
    },

    "& .MuiButtonBase-root": {
      backgroundColor: themePalette.palette.primary.main,
      borderRadius: "4px",
      textTransform: "capitalize",
      fontSize: 16,

      "&:hover": {
        backgroundColor: themePalette.palette.primary.main,
      },
    },
  };
});
