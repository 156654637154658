import styled from "@emotion/styled";
import { themePalette } from "../../../constants/Theme/colors";

export const ProfileInfoWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiTypography-body1": {
      fontFamily: '"AsapRegular", "sans-serif"',
      color: `${themePalette.palette.text.secondary} !important`,
    },

    "& .MuiTypography-body2": {
      fontFamily: '"AsapMedium", "sans-serif"',
      color: themePalette.palette.common.black,
      fontSize: 18,
    },
  };
});

export const CommunityWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiTypography-body1": {
      fontFamily: '"ArchivoRegular", "sans-serif"',
      color: themePalette.palette.text.darkSecondary,
      fontSize: 20,
    },

    "& .MuiTypography-body2": {
      fontFamily: '"AsapRegular", "sans-serif"',
      color: themePalette.palette.text.secondary,
      fontSize: 16,
    },

    "& .likedUsers": {
      fontFamily: '"AsapRegular", "sans-serif"',
      width: "28px",
      height: "28px",
    },

    "& .MuiAvatarGroup-avatar:first-child": {
      background: themePalette.palette.common.dialogActions,
      color: themePalette.palette.text.secondary,
      fontFamily: '"AsapRegular", "sans-serif"',
      width: "28px",
      height: "28px",
      fontSize: 12,
    },
  };
});

export const DeleteTextWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiListItemText-primary": {
      fontFamily: '"ArchivoMedium", "sans-serif" !important',
      color: `${themePalette.palette.button.secondary} !important`,
      fontSize: 18,
    },
  };
});

export const LikedPersonTextWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiTypography-body2": {
      fontFamily: '"AsapRegular", "sans-serif" !important',
      fontSize: 21,
      color: themePalette.palette.common.black,
    },

    "& .MuiListItemButton-root": {
      "&:hover": {
        background: "unset",
      },
    },
  };
});

export const DatePickerWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiFormControl-root": {
      marginTop: "24px",
      marginBottom: "16px",

      "@media (max-width:500px)": {
        marginTop: "8px",
      },
    },

    "& .MuiOutlinedInput-root": {
      fontFamily: '"ArchivoMedium", "sans-serif" !important',
      background: themePalette.palette.common.white,
    },

    "& .MuiOutlinedInput-root fieldset": {
      border: `2px solid ${themePalette.palette.common.dropdownBorder}`,
    },

    "& .MuiOutlinedInput-input": {
      paddingTop: "11px",
      paddingBottom: "11px",
      fontSize: 17,
    },
  };
});
