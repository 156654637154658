import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ROUTES_PATH from "../../constants/routes";
import theme from "../../constants/Theme";
import { AddFAQWrapper, IconWrapper } from "./styles";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { SkeletonLoad } from "./Skeleton/skeleton";
import services from "../../services";
import CommonDialog from "../../common/commonDialog/CommonDialog";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const FAQList = () => {
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [faqList, setFAQList] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteFaq, setDeleteFaq] = useState();
  const [listUpdated, setListUpdated] = useState(true);
  const allFAQ = [];

  useEffect(() => {
    if (listUpdated) {
      services
        .getFAQList()
        .then(res => {
          res?.data?.data.map(item => {
            if (!item.deleted) {
              allFAQ.push(item);
            }
          });
          setFAQList(allFAQ);
          setIsLoading(false);
          setListUpdated(false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [listUpdated]);

  const sendFaqId = faqId => {
    setDeleteFaq(faqId);
  };

  const handleRemoveItem = faqId => {
    setOpen(false);
    setLoader(true);
    let params = {
      id: faqId,
    };
    services.deleteFAQ(params).then(res => {
      if (res?.data?.statusCode === 200) {
        const newItems = faqList.filter(item => item.id !== faqId);
        setFAQList(newItems);
        setLoader(false);
      }
    });
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleArrowClick = (index, direction) => {
    if (direction === "up") {
      if (index > 0) {
        const updatedItems = [...faqList];
        const currentItem = updatedItems[index];
        const previousItem = updatedItems[index - 1];

        updatedItems[index] = {
          ...currentItem,
          sequenceId: previousItem.sequenceId,
        };
        updatedItems[index - 1] = {
          ...previousItem,
          sequenceId: currentItem.sequenceId,
        };

        const transformedData = {
          sequence: updatedItems.map(item => ({
            id: item.id,
            sequenceId: item.sequenceId,
          })),
        };

        services
          .updateFAQPosition(transformedData)
          .then(res => {
            if (res?.data?.statusCode === 200) {
              setIsLoading(true);
              setListUpdated(true);
            }
          })
          .catch(error => error);
      }
    } else if (direction === "down") {
      if (index < faqList.length - 1) {
        const updatedItems = [...faqList];
        const currentItem = updatedItems[index];
        const nextItem = updatedItems[index + 1];

        updatedItems[index] = {
          ...currentItem,
          sequenceId: nextItem.sequenceId,
        };
        updatedItems[index + 1] = {
          ...nextItem,
          sequenceId: currentItem.sequenceId,
        };

        const transformedData = {
          sequence: updatedItems.map(item => ({
            id: item.id,
            sequenceId: item.sequenceId,
          })),
        };

        services
          .updateFAQPosition(transformedData)
          .then(res => {
            if (res?.data?.statusCode === 200) {
              setIsLoading(true);
              setListUpdated(true);
            }
          })
          .catch(error => error);
      }
    }
  };

  return (
    <DashboardLayout>
      <Backdrop
        sx={{
          color: theme => theme.palette.common.white,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          p: 1,
          pt: { xl: 1, lg: 1, md: 1, sm: 1, xs: 2 },
        }}
      >
        <Grid container>
          <Grid item xl={9} lg={9} md={6} sm={6} xs={12}></Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <AddFAQWrapper>
              <Link to={ROUTES_PATH.DASHBOARD + "/faq/add"}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  sx={{
                    py: 3,
                    width: "175px",
                    float: {
                      xl: "right",
                      lg: "right",
                      md: "right",
                      sm: "right",
                      xs: "none",
                    },
                    mt: {
                      xl: 0,
                      lg: 0,
                      md: 0,
                      sm: 0,
                      xs: 2,
                    },
                  }}
                >
                  Add FAQ
                </Button>
              </Link>
            </AddFAQWrapper>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack sx={styles.centeredContent}>
              <Box
                sx={{
                  backgroundColor: theme =>
                    theme.palette.common.tabTableBackground,
                  px: { xl: 12, lg: 12, md: 6, sm: 4, xs: 3 },
                  pt: { xl: 7, lg: 7, md: 8, sm: 4, xs: 3 },
                  pb: { xl: 6, lg: 6, md: 4, sm: 3, xs: 3 },
                  mt: 3,
                  borderRadius: "5px",
                  width: 1,
                  maxWidth: theme.breakpoints.values.lg,
                }}
              >
                <Grid container>
                  {isLoading ? (
                    <SkeletonLoad count={faqList.length} />
                  ) : faqList.length ? (
                    faqList.map((faq, index) => (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mb={4}>
                        <Box
                          sx={{
                            backgroundColor: theme =>
                              theme.palette.common.white,
                            border: `1px solid ${theme.palette.text.greenValue}`,
                            borderRadius: "5px",
                            p: 2,
                            width: 1,
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xl={8}
                              lg={8}
                              md={8}
                              sm={6}
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="heading3"
                                component="div"
                                sx={{
                                  color: theme =>
                                    theme.palette.common.faqQuestion,
                                  mx: 1,
                                }}
                              >
                                {index + 1 + "."} {faq.questionTitle}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xl={4}
                              lg={4}
                              md={4}
                              sm={6}
                              xs={12}
                              sx={{
                                display: "flex",
                                justifyContent: {
                                  xl: "end",
                                  lg: "end",
                                  md: "end",
                                  sm: "end",
                                  xs: "unset",
                                },
                              }}
                            >
                              <IconWrapper>
                                <Box
                                  sx={{
                                    float: {
                                      xl: "right",
                                      lg: "right",
                                      md: "right",
                                      sm: "none",
                                      xs: "none",
                                    },
                                    ml: {
                                      xl: 0,
                                      lg: 0,
                                      md: 0,
                                      sm: 0,
                                      xs: 1,
                                    },
                                    mr: {
                                      xl: 1,
                                      lg: 1,
                                      md: 1,
                                      sm: 1,
                                      xs: 0,
                                    },
                                  }}
                                  className="faq-icons"
                                >
                                  <Box sx={{ display: "inline-block" }}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      startIcon={<KeyboardArrowUpIcon />}
                                      sx={{
                                        width: "0",
                                        mt: {
                                          xl: 0,
                                          lg: 0,
                                          md: 0,
                                          sm: 0,
                                          xs: 2,
                                        },
                                        borderTopRightRadius: "0",
                                        borderBottomRightRadius: "0",
                                      }}
                                      disabled={index === 0 ? true : false}
                                      onClick={() =>
                                        handleArrowClick(index, "up")
                                      }
                                    ></Button>

                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      startIcon={<KeyboardArrowDownIcon />}
                                      sx={{
                                        width: "0",
                                        mt: {
                                          xl: 0,
                                          lg: 0,
                                          md: 0,
                                          sm: 0,
                                          xs: 2,
                                        },
                                        mr: 1,
                                        borderTopLeftRadius: "0",
                                        borderBottomLeftRadius: "0",
                                      }}
                                      disabled={
                                        index === faqList.length - 1
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        handleArrowClick(index, "down")
                                      }
                                    ></Button>
                                  </Box>

                                  <Link
                                    to={ROUTES_PATH.DASHBOARD + "/faq/update"}
                                    state={{ faq: faq }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      startIcon={<CreateOutlinedIcon />}
                                      sx={{
                                        width: "0",
                                        mt: {
                                          xl: 0,
                                          lg: 0,
                                          md: 0,
                                          sm: 0,
                                          xs: 2,
                                        },
                                        mr: 1,
                                      }}
                                    ></Button>
                                  </Link>

                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<DeleteOutlineIcon />}
                                    sx={{
                                      width: "0",
                                      mt: {
                                        xl: 0,
                                        lg: 0,
                                        md: 0,
                                        sm: 0,
                                        xs: 2,
                                      },
                                    }}
                                    onClick={() => {
                                      setOpen(true);
                                      sendFaqId(faq.id);
                                    }}
                                  ></Button>
                                </Box>
                              </IconWrapper>
                            </Grid>

                            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                variant="body_regular"
                                component="div"
                                sx={{
                                  color: theme =>
                                    theme.palette.common.notificationTime,
                                  mt: 2,
                                  mx: 1,
                                  mb: 1,
                                }}
                              >
                                {faq.answerTitle}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography
                      variant="heading3_large"
                      component="div"
                      sx={{ width: 1, textAlign: "center" }}
                    >
                      No FAQs Present!
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <CommonDialog
        open={open}
        title={"Delete FAQ"}
        subtext={"Are you sure you want to delete it?"}
        iconSpacing={"24px"}
        dialogSize={"xs"}
        dialogIcon={
          <ForumOutlinedIcon
            sx={{
              fontSize: 28,
              color: theme => theme.palette.primary.main,
              opacity: 0.8,
            }}
          />
        }
        dialogAction={
          <React.Fragment>
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${theme => theme.palette.primary.main}`,
                background: theme => theme.palette.common.white,
                fontSize: 18,
                py: 3,
                mr: 3,
              }}
              onClick={handleClickClose}
            >
              No
            </Button>

            <Button
              variant="contained"
              sx={{ fontSize: 18, py: 3 }}
              onClick={() => handleRemoveItem(deleteFaq)}
            >
              Yes
            </Button>
          </React.Fragment>
        }
      />
    </DashboardLayout>
  );
};

export default FAQList;
