import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeSelect = createTheme({
  components: {
    MuiSelect: {
      variants: [
        {
          props: { variant: "outlined", color: "primary" },
          style: {},
        },
      ],

      styleOverrides: {
        select: {
          padding: "12px",
          paddingBottom: "10px",
        },
        root: {
          fieldset: {
            border: `2px solid ${themePalette.palette.common.dropdownBorder}`,
          },
          fontFamily: "'ArchivoMedium', sans-serif",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: "7px",
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "'ArchivoMedium', sans-serif",
          fontSize: 18,
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: "7px",
          paddingBottom: "7px",
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: "unset !important",
          fontFamily: "'ArchivoMedium', sans-serif",
          fontSize: 18,
          color: themePalette.palette.text.secondary,
        },
      },
    },
  },
});
