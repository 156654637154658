export const messages = {
  emailRequired: "Email is required",
  validEmailRequired: "Valid email is required",
  passwordRequired: "Password is required",
  validPasswordRequired:
    "Password must be 8-15 characters long including special characters and numbers",
  passwordNotMatching: "New and Confirm Password does not match!",
  samePassword: "New password cannot be same as old password!",
  invalidCredentials: "Invalid Credentials!",
  faqQuestionRequired: "Question field cannot be empty!",
  faqAnswerRequired: "Answer field cannot be empty!",
  somethingWentWrong: "Oops... Something went wrong",
  reasonRequired: "Reason is required",
  validReasonRequired: "Reason must be 3-300 characters long!",
};
