import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CenterFlexBox } from "../../../../common/StyledComponents/Flexbox";
import { PropTypes } from "prop-types";
import car from "../.../../../../../assets/icons/car.svg";
import group from "../.../../../../../assets/icons/group.svg";
import carbonOffset from "../.../../../../../assets/icons/carbonOffset.svg";
import co2Credits from "../.../../../../../assets/icons/co2Credits.svg";
import services from "../../../../services";
import TodaysStatsSkeleton from "../skeletons/TodaysStatsSkeleton";
/**
 * This is the todays stats component. which gives us the information regarding User, Application, Users, carbon offset and Modes. here can customize many things such as giving border, background color, changing the color of the inner card as well.
 */
const TodaysStats = ({
  backgroundColor,
  borderColor,
  borderWidth,
  borderRadius,
  rowBoxColor,
  titleFontColor,
  bodyFontColor,
  valueColor,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [todaysStats, setTodaysStats] = useState({});
  const _getTodaysStats = () => {
    setIsLoading(true);
    services.getTodaysStats().then(res => {
      if (res?.status === 200) {
        const stats = {
          onboardedUsers: res?.data?.data?.totalUserCount,
          totalUsedApplication: res?.data?.data?.totalUsedApplication,
          carbonCredits: res?.data?.data?.totalCarbonCredits,
          avgCarbonOffset: res?.data?.data?.avgCarbonOffset,
          mostUsedMode: res?.data?.data?.mostUsedMode[0]?.modename,
        };

        setTodaysStats(stats);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    _getTodaysStats();
  }, []);

  return (
    <Box
      sx={theme => ({
        p: 2,
        // backgroundColor: "background.secondary",
        backgroundColor: `${backgroundColor}`,
        border: `${borderWidth} solid ${borderColor}`,
        borderRadius: `${borderRadius}`,
        height: "100%",
        width: "100%",
      })}
    >
      {isLoading ? (
        <TodaysStatsSkeleton />
      ) : (
        <Box>
          <Typography variant="heading3" sx={{ color: `${titleFontColor}` }}>
            Today's Stats
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={theme => ({
                borderRadius: "4px",
                px: 1,
                py: "12px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "background.tertiary",
                backgroundColor: `${rowBoxColor}`,
                border: `${borderWidth} solid ${borderColor}`,
              })}
            >
              <CenterFlexBox sx={{ my: 1 }}>
                <img src={group} alt="icon" />
                <Typography
                  variant="body_medium"
                  sx={{
                    ml: 1,
                    //  color: "text.secondary",
                    color: `${bodyFontColor}`,
                  }}
                >
                  Onboard Users
                </Typography>
              </CenterFlexBox>
              <Typography
                variant="body_small"
                sx={{
                  color: `${valueColor}`,
                  //  "text.greenValue"
                }}
              >
                {todaysStats?.onboardedUsers}
              </Typography>
            </Box>

            <Box
              sx={theme => ({
                borderRadius: "4px",
                px: 1,
                py: "12px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "background.tertiary",
                backgroundColor: `${rowBoxColor}`,
                border: `${borderWidth} solid ${borderColor}`,
              })}
            >
              <CenterFlexBox sx={{ my: 1 }}>
                <img src={group} alt="icon" />
                <Typography
                  variant="body_medium"
                  sx={{
                    ml: 1,
                    //  color: "text.secondary",
                    color: `${bodyFontColor}`,
                  }}
                >
                  Used Application
                </Typography>
              </CenterFlexBox>
              <Typography
                variant="body_small"
                sx={{
                  color: `${valueColor}`,
                  //  "text.greenValue"
                }}
              >
                {todaysStats?.totalUsedApplication}
              </Typography>
            </Box>

            <Box
              sx={theme => ({
                borderRadius: "4px",
                px: 1,
                py: "12px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "background.tertiary",
                backgroundColor: `${rowBoxColor}`,
                border: `${borderWidth} solid ${borderColor}`,
              })}
            >
              <CenterFlexBox sx={{ my: 1 }}>
                <img src={carbonOffset} alt="icon" />
                <Typography
                  variant="body_medium"
                  sx={{
                    ml: 1,
                    //  color: "text.secondary",
                    color: `${bodyFontColor}`,
                  }}
                >
                  Avg. Co2 Saved
                </Typography>
              </CenterFlexBox>
              <Typography
                variant="body_small"
                sx={{
                  color: `${valueColor}`,
                  //  "text.greenValue"
                }}
              >
                {todaysStats?.avgCarbonOffset}
              </Typography>
            </Box>
            <Box
              sx={theme => ({
                borderRadius: "4px",
                px: 1,
                py: "12px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "background.tertiary",
                backgroundColor: `${rowBoxColor}`,
                border: `${borderWidth} solid ${borderColor}`,
              })}
            >
              <CenterFlexBox sx={{ my: 1 }}>
                <img src={car} alt="icon" />
                <Typography
                  variant="body_medium"
                  sx={{
                    ml: 1,
                    //  color: "text.secondary",
                    color: `${bodyFontColor}`,
                  }}
                >
                  Most Used Mode
                </Typography>
              </CenterFlexBox>
              <Typography
                variant="body_small"
                sx={{
                  color: `${valueColor}`,
                  //  "text.greenValue"
                }}
              >
                {todaysStats?.mostUsedMode}
              </Typography>
            </Box>

            <Box
              sx={theme => ({
                borderRadius: "4px",
                px: 1,
                py: "12px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "background.tertiary",
                backgroundColor: `${rowBoxColor}`,
                border: `${borderWidth} solid ${borderColor}`,
                visibility: "hidden",
              })}
            >
              <CenterFlexBox sx={{ my: 1 }}>
                <img src={co2Credits} alt="icon" />
                <Typography
                  variant="body_medium"
                  sx={{
                    ml: 1,
                    //  color: "text.secondary",
                    color: `${bodyFontColor}`,
                  }}
                >
                  Total Credits
                </Typography>
              </CenterFlexBox>
              <Typography
                variant="body_small"
                sx={{
                  color: `${valueColor}`,
                  //  "text.greenValue"
                }}
              >
                {todaysStats?.carbonCredits}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

TodaysStats.propTypes = {
  /**
   * change the border radius of the component
   */
  borderRadius: PropTypes.string,
  /**
   * change the border color of the component
   */
  borderColor: PropTypes.string,
  /**
   * change the border width of the component
   */
  borderWidth: PropTypes.string,
  /**
   * change the background color of the component
   */
  backgroundColor: PropTypes.string,
  /**
   * change the title font color
   */
  titleFontColor: PropTypes.string,
  /**
   * change the color of body fonts
   */
  bodyFontColor: PropTypes.string,
  /**
   * change the color of row box
   */
  rowBoxColor: PropTypes.string,
  /**
   * change the color of the values
   */
  valueColor: PropTypes.string,
};

TodaysStats.defaultProps = {
  borderRadius: "0px",
  borderColor: "transparent",
  borderWidth: "0px",
  backgroundColor: "background.secondary",
  titleFontColor: "#000",
  bodyFontColor: "text.secondary",
  rowBoxColor: "background.tertiary",
  valueColor: "text.greenValue",
};

export default TodaysStats;
