import React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Avatar,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ModalContentWrapper } from "./styles";

const CommonDialog = props => {
  const {
    open,
    title,
    reasonTitle,
    subtext,
    dialogAction,
    dialogIcon,
    iconSpacing,
    dialogSize,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      fullWidth
      maxWidth={dialogSize}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100% !important",
          },
        },
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          px: 3,
          pt: 3,
          display: "block",
          m: "0 auto",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: theme => theme.palette.common.notificationIcon,
            p: iconSpacing,
          }}
        >
          {dialogIcon}
        </Avatar>
      </Box>

      <ModalContentWrapper>
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1 }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subtext}

            {reasonTitle && reasonTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: theme =>
              `${theme.palette.common.dialogActions} !important`,
            p: 3,
          }}
        >
          {dialogAction}
        </DialogActions>
      </ModalContentWrapper>
    </Dialog>
  );
};

export default CommonDialog;
