import * as React from "react";
import { Box, TextField } from "@mui/material";
import { DatePickerText } from "./style";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import format from "date-fns/format";
import { subDays } from "date-fns";
import { themePalette } from "../../../constants/Theme/colors";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/slice/auth-slice";

export default function BasicDateRangePicker() {
  const dispatch = useDispatch();
  const [range, setRange] = React.useState([
    {
      startDate: subDays(new Date(), 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [open, setOpen] = React.useState(false);

  const refOne = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEscape = e => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    const begin = new Date(
      range[0]?.startDate.getTime() -
        range[0]?.startDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    const end = new Date(
      range[0]?.endDate.getTime() -
        range[0]?.endDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    dispatch(
      authActions.weekRangeDate({
        begin,
        end,
      })
    );
  }, [range]);

  return (
    <DatePickerText>
      <Box>
        <TextField
          value={`${format(range[0].startDate, "dd MMM, yyyy")} to ${format(
            range[0].endDate,
            "dd MMM, yyyy"
          )}`}
          onClick={() => setOpen(open => !open)}
        />

        <Box ref={refOne}>
          {open && (
            <DateRange
              onChange={item => setRange([item.selection])}
              ranges={range}
              months={1}
              direction="horizontal"
              showMonthAndYearPickers={false}
              moveRangeOnFirstSelection={false}
              retainEndDateOnFirstSelection={true}
              showDateDisplay={false}
              fixedHeight={false}
              minDate={new Date("2022-10-01")}
              maxDate={new Date()}
              rangeColors={themePalette.palette.common.rangeColor}
              color={themePalette.palette.common.rangeColor}
            />
          )}
        </Box>
      </Box>
    </DatePickerText>
  );
}
