import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import DashboardRoutes from "./routes/routes";
import { createInstanceandInjectStore } from "./routes/axios";
import { useStore } from "react-redux";
import MainRoutes from "./routes";

const App = () => {
  const storeInstance = useStore();
  createInstanceandInjectStore(storeInstance);

  return (
    <Router>
      <MainRoutes />
      <DashboardRoutes />
    </Router>
  );
};

export default App;
