export const monthsList = [
  { label: "Jan", value: "1", dataset: [] },
  { label: "Feb", value: "2", dataset: [] },
  { label: "Mar", value: "3", dataset: [] },
  { label: "Apr", value: "4", dataset: [] },
  { label: "May", value: "5", dataset: [] },
  { label: "Jun", value: "6", dataset: [] },
  { label: "Jul", value: "7", dataset: [] },
  { label: "Aug", value: "8", dataset: [] },
  { label: "Sep", value: "9", dataset: [] },
  { label: "Oct", value: "10", dataset: [] },
  { label: "Nov", value: "11", dataset: [] },
  { label: "Dec", value: "12", dataset: [] },
];
