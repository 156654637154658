export const vehicles = [
  {
    id: 0,
    vehicle: "Gasoline Vehicles",
    number: 1254,
    color: "graph.gasoline",
  },
  {
    id: 1,
    vehicle: "Diesel Vehicles",
    number: 254,
    color: "graph.diesel",
  },
  {
    id: 2,
    vehicle: "CNG Vehicles",
    number: 254,
    color: "graph.cng",
  },
  {
    id: 3,
    vehicle: "Electric Vehicles",
    number: 254,
    color: "graph.electric",
  },
  {
    id: 4,
    vehicle: "Hybrid Vehicles",
    number: 254,
    color: "graph.hybrid",
  },
];
