let API_URL = process.env.REACT_APP_API_URL;
//const API_URL = "https://api.dev.travelrite.org";

export const loginUrl = () => `${API_URL}/api/manual/login`;

export const forgotPasswordUrl = () => `${API_URL}/api/forget/password`;

export const verifyForgotTokenUrl = verifyToken =>
  `${API_URL}/api/verify/forget/password?forgetPasswordToken=${verifyToken}`;

export const resetPasswordUrl = () => `${API_URL}/api/reset/password`;

export const changePasswordUrl = () => `${API_URL}/api/change/passowrd`;

export const fetchUserListUrl = (rowsPerPage, page, sortDirection) =>
  `${API_URL}/api/list/user?pageNo=${page}&pageSize=${rowsPerPage}&sortDir=${sortDirection}`;

export const singleUserUrl = userId => `${API_URL}/api/user/${userId}`;

export const userActiveStatus = () => `${API_URL}/api/user/block`;

export const fetchFAQListUrl = () => `${API_URL}/api/get/faqs`;

export const addFAQUrl = () => `${API_URL}/api/add/faq`;

export const updateFAQUrl = () => `${API_URL}/api/update/faq`;

export const deleteFAQUrl = () => `${API_URL}/api/delete/faq`;

export const updateFAQPositionUrl = () => `${API_URL}/api/sequence/faq`;

export const topPerformersUrl = () => `${API_URL}/api/track/list/top/five`;

export const searchUserUrl = () => `${API_URL}/api/search?p=1&ps=1`;

export const dashboardChartUrl = () => `${API_URL}/api/track/chart`;

export const modeChartUrl = () => `${API_URL}/api/track/mode/chart`;

export const carOwnersUrl = () => `${API_URL}/api/type/vehicles`;

export const pointsChartUrl = () => `${API_URL}/api/track/mode/user`;

export const totalUsersAndTrees = () => `${API_URL}/api/users`;

export const todaysStats = () => `${API_URL}/api/dashboard/stats`;
