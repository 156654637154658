import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Button,
  TextField,
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CommonDialog from "../../common/commonDialog/CommonDialog";
import { messages } from "../../constants/message/messages";
import { themePalette } from "../../constants/Theme/colors";
import { PasswordFieldWrapper } from "../ChangePassword/styles";
import login_poster from "../../assets/images/City-driver-pana.svg";
import ROUTES_PATH from "../../constants/routes";
import { isValidPassword } from "../../utils/inputvalidation";
import { useNavigate } from "react-router";
import services from "../../services";

const PasswordResetPage = () => {
  const [loader, setLoader] = React.useState(false);
  const [values, setValues] = React.useState({
    newPassword: "",
    confirmPassword: "",
    error: true,
    isNewPasswordError: false,
    isConfirmPasswordError: false,
    newPasswordErrorMsg: "",
    confirmPasswordErrorMsg: "",
  });
  const [open, setOpen] = React.useState(false);
  const [verify, setVerify] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const verifyToken = urlParams.get("token");

    setLoader(true);
    services
      .verifyForgotToken(verifyToken)
      .then(res => {
        if (res.data.statusCode == 200) {
          setLoader(false);
          setVerify(true);
        }
      })
      .catch(error => console.log(error));
  }, []);

  const handleClickClose = () => {
    setOpen(false);
    navigate(ROUTES_PATH.LOG_IN);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      const urlParams = new URLSearchParams(window.location.search);
      const verifyToken = urlParams.get("token");

      let { newPassword, confirmPassword } = values;
      let params = {
        isResetRequest: true,
        newPassword: newPassword.trim(),
        resetPasswordToken: verifyToken,
      };

      services
        .resetPassword(params)
        .then(res => {
          setOpen(true);
          setValues({
            newPassword: "",
            confirmPassword: "",
          });
        })
        .catch(error => console.log(error));
    }
  };

  const validate = () => {
    let { newPassword, confirmPassword } = values;
    if (newPassword.trim() == "") {
      handleChangeValue("isNewPasswordError", true);
      handleChangeValue("newPasswordErrorMsg", messages.passwordRequired);
      return false;
    } else if (!isValidPassword(newPassword)) {
      handleChangeValue("isNewPasswordError", true);
      handleChangeValue("newPasswordErrorMsg", messages.validPasswordRequired);
      return false;
    }
    if (confirmPassword.trim() == "") {
      handleChangeValue("isConfirmPasswordError", true);
      handleChangeValue("confirmPasswordErrorMsg", messages.passwordRequired);
      return false;
    } else if (!isValidPassword(confirmPassword)) {
      handleChangeValue("isConfirmPasswordError", true);
      handleChangeValue(
        "confirmPasswordErrorMsg",
        messages.validPasswordRequired
      );
      return false;
    }
    if (confirmPassword !== newPassword) {
      handleChangeValue("isConfirmPasswordError", true);
      handleChangeValue(
        "confirmPasswordErrorMsg",
        messages.passwordNotMatching
      );
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box>
      {verify ? (
        <Box>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              display={{
                xl: "block",
                lg: "block",
                md: "block",
                sm: "none",
                xs: "none",
              }}
            >
              <Box
                sx={{
                  background: themePalette.palette.secondary.main,
                  minHeight: "100vh",
                  display: "grid",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  flexFlow: "row wrap",
                  width: 1,
                  webkitBoxPack: "center",
                  justifyContent: "center",
                }}
              >
                <Container maxWidth="sm">
                  <Box
                    sx={{
                      px: { xl: 6, lg: 6, md: 6 },
                      pt: { xl: 7, lg: 7, md: 5 },
                      pb: { xl: 8, lg: 8, md: 5 },
                    }}
                  >
                    <Typography
                      sx={{
                        color: themePalette.palette.primary.main,
                      }}
                      variant="heading1_xlarge"
                    >
                      TravelRite
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "16px",
                        backgroundColor: "background.main",
                        display: "block",
                        m: "0 auto",
                        width: "63%",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundImage: `url(${login_poster})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          height: "340px",
                        }}
                      ></Box>
                    </Box>

                    <Box sx={{ mt: 8 }}>
                      <Typography
                        variant="heading2"
                        sx={{
                          color: "text.main",
                        }}
                      >
                        Track Users, Emissions etc.
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "block",
                        m: "0 auto",
                        width: "80%",
                        marginTop: 2,
                        marginBottom: 7,
                      }}
                    >
                      <Typography
                        variant="body_large"
                        sx={{
                          color: themePalette.palette.text.secondary,
                        }}
                      >
                        Lorem ipsum dolor sit amet. In lobortis ex id ante
                        maximus, quis varius elit suscipit.
                      </Typography>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              xs={12}
              sx={{
                backgroundColor: themePalette.palette.background.tertiary,
                minHeight: "100vh",
                display: "grid",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                flexFlow: "column wrap",
                width: 1,
                webkitBoxPack: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: themePalette.palette.primary.main,
                  my: 3,
                  display: { xs: "block", md: "none" },
                }}
                variant="heading1_xlarge"
              >
                TravelRite
              </Typography>
              <Container maxWidth="sm" sx={{ mt: { xs: 3, md: 0 } }}>
                <Box
                  sx={{
                    pt: 4,
                    pb: { xl: 0, lg: 0, md: 0, sm: 6, xs: 5 },
                    pl: { xl: 9, lg: 9, md: 7, sm: 0, xs: 2 },
                    pr: { xl: 13, lg: 13, md: 7, sm: 0, xs: 2 },
                  }}
                >
                  <Typography
                    sx={{ mb: 1 }}
                    variant="heading3_large"
                    component="div"
                    color="text.main"
                  >
                    Reset Password
                  </Typography>
                  <Typography
                    sx={{
                      mb: 5,
                      color: themePalette.palette.common.subtextColor,
                    }}
                    variant="body_regular"
                    component="div"
                  >
                    Enter new password below
                  </Typography>

                  <form onSubmit={handleSubmit}>
                    <FormControl variant="standard" sx={{ width: 1 }}>
                      <PasswordFieldWrapper>
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            color="text.darkSecondary"
                            variant="heading_regular"
                          >
                            New Password
                          </Typography>
                        </Box>
                        <TextField
                          variant="outlined"
                          placeholder="Enter password here"
                          sx={{ mb: 3 }}
                          type="password"
                          value={values.newPassword}
                          fullWidth
                          onFocus={() => {
                            handleChangeValue("isNewPasswordError", false);
                            handleChangeValue("newPasswordErrorMsg", "");
                          }}
                          onChange={handleChange("newPassword")}
                          error={values.isNewPasswordError}
                          helperText={values.newPasswordErrorMsg}
                        />

                        <Box sx={{ mb: 1 }}>
                          <Typography
                            color="text.darkSecondary"
                            variant="heading_regular"
                          >
                            Confirm Password
                          </Typography>
                        </Box>
                        <TextField
                          variant="outlined"
                          placeholder="Enter confirm password here"
                          sx={{ mb: 5 }}
                          type="password"
                          value={values.confirmPassword}
                          fullWidth
                          onFocus={() => {
                            handleChangeValue("isConfirmPasswordError", false);
                            handleChangeValue("confirmPasswordErrorMsg", "");
                          }}
                          onChange={handleChange("confirmPassword")}
                          error={values.isConfirmPasswordError}
                          helperText={values.confirmPasswordErrorMsg}
                        />
                      </PasswordFieldWrapper>

                      <Button type="submit" variant="contained">
                        Reset Password
                      </Button>
                    </FormControl>
                  </form>

                  <CommonDialog
                    open={open}
                    title={"Password Changed Successfully!"}
                    subtext={"We have changed your password successfully."}
                    dialogSize={"xs"}
                    iconSpacing={"5px"}
                    dialogAction={
                      <React.Fragment>
                        <Button
                          variant="contained"
                          onClick={handleClickClose}
                          sx={{ fontSize: 19, py: "25px" }}
                        >
                          Close
                        </Button>
                      </React.Fragment>
                    }
                  />
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default PasswordResetPage;
