import styled from "@emotion/styled";
import { themePalette } from "../../constants/Theme/colors";

export const AddFAQWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiButtonBase-root": {
      backgroundColor: themePalette.palette.primary.main,
      borderRadius: "4px",
      textTransform: "capitalize",
      fontSize: 16,

      "&:hover": {
        backgroundColor: themePalette.palette.primary.main,
      },
    },
  };
});

export const IconWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiButtonBase-root .MuiButton-startIcon": {
      marginRight: "0",
      marginLeft: "0",
    },
  };
});

export const FAQListWrapper = styled.div(({ theme }) => {
  return {
    "& .faq-title ": {
      display: "flex",
      alignItems: "center",
    },
  };
});
