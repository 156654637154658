import { createTheme } from "@mui/material/styles";

export const themePalette = createTheme({
  palette: {
    primary: {
      main: "#4E8FC5",
    },
    secondary: {
      main: "#F1F1F1",
    },
    background: {
      main: "#FFFFFF",
      secondary: "#F9FAFC",
      tertiary: "#F3F4F6",
      avatar: "#E5EEF7",
    },
    text: {
      primary: "#181818",
      secondary: "#9C9C9C",
      lightSecondary: "#B8B8B8",
      darkSecondary: "#636363",
      blueValue: "#4E8FC5",
      greenValue: "#50C878",
    },

    error: {
      main: "#BA1B1B",
    },

    common: {
      white: "#FFFFFF",
      black: "#000000",
      tableTextColor: "#474747",
      dropdownBorder: "#D4D4D4",
      notificationTime: "#808080",
      notificationIcon: "#B8D2E8",
      dialogActions: "#F1F1F1",
      tableEvenRow: "#F8F8F8",
      tabTableBackground: "#F9FAFB",
      userProfileIconBg: "#E8EFF6",
      userProfileIcon: "#629BCB",
      dividerColor: "#F1F1F1",
      faqQuestion: "#0E0E0E",
      chartBorder: "#FF7723",
      headerDivider: "#E5E7EB",
      profileIconBackground: "#CBEFD7",
      profileIconColor: "#50C878",
      rangeColor: "#E4EEF6",
      subtextColor: "#6B7280",
      nextMonthDatesColor: "#D5DCE0",
    },

    chartColor: {
      bus: "#ff0000",
      bicycle: "#0000ff",
      car: "#3cb371",
      scooter: "#ee82ee",
      rideShare: "#ffa500",
      train: "#6a5acd",
      walk: "#9FE2BF",
    },

    button: {
      main: "#4E8FC5",
      secondary: "#EF4444",
    },
    containerBorder: {
      main: "#4E8FC5",
      secondary: "#EF4444",
      lightSecondary: "#D4D4D4",
    },

    graph: {
      gasoline: "#02B3FF",
      diesel: "#FF7723",
      cng: "#EF4444",
      electric: "#32CD32",
      hybrid: "#ED589D",
    },
  },
});
