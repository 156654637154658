import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import services from "../../../../services";
import { themePalette } from "../../../../constants/Theme/colors";
import { CenterFlexBox } from "../../../../common/StyledComponents/Flexbox";
import { vehicles } from "../../../../constants/staticData/vehicles";
import DonutChart from "../DonutChart";
import CarOwnersSkeleton from "../skeletons/CarOwnersSkeleton";
import { PropTypes } from "prop-types";

/**
 * This is the car owners componenet. which contains a donut chart, which is projecting the data of types of car engines.
 * Here you can customize many background color, border radius, border width, font colors and border color of the component.
 */

const CarOwnerComponenet = ({
  borderRadius,
  borderColor,
  borderWidth,
  backgroundColor,
  titleFontColor,
  valueColor,
  dividerColor,
  maxWidth,
}) => {
  const [vehicleCount, setVehicleCount] = useState(vehicles);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const vehicleData = [];
    setIsLoading(true);

    services
      .carOwners()
      .then(res => {
        if (res?.data?.statusCode === 200) {
          vehicleData.push(res?.data?.data?.CarOwners?.data);
          setVehicleCount(vehicleData);
          setIsLoading(false);
        }
      })
      .catch(e => {
        //console.log(e);
      });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: `${backgroundColor}`,
        width: `${maxWidth}`,
        display: "flex",
        flexDirection: "column",
        px: 3,
        pt: "19px",
        pb: 4,
        height: "100%",
        borderRadius: `${borderRadius}`,
        border: `${borderWidth} solid ${borderColor}`,
      }}
    >
      {isLoading ? (
        <CarOwnersSkeleton />
      ) : (
        <Box>
          <Typography
            variant="titleLarge"
            sx={{
              pb: { xl: 0, lg: 0, md: 0, sm: 0, xs: 1 },
              color: `${titleFontColor}`,
            }}
          >
            Car Owners
          </Typography>
          <Grid container>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{
                width: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: 2,
              }}
            >
              <DonutChart vehicleCount={vehicleCount} />

              {/*<DonutChart count={totalVehicles} />*/}
            </Grid>
            <Grid item xs={12} md={6} sm={6} xl={6} lg={6}>
              <Box
                key={"1"}
                sx={theme => ({
                  display: "flex",
                  pt: 2.5,
                  flexDirection: "column",
                })}
              >
                <CenterFlexBox sx={{ justifyContent: "space-between" }}>
                  <Box sx={{ width: 1, display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "100%",
                        backgroundColor: `graph.gasoline`,
                        mr: 2,
                      }}
                    />
                    <Typography
                      sx={{ color: `${titleFontColor}` }}
                      variant="body_medium"
                    >
                      {"Gasoline Vehicles"}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body_medium"
                    sx={{ color: `${valueColor}` }}
                  >
                    {vehicleCount[0]?.Gasoline}
                  </Typography>
                </CenterFlexBox>
                <Divider
                  sx={{
                    width: 1,
                    borderColor: `${dividerColor}`,
                  }}
                />
              </Box>

              <Box
                key={"2"}
                sx={theme => ({
                  display: "flex",
                  pt: 2.5,
                  flexDirection: "column",
                })}
              >
                <CenterFlexBox sx={{ justifyContent: "space-between" }}>
                  <Box sx={{ width: 1, display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "100%",
                        backgroundColor: `graph.diesel`,
                        mr: 2,
                      }}
                    />
                    <Typography
                      sx={{ color: `${titleFontColor}` }}
                      variant="body_medium"
                    >
                      {"Diesel Vehicles"}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body_medium"
                    sx={{ color: `${valueColor}` }}
                  >
                    {vehicleCount[0]?.Diesel}
                  </Typography>
                </CenterFlexBox>
                <Divider
                  sx={{
                    width: 1,
                    borderColor: `${dividerColor}`,
                  }}
                />
              </Box>

              <Box
                key={"3"}
                sx={theme => ({
                  display: "flex",
                  pt: 2.5,
                  flexDirection: "column",
                })}
              >
                <CenterFlexBox sx={{ justifyContent: "space-between" }}>
                  <Box sx={{ width: 1, display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "100%",
                        backgroundColor: `graph.cng`,
                        mr: 2,
                      }}
                    />
                    <Typography
                      sx={{ color: `${titleFontColor}` }}
                      variant="body_medium"
                    >
                      {"CNG Vehicles"}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body_medium"
                    sx={{ color: `${valueColor}` }}
                  >
                    {vehicleCount[0]?.CNG}
                  </Typography>
                </CenterFlexBox>
                <Divider
                  sx={{
                    width: 1,
                    borderColor: `${dividerColor}`,
                  }}
                />
              </Box>

              <Box
                key={"4"}
                sx={theme => ({
                  display: "flex",
                  pt: 2.5,
                  flexDirection: "column",
                })}
              >
                <CenterFlexBox sx={{ justifyContent: "space-between" }}>
                  <Box sx={{ width: 1, display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "100%",
                        backgroundColor: `graph.electric`,
                        mr: 2,
                      }}
                    />
                    <Typography
                      sx={{ color: `${titleFontColor}` }}
                      variant="body_medium"
                    >
                      {"Electric Vehicles"}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body_medium"
                    sx={{ color: `${valueColor}` }}
                  >
                    {vehicleCount[0]?.Electric}
                  </Typography>
                </CenterFlexBox>
                <Divider
                  sx={{
                    width: 1,
                    borderColor: `${dividerColor}`,
                  }}
                />
              </Box>

              <Box
                key={"5"}
                sx={theme => ({
                  display: "flex",
                  pt: 2.5,
                  flexDirection: "column",
                })}
              >
                <CenterFlexBox sx={{ justifyContent: "space-between" }}>
                  <Box sx={{ width: 1, display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "100%",
                        backgroundColor: `graph.hybrid`,
                        mr: 2,
                      }}
                    />
                    <Typography
                      sx={{ color: `${titleFontColor}` }}
                      variant="body_medium"
                    >
                      {"Hybrid Vehicles"}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body_medium"
                    sx={{ color: `${valueColor}` }}
                  >
                    {vehicleCount[0]?.Hybrid}
                  </Typography>
                </CenterFlexBox>
                <Divider
                  sx={{
                    width: 1,
                    borderColor: `${dividerColor}`,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

CarOwnerComponenet.propTypes = {
  /**
   * change the border radius of the component
   */
  borderRadius: PropTypes.string,
  /**
   * change the border color of the component
   */
  borderColor: PropTypes.string,
  /**
   * change the border width of the component
   */
  borderWidth: PropTypes.string,
  /**
   * change the background color of the component
   */
  backgroundColor: PropTypes.string,
  /**
   * change the title font color
   */
  titleFontColor: PropTypes.string,
  /**
   * change the color of the number of car types
   */
  valueColor: PropTypes.string,
  /**
   * change the color of the divider
   */
  dividerColor: PropTypes.string,
  /**
   * fix the width of this component. for storybook dashboard "561px" is recomended for best view.
   */
  maxWidth: PropTypes.string,
};

CarOwnerComponenet.defaultProps = {
  borderRadius: "0px",
  borderColor: "transparent",
  borderWidth: "1px",
  backgroundColor: "common.white",
  titleFontColor: "#000",
  valueColor: `${themePalette.palette.text.lightSecondary}`,
  dividerColor: `${themePalette.palette.secondary.main}`,
  maxWidth: "100%",
};

export default CarOwnerComponenet;
