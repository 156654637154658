import styled from "@emotion/styled";
import { themePalette } from "../../constants/Theme/colors";

export const ButtonWrapper = styled.div(({}) => {
  return {
    "& .MuiButton-read-all": {
      fontFamily: '"ArchivoMedium", "sans-serif"',
      textTransform: "capitalize",

      border: `1px solid ${themePalette.palette.primary.main}`,
      background: themePalette.palette.common.white,
      color: themePalette.palette.primary.main,
      "&:hover": {
        backgroundColor: themePalette.palette.primary.main,
        color: themePalette.palette.common.white,
        border: `1px solid ${themePalette.palette.primary.main}`,
      },
    },
  };
});

export const UserIconWrapper = styled.div(({}) => {
  return {
    "& .MuiAvatar-root": {
      width: "50px",
      height: "50px",
    },
  };
});

export const NotificationTextWrapper = styled.div(({}) => {
  return {
    "& .MuiListItemText-root": {
      marginTop: "9px",
      marginLeft: "25px",
    },

    "& .MuiTypography-body1": {
      fontFamily: '"ArchivoRegular", "sans-serif" !important',
      color: themePalette.palette.common.tableTextColor,
      fontSize: 20,
      marginBottom: "6px",
    },

    "& .MuiTypography-body2": {
      fontFamily: '"AsapMedium", "sans-serif"',
      color: themePalette.palette.common.notificationTime,
      fontSize: 15,
      marginBottom: "16px",
    },
  };
});

export const DividerLineWrapper = styled.div(({}) => {
  return {
    "& .MuiList-padding .MuiBox-root:last-child .MuiDivider-root": {
      display: "none",
    },
  };
});
