import React from "react";
import { Skeleton, Typography } from "@mui/material";
import { Box, Grid } from "@mui/material";
// import DonutChart from "../DonutChart";

const CarOwnersSkeleton = () => {
  const carsSkeleton = [1, 2, 3, 4, 5];
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Skeleton animation="wave" width="200px">
        <Typography variant="titleLarge">.</Typography>
      </Skeleton>
      <Grid container sx={{ height: "80%", width: "100%" }}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {/* <Skeleton variant="circular" animation="wave"><DonutChart/></Skeleton> */}
          {/* putting graph as children of skeleton is now working fine. That's why need to put height here. */}
          <Skeleton
            variant="circular"
            animation="wave"
            sx={{
              width: { xs: "110px", sm: "120px", md: "130px", lg: "150px" },
              height: { xs: "110px", sm: "120px", md: "130px", lg: "150px" },
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ height: "100%" }}>
          {carsSkeleton.map((item, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", my: 2 }}
            >
              <Skeleton
                variant="circular"
                height="16px"
                width="16px"
                animation="wave"
                sx={{ mr: 1 }}
              />
              <Skeleton animation="wave" width="80%">
                <Typography variant="body_medium">.</Typography>
              </Skeleton>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CarOwnersSkeleton;
