import React, { useEffect, useState } from "react";
import { themePalette } from "../../../constants/Theme/colors";
import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { modesMonthList } from "../../../constants/staticData/modesMonthlyList";
import { modesDaysList } from "../../../constants/staticData/modesDaysList";

const ModeGraph = props => {
  const { info, interval, startWeek, endWeek, monthInfo, yearInfo } = props;
  const [yearData, setYearData] = useState(modesMonthList);
  const [monthData, setMonthData] = useState(modesDaysList);
  const [weekData, setWeekData] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [busData, setBusData] = useState([]);
  const [bicycleData, setBicycleData] = useState([]);
  const [carData, setCarData] = useState([]);
  const [scooterData, setScooterData] = useState([]);
  const [rideShareData, setRideShareData] = useState([]);
  const [trainData, setTrainData] = useState([]);
  const [walkData, setWalkData] = useState([]);
  const [noData, setNoData] = useState("");

  const startWeekDate = new Date(startWeek);
  const startEndDate = new Date(endWeek);

  const busInfo = [],
    bicycleInfo = [],
    carInfo = [],
    scooterInfo = [],
    rideShareInfo = [],
    trainInfo = [],
    walkInfo = [];

  useEffect(() => {
    if (interval == "Yearly") {
      setNoData("");
      if (
        info?.bus?.length === 0 &&
        info?.bicycle?.length === 0 &&
        info?.car?.length === 0 &&
        info?.electricScooter?.length === 0 &&
        info?.rideShare?.length === 0 &&
        info?.train?.length === 0 &&
        info?.walk?.length === 0
      ) {
        setNoData("No data present!");
        setYearData([]);
      } else {
        setNoData("");
        setYearData(modesMonthList);
        if (info?.bus !== undefined && info?.bus?.length) {
          info?.bus.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    bus: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.bicycle !== undefined && info?.bicycle?.length) {
          info?.bicycle.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    bicycle: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.car !== undefined && info?.car?.length) {
          info?.car.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    car: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (
          info?.electricScooter !== undefined &&
          info?.electricScooter?.length
        ) {
          info?.electricScooter.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    electricScooter: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.rideShare !== undefined && info?.rideShare?.length) {
          info?.rideShare.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    rideShare: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.train !== undefined && info?.train?.length) {
          info?.train.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    train: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.walk !== undefined && info?.walk?.length) {
          info?.walk.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    walk: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }
      }
    } else if (interval == "Monthly") {
      setNoData("");
      if (
        info?.bus?.length === 0 &&
        info?.bicycle?.length === 0 &&
        info?.car?.length === 0 &&
        info?.electricScooter?.length === 0 &&
        info?.rideShare?.length === 0 &&
        info?.train?.length === 0 &&
        info?.walk?.length === 0
      ) {
        setNoData("No data present!");
        setMonthData([]);
      } else {
        setNoData("");
        setMonthData(modesDaysList);
        if (info?.bus !== undefined && info?.bus?.length) {
          info?.bus.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    bus: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.bicycle !== undefined && info?.bicycle?.length) {
          info?.bicycle.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    bicycle: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.car !== undefined && info?.car?.length) {
          info?.car.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    car: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (
          info?.electricScooter !== undefined &&
          info?.electricScooter?.length
        ) {
          info?.electricScooter.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    electricScooter: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.rideShare !== undefined && info?.rideShare?.length) {
          info?.rideShare.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    rideShare: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.train !== undefined && info?.train?.length) {
          info?.train.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    train: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.walk !== undefined && info?.walk?.length) {
          info?.walk.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    walk: [data?.points],
                  };
                }
                return item;
              })
            );
          });
        }
      }
    } else if (interval == "Weekly") {
      setNoData("");
      if (
        info?.bus?.length === 0 &&
        info?.bicycle?.length === 0 &&
        info?.car?.length === 0 &&
        info?.electricScooter?.length === 0 &&
        info?.rideShare?.length === 0 &&
        info?.train?.length === 0 &&
        info?.walk?.length === 0
      ) {
        setNoData("No data present!");
        setWeekData([]);
      } else {
        setNoData("");
        const filterWeek = [];
        dateList.map(item => {
          filterWeek.push({
            id: item,
            bus: 0,
            bicycle: 0,
            car: 0,
            electricScooter: 0,
            rideShare: 0,
            train: 0,
            walk: 0,
          });
        });
        setWeekData(filterWeek);

        if (info?.bus !== undefined && info?.bus?.length) {
          info?.bus.map(data => {
            setWeekData(prev =>
              prev.map(item => {
                if (item?.id == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    bus: data?.points,
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.bicycle !== undefined && info?.bicycle?.length) {
          info?.bicycle.map(data => {
            setWeekData(prev =>
              prev.map(item => {
                if (item?.id == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    bicycle: data?.points,
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.car !== undefined && info?.car?.length) {
          info?.car.map(data => {
            setWeekData(prev =>
              prev.map(item => {
                if (item?.id == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    car: data?.points,
                  };
                }
                return item;
              })
            );
          });
        }

        if (
          info?.electricScooter !== undefined &&
          info?.electricScooter?.length
        ) {
          info?.electricScooter.map(data => {
            setWeekData(prev =>
              prev.map(item => {
                if (item?.id == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    electricScooter: data?.points,
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.rideShare !== undefined && info?.rideShare?.length) {
          info?.rideShare.map(data => {
            setWeekData(prev =>
              prev.map(item => {
                if (item?.id == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    rideShare: data?.points,
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.train !== undefined && info?.train?.length) {
          info?.train.map(data => {
            setWeekData(prev =>
              prev.map(item => {
                if (item?.id == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    train: data?.points,
                  };
                }
                return item;
              })
            );
          });
        }

        if (info?.walk !== undefined && info?.walk?.length) {
          info?.walk.map(data => {
            setWeekData(prev =>
              prev.map(item => {
                if (item?.id == new Date(data?.date).getDate()) {
                  return {
                    ...item,
                    walk: data?.points,
                  };
                }
                return item;
              })
            );
          });
        }
      }
    }
  }, [info, interval]);

  useEffect(() => {
    if (interval == "Yearly") {
      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.bus !== undefined && item?.bus?.length) {
            item?.bus.map(val => {
              busInfo.push({ id: item?.value, points: val });
            });
          } else {
            busInfo.push({ id: item?.value, points: null });
          }
        });
      }

      setBusData(busInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.bicycle !== undefined && item?.bicycle?.length) {
            item?.bicycle.map(val => {
              bicycleInfo.push({ id: item?.value, points: val });
            });
          } else {
            bicycleInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setBicycleData(bicycleInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.car !== undefined && item?.car?.length) {
            item?.car.map(val => {
              carInfo.push({ id: item?.value, points: val });
            });
          } else {
            carInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setCarData(carInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (
            item?.electricScooter !== undefined &&
            item?.electricScooter?.length
          ) {
            item?.electricScooter.map(val => {
              scooterInfo.push({ id: item?.value, points: val });
            });
          } else {
            scooterInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setScooterData(scooterInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.rideShare !== undefined && item?.rideShare?.length) {
            item?.rideShare.map(val => {
              rideShareInfo.push({ id: item?.value, points: val });
            });
          } else {
            rideShareInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setRideShareData(rideShareInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.train !== undefined && item?.train?.length) {
            item?.train.map(val => {
              trainInfo.push({ id: item?.value, points: val });
            });
          } else {
            trainInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setTrainData(trainInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.walk !== undefined && item?.walk?.length) {
            item?.walk.map(val => {
              walkInfo.push({ id: item?.value, points: val });
            });
          } else {
            walkInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setWalkData(walkInfo);
    } else if (interval == "Monthly") {
      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.bus !== undefined && item?.bus?.length) {
            item?.bus.map(val => {
              busInfo.push({ id: item?.value, points: val });
            });
          } else {
            busInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setBusData(busInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.bicycle !== undefined && item?.bicycle?.length) {
            item?.bicycle.map(val => {
              bicycleInfo.push({ id: item?.value, points: val });
            });
          } else {
            bicycleInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setBicycleData(bicycleInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.car !== undefined && item?.car?.length) {
            item?.car.map(val => {
              carInfo.push({ id: item?.value, points: val });
            });
          } else {
            carInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setCarData(carInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (
            item?.electricScooter !== undefined &&
            item?.electricScooter?.length
          ) {
            item?.electricScooter.map(val => {
              scooterInfo.push({ id: item?.value, points: val });
            });
          } else {
            scooterInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setScooterData(scooterInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.rideShare !== undefined && item?.rideShare?.length) {
            item?.rideShare.map(val => {
              rideShareInfo.push({ id: item?.value, points: val });
            });
          } else {
            rideShareInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setRideShareData(rideShareInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.train !== undefined && item?.train?.length) {
            item?.train.map(val => {
              trainInfo.push({ id: item?.value, points: val });
            });
          } else {
            trainInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setTrainData(trainInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.walk !== undefined && item?.walk?.length) {
            item?.walk.map(val => {
              walkInfo.push({ id: item?.value, points: val });
            });
          } else {
            walkInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setWalkData(walkInfo);
    } else if (interval == "Weekly") {
      if (weekData !== undefined && weekData?.length) {
        weekData.map(item => {
          if (item?.bus !== 0) {
            busInfo.push({ id: item?.id, points: item?.bus });
          } else {
            busInfo.push({ id: item?.id, points: null });
          }
        });
      }
      setBusData(busInfo);

      if (weekData !== undefined && weekData?.length) {
        weekData.map(item => {
          if (item?.bicycle !== 0) {
            bicycleInfo.push({ id: item?.id, points: item?.bicycle });
          } else {
            bicycleInfo.push({ id: item?.id, points: null });
          }
        });
      }
      setBicycleData(bicycleInfo);

      if (weekData !== undefined && weekData?.length) {
        weekData.map(item => {
          if (item?.car !== 0) {
            carInfo.push({ id: item?.id, points: item?.car });
          } else {
            carInfo.push({ id: item?.id, points: null });
          }
        });
      }
      setCarData(carInfo);

      if (weekData !== undefined && weekData?.length) {
        weekData.map(item => {
          if (item?.electricScooter !== 0) {
            scooterInfo.push({ id: item?.id, points: item?.electricScooter });
          } else {
            scooterInfo.push({ id: item?.id, points: null });
          }
        });
      }
      setScooterData(scooterInfo);

      if (weekData !== undefined && weekData?.length) {
        weekData.map(item => {
          if (item?.rideShare !== 0) {
            rideShareInfo.push({ id: item?.id, points: item?.rideShare });
          } else {
            rideShareInfo.push({ id: item?.id, points: null });
          }
        });
      }
      setRideShareData(rideShareInfo);

      if (weekData !== undefined && weekData?.length) {
        weekData.map(item => {
          if (item?.train !== 0) {
            trainInfo.push({ id: item?.id, points: item?.train });
          } else {
            trainInfo.push({ id: item?.id, points: null });
          }
        });
      }
      setTrainData(trainInfo);

      if (weekData !== undefined && weekData?.length) {
        weekData.map(item => {
          if (item?.walk !== 0) {
            walkInfo.push({ id: item?.id, points: item?.walk });
          } else {
            walkInfo.push({ id: item?.id, points: null });
          }
        });
      }
      setWalkData(walkInfo);
    }
  }, [yearData, monthData, weekData, interval]);

  useEffect(() => {
    const allDates = [];
    for (
      var day = startWeekDate;
      day <= startEndDate;
      day.setDate(day.getDate() + 1)
    ) {
      allDates.push(day.getDate());
    }
    setDateList(allDates);
  }, [startWeek, endWeek]);

  return (
    <Box sx={{ mt: 3 }}>
      {noData ? (
        <Box
          sx={{
            height: "350px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="heading3_large"
            sx={{ color: themePalette.palette.text.secondary }}
          >
            {noData}
          </Typography>
        </Box>
      ) : (
        <Chart
          height="360px"
          options={{
            colors: [
              themePalette.palette.chartColor.bus,
              themePalette.palette.chartColor.bicycle,
              themePalette.palette.chartColor.car,
              themePalette.palette.chartColor.scooter,
              themePalette.palette.chartColor.rideShare,
              themePalette.palette.chartColor.train,
              themePalette.palette.chartColor.walk,
            ],
            chart: {
              type: "line",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },

            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 2,
            },
            xaxis: {
              categories:
                interval == "Yearly"
                  ? yearData.map(val => val?.label)
                  : interval == "Monthly"
                  ? monthData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.label)
                  : interval == "Weekly"
                  ? dateList.map(val => val)
                  : "",
            },
            yaxis: {
              show: true,
              axisBorder: {
                show: true,
                color: "#d4d4d4",
                offsetX: 0,
                offsetY: 0,
              },

              labels: {
                formatter: function (val) {
                  if (val) {
                    if (val > 9999 && val < 999999) {
                      return (
                        Math.sign(val) * (Math.abs(val) / 1000).toFixed(1) +
                        "K Kg"
                      );
                    }

                    if (val > 999999 && val < 999999999) {
                      return (
                        Math.sign(val) * (Math.abs(val) / 1000000).toFixed(1) +
                        "M Kg"
                      );
                    }

                    if (val > 999999999) {
                      return (
                        Math.sign(val) *
                          (Math.abs(val) / 1000000000).toFixed(1) +
                        "B Kg"
                      );
                    } else {
                      return Math.round(val * 100) / 100 + " Kg";
                    }
                  }
                },
              },
            },
            legend: {
              markers: {
                fillColors: [
                  themePalette.palette.chartColor.bus,
                  themePalette.palette.chartColor.bicycle,
                  themePalette.palette.chartColor.car,
                  themePalette.palette.chartColor.scooter,
                  themePalette.palette.chartColor.rideShare,
                  themePalette.palette.chartColor.train,
                  themePalette.palette.chartColor.walk,
                ],
              },
            },
            markers: {
              size: 6,
            },
            tooltip: {
              x: {
                format: "dd/MM/yy HH:mm",
              },
            },
          }}
          series={[
            {
              name: "Bus",
              data:
                interval == "Yearly"
                  ? busData.map(val => val?.points)
                  : interval == "Monthly"
                  ? busData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.points)
                  : interval == "Weekly"
                  ? busData.map(val => val?.points)
                  : "",
            },
            {
              name: "Bicycle",
              data:
                interval == "Yearly"
                  ? bicycleData.map(val => val?.points)
                  : interval == "Monthly"
                  ? bicycleData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.points)
                  : interval == "Weekly"
                  ? bicycleData.map(val => val?.points)
                  : "",
            },
            {
              name: "Car",
              data:
                interval == "Yearly"
                  ? carData.map(val => val?.points)
                  : interval == "Monthly"
                  ? carData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.points)
                  : interval == "Weekly"
                  ? carData.map(val => val?.points)
                  : "",
            },
            {
              name: "Electric Scooter",
              data:
                interval == "Yearly"
                  ? scooterData.map(val => val?.points)
                  : interval == "Monthly"
                  ? scooterData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.points)
                  : interval == "Weekly"
                  ? scooterData.map(val => val?.points)
                  : "",
            },
            {
              name: "Rideshare",
              data:
                interval == "Yearly"
                  ? rideShareData.map(val => val?.points)
                  : interval == "Monthly"
                  ? rideShareData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.points)
                  : interval == "Weekly"
                  ? rideShareData.map(val => val?.points)
                  : "",
            },
            {
              name: "Train",
              data:
                interval == "Yearly"
                  ? trainData.map(val => val?.points)
                  : interval == "Monthly"
                  ? trainData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.points)
                  : interval == "Weekly"
                  ? trainData.map(val => val?.points)
                  : "",
            },
            {
              name: "Walk",
              data:
                interval == "Yearly"
                  ? walkData.map(val => val?.points)
                  : interval == "Monthly"
                  ? walkData
                      .slice(0, new Date(yearInfo, monthInfo, 0).getDate())
                      .map(val => val?.points)
                  : interval == "Weekly"
                  ? walkData.map(val => val?.points)
                  : "",
            },
          ]}
        />
      )}
    </Box>
  );
};

export default ModeGraph;
