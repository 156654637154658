import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { modesMonthList } from "../../constants/staticData/modesMonthlyList";
import { modesDaysList } from "../../constants/staticData/modesDaysList";
import { themePalette } from "../../constants/Theme/colors";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LegendWrapper } from "./style";
import Chart from "react-apexcharts";
import { DatePickerWrapper } from "../../pages/Users/Profile/styles";
import { DropdownWrapper } from "../../pages/Users/styles";
import services from "../../services";
import { ChartSkeleton } from "../../pages/Users/Profile/Profile Skeleton/chartSkeleton";
import {
  Divider,
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

/**
  Documentation and Settings for Bar Graph displaying data on monthly/
  yearly basis for different transport modes on User Profile Page 
*/

const PointsChart = ({
  backgroundColor,
  borderRadius,
  heading,
  monthLabel,
  yearLabel,
  dividerColor,
  totalPointsText,
  chartBorderColor,
  chartLabelColor,
  userId,
  chartLoader,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [criteria, setCriteria] = useState(0);
  const [value, setValue] = useState(dayjs());
  const [pointsData, setPointsData] = useState([{}]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [yearVal, setYearVal] = useState(new Date().getFullYear());
  const [monthVal, setMonthVal] = useState(new Date().getMonth());
  const [yearData, setYearData] = useState(modesMonthList);
  const [monthData, setMonthData] = useState(modesDaysList);
  const [busData, setBusData] = useState([]);
  const [bicycleData, setBicycleData] = useState([]);
  const [carData, setCarData] = useState([]);
  const [scooterData, setScooterData] = useState([]);
  const [rideShareData, setRideShareData] = useState([]);
  const [trainData, setTrainData] = useState([]);
  const [walkData, setWalkData] = useState([]);
  const [noData, setNoData] = useState("");

  const busInfo = [],
    bicycleInfo = [],
    carInfo = [],
    scooterInfo = [],
    rideShareInfo = [],
    trainInfo = [],
    walkInfo = [];

  const handleChange = e => {
    setCriteria(e.target.value);
  };

  const onKeyDown = e => {
    e.preventDefault();
  };

  useEffect(() => {
    var params = {};
    if (criteria == 1) {
      params = {
        userId: userId,
        year: value["$y"],
      };
      setIsLoading(true);

      services
        .pointsChart(params)
        .then(res => {
          setTotalPoints(res?.data?.data?.totalPointsYearly);
          setPointsData(res?.data?.data?.yearData);
          setIsLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    } else if (criteria == 0) {
      params = {
        userId: userId,
        month: value["$M"] + 1,
        year: value["$y"],
      };

      setMonthVal(value["$M"] + 1);
      setYearVal(value["$y"]);
      setIsLoading(true);

      services
        .pointsChart(params)
        .then(res => {
          setTotalPoints(res?.data?.data?.totalPointsMonthly);
          setPointsData(res?.data?.data?.monthData);
          setIsLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [value, criteria]);

  useEffect(() => {
    if (criteria == 1) {
      setNoData("");
      if (
        pointsData?.bus?.length == 0 &&
        pointsData?.bicycle?.length == 0 &&
        pointsData?.car?.length == 0 &&
        pointsData?.electricScooter?.length == 0 &&
        pointsData?.rideShare?.length == 0 &&
        pointsData?.train?.length == 0 &&
        pointsData?.walk?.length == 0
      ) {
        setNoData("No data present!");
        setYearData([]);
      } else {
        setNoData("");
        setYearData(modesMonthList);
        if (pointsData?.bus !== undefined && pointsData?.bus?.length) {
          pointsData?.bus.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    bus: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.bicycle !== undefined && pointsData?.bicycle?.length) {
          pointsData?.bicycle.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    bicycle: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.car !== undefined && pointsData?.car?.length) {
          pointsData?.car.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    car: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (
          pointsData?.electricScooter !== undefined &&
          pointsData?.electricScooter?.length
        ) {
          pointsData?.electricScooter.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    electricScooter: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (
          pointsData?.rideShare !== undefined &&
          pointsData?.rideShare?.length
        ) {
          pointsData?.rideShare.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    rideShare: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.train !== undefined && pointsData?.train?.length) {
          pointsData?.train.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    train: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.walk !== undefined && pointsData?.walk?.length) {
          pointsData?.walk.map(data => {
            setYearData(prev =>
              prev.map(item => {
                if (item?.value == data?.month) {
                  return {
                    ...item,
                    walk: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }
      }
    } else if (criteria == 0) {
      setNoData("");
      if (
        pointsData?.bus?.length == 0 &&
        pointsData?.bicycle?.length == 0 &&
        pointsData?.car?.length == 0 &&
        pointsData?.electricScooter?.length == 0 &&
        pointsData?.rideShare?.length == 0 &&
        pointsData?.train?.length == 0 &&
        pointsData?.walk?.length == 0
      ) {
        setNoData("No data present!");
        setMonthData([]);
      } else {
        setNoData("");
        setMonthData(modesDaysList);
        if (pointsData?.bus !== undefined && pointsData?.bus?.length) {
          pointsData?.bus.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == data?.date) {
                  return {
                    ...item,
                    bus: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.bicycle !== undefined && pointsData?.bicycle?.length) {
          pointsData?.bicycle.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == data?.date) {
                  return {
                    ...item,
                    bicycle: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.car !== undefined && pointsData?.car?.length) {
          pointsData?.car.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == data?.date) {
                  return {
                    ...item,
                    car: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (
          pointsData?.electricScooter !== undefined &&
          pointsData?.electricScooter?.length
        ) {
          pointsData?.electricScooter.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == data?.date) {
                  return {
                    ...item,
                    electricScooter: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (
          pointsData?.rideShare !== undefined &&
          pointsData?.rideShare?.length
        ) {
          pointsData?.rideShare.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == data?.date) {
                  return {
                    ...item,
                    rideShare: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.train !== undefined && pointsData?.train?.length) {
          pointsData?.train.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == data?.date) {
                  return {
                    ...item,
                    train: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }

        if (pointsData?.walk !== undefined && pointsData?.walk?.length) {
          pointsData?.walk.map(data => {
            setMonthData(prev =>
              prev.map(item => {
                if (item?.value == data?.date) {
                  return {
                    ...item,
                    walk: [data?.co2Offset],
                  };
                }
                return item;
              })
            );
          });
        }
      }
    }
  }, [pointsData, criteria]);

  useEffect(() => {
    if (criteria == 1) {
      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.bus !== undefined && item?.bus?.length) {
            item?.bus.map(val => {
              busInfo.push({ id: item?.value, points: val });
            });
          } else {
            busInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setBusData(busInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.bicycle !== undefined && item?.bicycle?.length) {
            item?.bicycle.map(val => {
              bicycleInfo.push({ id: item?.value, points: val });
            });
          } else {
            bicycleInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setBicycleData(bicycleInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.car !== undefined && item?.car?.length) {
            item?.car.map(val => {
              carInfo.push({ id: item?.value, points: val });
            });
          } else {
            carInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setCarData(carInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (
            item?.electricScooter !== undefined &&
            item?.electricScooter?.length
          ) {
            item?.electricScooter.map(val => {
              scooterInfo.push({ id: item?.value, points: val });
            });
          } else {
            scooterInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setScooterData(scooterInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.rideShare !== undefined && item?.rideShare?.length) {
            item?.rideShare.map(val => {
              rideShareInfo.push({ id: item?.value, points: val });
            });
          } else {
            rideShareInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setRideShareData(rideShareInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.train !== undefined && item?.train?.length) {
            item?.train.map(val => {
              trainInfo.push({ id: item?.value, points: val });
            });
          } else {
            trainInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setTrainData(trainInfo);

      if (yearData !== undefined && yearData?.length) {
        yearData.map(item => {
          if (item?.walk !== undefined && item?.walk?.length) {
            item?.walk.map(val => {
              walkInfo.push({ id: item?.value, points: val });
            });
          } else {
            walkInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setWalkData(walkInfo);
    } else if (criteria == 0) {
      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.bus !== undefined && item?.bus?.length) {
            item?.bus.map(val => {
              busInfo.push({ id: item?.value, points: val });
            });
          } else {
            busInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setBusData(busInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.bicycle !== undefined && item?.bicycle?.length) {
            item?.bicycle.map(val => {
              bicycleInfo.push({ id: item?.value, points: val });
            });
          } else {
            bicycleInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setBicycleData(bicycleInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.car !== undefined && item?.car?.length) {
            item?.car.map(val => {
              carInfo.push({ id: item?.value, points: val });
            });
          } else {
            carInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setCarData(carInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (
            item?.electricScooter !== undefined &&
            item?.electricScooter?.length
          ) {
            item?.electricScooter.map(val => {
              scooterInfo.push({ id: item?.value, points: val });
            });
          } else {
            scooterInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setScooterData(scooterInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.rideShare !== undefined && item?.rideShare?.length) {
            item?.rideShare.map(val => {
              rideShareInfo.push({ id: item?.value, points: val });
            });
          } else {
            rideShareInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setRideShareData(rideShareInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.train !== undefined && item?.train?.length) {
            item?.train.map(val => {
              trainInfo.push({ id: item?.value, points: val });
            });
          } else {
            trainInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setTrainData(trainInfo);

      if (monthData !== undefined && monthData?.length) {
        monthData.map(item => {
          if (item?.walk !== undefined && item?.walk?.length) {
            item?.walk.map(val => {
              walkInfo.push({ id: item?.value, points: val });
            });
          } else {
            walkInfo.push({ id: item?.value, points: null });
          }
        });
      }
      setWalkData(walkInfo);
    }
  }, [yearData, monthData, criteria]);

  return (
    <>
      {isLoading ? (
        <ChartSkeleton />
      ) : (
        <Box
          sx={{
            backgroundColor: backgroundColor,
            borderRadius: `${borderRadius}px`,
          }}
        >
          <Grid container>
            <Grid item xl={5} lg={5} md={6} sm={4} xs={12}>
              <Box
                sx={{
                  mb: 1,
                  px: 3,
                  pt: 4,
                }}
              >
                <Typography variant="heading3">{heading}</Typography>
              </Box>
            </Grid>
            <Grid item xl={7} lg={7} md={6} sm={8} xs={12}>
              <DropdownWrapper>
                <Box sx={{ px: 3, float: "right" }}>
                  <Grid container spacing={3}>
                    <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                      <FormControl
                        sx={{
                          mt: { xl: 3, lg: 3, md: 3, sm: 3, xs: 1 },
                          mb: 2,
                          minWidth: "160px",
                          float: "right",
                          background: theme => theme.palette.common.white,
                        }}
                        size="small"
                      >
                        <Select
                          labelId="criteriaSelect"
                          id="criteriaSelect"
                          value={criteria}
                          onChange={handleChange}
                          MenuProps={{
                            sx: {
                              "& .Mui-selected": {
                                color: `${themePalette.palette.primary.main} !important`,
                              },
                            },
                          }}
                        >
                          <MenuItem value={0}>{monthLabel}</MenuItem>
                          <MenuItem value={1}>{yearLabel}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                      {criteria == 0 ? (
                        <DatePickerWrapper>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={["year", "month"]}
                              value={value}
                              openTo="month"
                              onChange={newValue => {
                                setValue(newValue);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  onKeyDown={onKeyDown}
                                />
                              )}
                              minDate={new Date("2022-10-01")}
                              maxDate={new Date()}
                            />
                          </LocalizationProvider>
                        </DatePickerWrapper>
                      ) : (
                        <DatePickerWrapper>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={["year"]}
                              value={value}
                              onChange={newValue => {
                                setValue(newValue);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  onKeyDown={onKeyDown}
                                />
                              )}
                              minDate={new Date("2022-10-01")}
                              maxDate={new Date()}
                            />
                          </LocalizationProvider>
                        </DatePickerWrapper>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </DropdownWrapper>
            </Grid>
          </Grid>
          <Divider
            sx={{
              border: `1px solid ${dividerColor}`,
              opacity: "0.7",
            }}
          />

          {noData ? (
            <Box
              sx={{
                height: "350px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="heading3_large"
                sx={{ color: themePalette.palette.text.secondary }}
              >
                {noData}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                variant="heading_regular"
                sx={{ position: "absolute", m: 2 }}
              >
                {totalPointsText} : {totalPoints + " Kg"}
              </Typography>
              <Box sx={{ mt: 8 }}>
                <LegendWrapper>
                  <Chart
                    options={{
                      colors: [
                        themePalette.palette.chartColor.bus,
                        themePalette.palette.chartColor.bicycle,
                        themePalette.palette.chartColor.car,
                        themePalette.palette.chartColor.scooter,
                        themePalette.palette.chartColor.rideShare,
                        themePalette.palette.chartColor.train,
                        themePalette.palette.chartColor.walk,
                      ],
                      chart: {
                        type: "bar",
                        stacked: true,
                        toolbar: {
                          show: false,
                        },
                        zoom: {
                          enabled: true,
                        },
                      },

                      grid: {
                        show: true,
                        xaxis: {
                          lines: {
                            show: false,
                          },
                        },
                        yaxis: {
                          lines: {
                            show: false,
                          },
                        },
                      },

                      plotOptions: {
                        bar: {
                          horizontal: false,
                          borderRadius: 4,
                        },
                      },

                      dataLabels: {
                        enabled: false,
                      },

                      xaxis: {
                        show: true,
                        showAlways: true,
                        showForNullSeries: true,
                        categories:
                          criteria == 1
                            ? yearData.map(val => val?.label)
                            : criteria == 0
                            ? monthData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.label)
                            : "",

                        axisBorder: {
                          show: true,
                          color: chartBorderColor,
                          offsetX: 0,
                          offsetY: 0,
                          height: 2,
                        },

                        labels: {
                          style: {
                            colors: [
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                            ],
                            fontSize: 16,
                            fontFamily: '"AsapRegular", "sans-serif"',
                            cssClass: "apexcharts-xaxis-label",
                          },
                          offsetY: 5,
                        },
                      },

                      yaxis: {
                        show: true,
                        showAlways: true,
                        showForNullSeries: true,

                        axisBorder: {
                          show: true,
                          color: chartBorderColor,
                          offsetX: 0,
                          offsetY: 0,
                          width: 2,
                        },

                        labels: {
                          style: {
                            colors: [
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                              chartLabelColor,
                            ],
                            fontSize: 16,
                            fontFamily: '"AsapRegular", "sans-serif"',
                            cssClass: "apexcharts-xaxis-label",
                          },

                          offsetY: 0,
                          formatter: function (val) {
                            if (val) {
                              if (val > 9999 && val < 999999) {
                                return (
                                  Math.sign(val) *
                                    (Math.abs(val) / 1000).toFixed(1) +
                                  "K Kg"
                                );
                              }

                              if (val > 999999 && val < 999999999) {
                                return (
                                  Math.sign(val) *
                                    (Math.abs(val) / 1000000).toFixed(1) +
                                  "M Kg"
                                );
                              }

                              if (val > 999999999) {
                                return (
                                  Math.sign(val) *
                                    (Math.abs(val) / 1000000000).toFixed(1) +
                                  "B Kg"
                                );
                              } else {
                                return Math.round(val * 100) / 100 + " Kg";
                              }
                            }
                          },
                        },
                      },

                      legend: {
                        position: "top",
                        horizontalAlign: "right",
                        offsetX: 40,
                        fontFamily: '"ArchivoMedium", "sans-serif"',
                        fontSize: 16,

                        markers: {
                          width: 12,
                          height: 12,
                          offsetX: -8,
                          offsetY: 0,
                          fillColors: [
                            themePalette.palette.chartColor.bus,
                            themePalette.palette.chartColor.bicycle,
                            themePalette.palette.chartColor.car,
                            themePalette.palette.chartColor.scooter,
                            themePalette.palette.chartColor.rideShare,
                            themePalette.palette.chartColor.train,
                            themePalette.palette.chartColor.walk,
                          ],
                        },

                        itemMargin: {
                          horizontal: 10,
                          vertical: 0,
                        },
                      },

                      fill: {
                        opacity: 1,
                      },
                    }}
                    series={[
                      {
                        name: "Bus",
                        data:
                          criteria == 1
                            ? busData.map(val => val?.points)
                            : criteria == 0
                            ? busData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.points)
                            : "",
                      },
                      {
                        name: "Bicycle",
                        data:
                          criteria == 1
                            ? bicycleData.map(val => val?.points)
                            : criteria == 0
                            ? bicycleData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.points)
                            : "",
                      },
                      {
                        name: "Car",
                        data:
                          criteria == 1
                            ? carData.map(val => val?.points)
                            : criteria == 0
                            ? carData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.points)
                            : "",
                      },
                      {
                        name: "Electric Scooter",
                        data:
                          criteria == 1
                            ? scooterData.map(val => val?.points)
                            : criteria == 0
                            ? scooterData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.points)
                            : "",
                      },
                      {
                        name: "Rideshare",
                        data:
                          criteria == 1
                            ? rideShareData.map(val => val?.points)
                            : criteria == 0
                            ? rideShareData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.points)
                            : "",
                      },
                      {
                        name: "Train",
                        data:
                          criteria == 1
                            ? trainData.map(val => val?.points)
                            : criteria == 0
                            ? trainData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.points)
                            : "",
                      },
                      {
                        name: "Walk",
                        data:
                          criteria == 1
                            ? walkData.map(val => val?.points)
                            : criteria == 0
                            ? walkData
                                .slice(
                                  0,
                                  new Date(yearVal, monthVal, 0).getDate()
                                )
                                .map(val => val?.points)
                            : "",
                      },
                    ]}
                    type="bar"
                    height={350}
                  ></Chart>
                </LegendWrapper>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

PointsChart.defaultProps = {
  backgroundColor: themePalette.palette.common.tabTableBackground,
  borderRadius: 5,
  heading: "Co2 Saved History",
  monthLabel: "Monthly",
  yearLabel: "Yearly",
  dividerColor: themePalette.palette.secondary.main,
  totalPointsText: "Total Co2 Saved",
  chartBorderColor: themePalette.palette.common.chartBorder,
  chartLabelColor: themePalette.palette.text.darkSecondary,
};

PointsChart.propTypes = {
  /**
  Change background color of Points Chart
  */
  backgroundColor: PropTypes.string.isRequired,

  /**
  Change border radius of points chart
  */
  borderRadius: PropTypes.number.isRequired,

  /**
  Change heading of Component
  */
  heading: PropTypes.string.isRequired,

  /**
  Change text of Month Label
  */
  monthLabel: PropTypes.string.isRequired,

  /**
  Change text of Year Label
  */
  yearLabel: PropTypes.string.isRequired,

  /**
  Change color of divider
  */
  dividerColor: PropTypes.string.isRequired,

  /**
  Change value of total points text
  */
  totalPointsText: PropTypes.string.isRequired,

  /**
  Change color of border axis(in chart)
  */
  chartBorderColor: PropTypes.string.isRequired,

  /**
  Change color of label(in chart)
  */
  chartLabelColor: PropTypes.string.isRequired,
};

export default PointsChart;
