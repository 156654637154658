import * as React from "react";
import theme from "../../constants/Theme";
import {
  ButtonWrapper,
  UserIconWrapper,
  NotificationTextWrapper,
  DividerLineWrapper,
} from "./styles";
import notificationUser from "./assets/notificationUser.png";
import { allNotifications } from "../../constants/notifications/notification";
import {
  Box,
  Stack,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Button,
} from "@mui/material";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const Notifications = () => {
  return (
    <DashboardLayout>
      <Box sx={{ pt: { xl: 1, lg: 1, md: 1, sm: 1, xs: 3 } }}>
        <Box sx={{ textAlign: "right" }}>
          <ButtonWrapper>
            <Button variant="read-all" sx={{ p: 3, width: "175px" }}>
              Read All
            </Button>
          </ButtonWrapper>
        </Box>

        <Stack sx={styles.centeredContent}>
          <Box sx={{ maxWidth: theme.breakpoints.values.lg, width: 1 }}>
            <DividerLineWrapper>
              <List>
                {allNotifications.map(message => (
                  <Box>
                    <ListItem alignItems="flex-start" sx={{ px: 1, pt: 2}}>
                      <UserIconWrapper>
                        <ListItemAvatar>
                          <Avatar alt="User Icon" src={notificationUser} />
                        </ListItemAvatar>
                      </UserIconWrapper>

                      <NotificationTextWrapper>
                        <ListItemText
                          primary={message.notificationText}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline", mt: 1 }}
                                component="span"
                                variant="body2"
                              >
                                {message.notificationTime}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </NotificationTextWrapper>
                    </ListItem>

                    <Divider
                      variant="inset"
                      component="li"
                      sx={{
                        border: `1px solid ${theme.palette.common.dropdownBorder}`,
                        ml: 2,
                        opacity: "0.4",
                      }}
                    />
                  </Box>
                ))}
              </List>
            </DividerLineWrapper>
          </Box>
        </Stack>
      </Box>
    </DashboardLayout>
  );
};

export default Notifications;
