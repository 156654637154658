import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
// import ModeGraph from "../ModeGraph";

const UserAndModeGraphSkeleton = () => {
  return (
    <Box sx={{ width: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: 1,
          mb: 4,
        }}
      >
        <Skeleton animation="wave" width="30%">
          <Typography variant="titleLarge">.</Typography>
        </Skeleton>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "70%" }}>
          <Skeleton animation="wave" width="30%">
            <Typography variant="titleLarge">.</Typography>
          </Skeleton>
          <Skeleton animation="wave" width="40%" sx={{ ml: 1 }}>
            <Typography variant="titleLarge">.</Typography>
          </Skeleton>
        </Box>
      </Box>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height="360px"
        sx={{ borderRadius: "4px" }}
      />
      {/* <Skeleton animation="wave"><ModeGraph/></Skeleton> */}
      {/* putting graph as children of skeleton is now working fine. That's why need to put height here. */}
    </Box>
  );
};

export default UserAndModeGraphSkeleton;
