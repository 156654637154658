import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeTable = createTheme(themePalette, {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "unset",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: "'AsapMedium', sans-serif",
          backgroundColor: `${themePalette.palette.background.tertiary} !important`,
          color: `${themePalette.palette.text.primary} !important`,
          fontSize: 16,
          paddingTop: "25px",
          paddingBottom: "25px",

          "&:first-child": {
            paddingLeft: "32px",
          },
        },

        root: {
          backgroundColor: themePalette.palette.common.white,
        },

        body: {
          fontFamily: "'AsapRegular', sans-serif",
          fontSize: "16px !important",
          color: themePalette.palette.common.tableTextColor,

          "@media (max-width:1150px)": {
            fontSize: "14px !important",
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: "'AsapRegular', sans-serif",
          color: themePalette.palette.common.tableTextColor,
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottom: "unset",
        },

        selectLabel: {
          fontFamily: "'AsapRegular', sans-serif",
          fontSize: 15,
        },

        select: {
          fontFamily: "'AsapRegular', sans-serif",
          fontSize: 15,
        },

        displayedRows: {
          fontFamily: "'AsapRegular', sans-serif",
          fontSize: 15,
        },
      },
    },
  },
});
