import { Grid, Box, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { authActions } from "../../redux/slice/auth-slice";
import CarOwnerComponenet from "./component/stories/CarOwnerComponenet";
import TodaysStats from "./component/stories/TodaysStats";
import TopPerformers from "./component/stories/TopPerformers";
import TotalUsersAndTrees from "./component/stories/TotalUsersAndTrees";
import UserAndMode from "./component/UserAndMode";
import theme from "../../constants/Theme";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import CommonDialog from "../../common/commonDialog/CommonDialog";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ROUTES_PATH from "../../constants/routes";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },

  columnWidth: {
    [theme.breakpoints.up("xl")]: {
      width: "auto",
    },
  },
};
const DashboardDashboard = () => {
  const authToken = useSelector(state => state?.auth?.tokenValid);
  const [isPreviewShown, setPreviewShown] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickClose = () => {
    setPreviewShown(false);
    localStorage.clear();
    dispatch(authActions.resetLoggedInUser());
    dispatch(authActions.validToken({ currToken: true }));
    navigate(ROUTES_PATH.LOG_IN);
  };

  useEffect(() => {
    if (!authToken) {
      setPreviewShown(true);
    }
  }, [authToken]);

  return (
    <DashboardLayout>
      <Box>
        <Stack sx={styles.centeredContent}>
          <Grid
            container
            sx={theme => ({
              maxWidth: theme.breakpoints.values.lg,
            })}
          >
            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} sx={{ p: 1 }}>
              <TotalUsersAndTrees />
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={6} xl={6} sx={{ p: 1 }}>
              <CarOwnerComponenet />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              sx={{ p: 1 }}
              order={{ xs: 3, md: 0 }}
            >
              <TopPerformers />
            </Grid>
            <Grid item xs={12} md={9} sx={{ p: 1 }}>
              <UserAndMode />
            </Grid>
            <Grid item xs={12} sm={12} md={3} sx={{ p: 1 }}>
              <TodaysStats />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <CommonDialog
        open={isPreviewShown}
        title={"Session Expired!"}
        subtext={"Your session has been expired. Please try to login again."}
        iconSpacing={"24px"}
        dialogSize={"xs"}
        dialogIcon={
          <ErrorOutlineOutlinedIcon
            sx={{
              fontSize: 28,
              color: theme => theme.palette.primary.main,
              opacity: 0.8,
            }}
          />
        }
        dialogAction={
          <React.Fragment>
            <Button
              variant="contained"
              sx={{ fontSize: 19, py: 3 }}
              onClick={handleClickClose}
            >
              Close
            </Button>
          </React.Fragment>
        }
      />
    </DashboardLayout>
  );
};

export default DashboardDashboard;
