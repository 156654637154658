import React from "react";
import PropTypes from "prop-types";
import theme from "../../constants/Theme";
import { themePalette } from "../../constants/Theme/colors";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import MergeTypeOutlinedIcon from "@mui/icons-material/MergeTypeOutlined";
import { ProfileInfoWrapper } from "../../pages/Users/Profile/styles";
import defaultuser from "../../pages/Users/assets/defaultuser.png";

/**
  Documentation and Settings for User Profile Card consisting of 
  User Image, Full Name, Mobile No, Email Address on User Profile Page 
*/

const UserProfileCard = ({
  profileImg,
  backgroundColor,
  borderRadius,
  iconBackgroundColor,
  iconColor,
  nameLabel,
  fullName,
  mobileLabel,
  contact,
  emailLabel,
  email,
  questionAnswer,
}) => {
  const [imageLoading, setImageLoading] = React.useState(true);
  const imageLoad = () => {
    setImageLoading(false);
  };
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        px: { xl: 4, lg: 4, md: 4, sm: 4, xs: 0 },
        py: 8,
        mt: 3,
        borderRadius: `${borderRadius}px`,
      }}
    >
      <Grid container>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <Box>
            <Box
              component="img"
              alt="User Profile Picture"
              src={imageLoading ? defaultuser : profileImg}
              width={"125px"}
              height={"125px"}
              sx={{
                borderRadius: "50%",
                [theme.breakpoints.up("xs")]: {
                  display: "block",
                  m: "0 auto",
                  my: 1,
                },
              }}
              onLoad={() => imageLoad()}
            />
          </Box>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
          <ProfileInfoWrapper>
            <List>
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: iconBackgroundColor,
                          p: "2px",
                        }}
                      >
                        <AccountBoxOutlinedIcon
                          sx={{
                            color: iconColor,
                          }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={nameLabel} secondary={fullName} />
                  </ListItem>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: iconBackgroundColor,
                          p: "2px",
                        }}
                      >
                        <PhoneOutlinedIcon
                          sx={{
                            color: iconColor,
                          }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={mobileLabel} secondary={contact} />
                  </ListItem>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: iconBackgroundColor,
                          p: "2px",
                        }}
                      >
                        <MailOutlinedIcon
                          sx={{
                            color: iconColor,
                          }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={emailLabel} secondary={email} />
                  </ListItem>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: iconBackgroundColor,
                          p: "2px",
                        }}
                      >
                        <DirectionsCarFilledOutlinedIcon
                          sx={{
                            color: iconColor,
                          }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={"Own a car?"}
                      secondary={
                        questionAnswer[0]?.skipOne === true ||
                        questionAnswer === "Skipped" ? (
                          <Typography
                            variant="body_regular"
                            sx={{
                              fontStyle: "italic",
                              color: theme => theme.palette.common.black,
                            }}
                          >
                            Skipped
                          </Typography>
                        ) : (
                          questionAnswer[0]?.answer
                        )
                      }
                      sx={{ color: "red" }}
                    />
                  </ListItem>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: iconBackgroundColor,
                          p: "2px",
                        }}
                      >
                        <MergeTypeOutlinedIcon
                          sx={{
                            color: iconColor,
                          }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={"Type of car?"}
                      secondary={
                        questionAnswer[1]?.skipTwo === true ||
                        questionAnswer === "Skipped" ? (
                          <Typography
                            variant="body_regular"
                            sx={{
                              fontStyle: "italic",
                              color: theme => theme.palette.common.black,
                            }}
                          >
                            Skipped
                          </Typography>
                        ) : (
                          questionAnswer[1]?.answer
                        )
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </List>
          </ProfileInfoWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

UserProfileCard.defaultProps = {
  backgroundColor: `${themePalette.palette.common.tabTableBackground}`,
  borderRadius: 5,
  iconBackgroundColor: themePalette.palette.common.userProfileIconBg,
  iconColor: themePalette.palette.common.userProfileIcon,
  nameLabel: "Full Name",
  fullName: "Steve Mark",
  mobileLabel: "Mobile Number",
  contact: "9273738113",
  emailLabel: "Email Address",
  email: "abcd139@gmail.com",
};

UserProfileCard.propTypes = {
  /**
  Change background color of profile card
  */
  backgroundColor: PropTypes.string.isRequired,

  /**
  Change border radius of profile card
  */
  borderRadius: PropTypes.number.isRequired,

  /**
  Change background color of icon
  */
  iconBackgroundColor: PropTypes.string.isRequired,

  /**
  Change color of icon
  */
  iconColor: PropTypes.string.isRequired,

  /**
  Change label for name
  */
  nameLabel: PropTypes.string.isRequired,

  /**
  Change value of full name
  */
  fullName: PropTypes.string.isRequired,

  /**
  Change label for contact number
  */
  mobileLabel: PropTypes.string.isRequired,

  /**
  Change value of mobile number
  */
  contact: PropTypes.string.isRequired,

  /**
  Change label for email address
  */
  emailLabel: PropTypes.string.isRequired,

  /**
  Change value of email
  */
  email: PropTypes.string.isRequired,
};

export default UserProfileCard;
