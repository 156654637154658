import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { Skeleton } from "@mui/material";

const TotalUsersAndTreesSkeleton = () => {
  return (
    <Box
      sx={theme => ({
        backgroundColor: "background.main",
        width: 1,
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        py: { xs: 2 },
      })}
    >
      <Skeleton variant="circular" animation="wave" sx={{ ml: 3 }}>
        <Avatar />
      </Skeleton>
      <Box
        sx={{ display: "flex", flexDirection: "column", ml: 3, width: "60%" }}
      >
        <Skeleton animation="wave" width="80%">
          <Typography variant="heading_semiBold">.</Typography>
        </Skeleton>
        <Skeleton animation="wave" width="80%">
          <Typography variant="heading_medium">.</Typography>
        </Skeleton>
      </Box>
    </Box>
  );
};

export default TotalUsersAndTreesSkeleton;
