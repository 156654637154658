import { Box } from "@mui/system";
import React from "react";
import Chart from "react-apexcharts";

const DonutChart = props => {
  const { vehicleCount } = props;
  const chartData = [];
  chartData.push(
    vehicleCount[0]?.Gasoline,
    vehicleCount[0]?.Diesel,
    vehicleCount[0]?.CNG,
    vehicleCount[0]?.Electric,
    vehicleCount[0]?.Hybrid
  );

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Chart
        type="donut"
        series={chartData.map(val => val || 0)}
        options={{
          chart: {
            width: "100%",
          },
          colors: ["#02B3FF", "#FF7723", "#EF4444", "#32CD32", "#ED589D"],
          labels: ["Gasoline", "Diesel", "CNG", "Electric", "Hybrid"],
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,

                  total: {
                    show: true,
                    showAlways: true,
                    fontSize: "15px",
                  },
                  value: {
                    offsetY: -3,
                    fontSize: "20px",
                  },
                },
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default DonutChart;
