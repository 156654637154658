export const modesDaysList = [
  {
    label: "1",
    value: "1",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "2",
    value: "2",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "3",
    value: "3",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "4",
    value: "4",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "5",
    value: "5",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "6",
    value: "6",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "7",
    value: "7",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "8",
    value: "8",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "9",
    value: "9",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "10",
    value: "10",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "11",
    value: "11",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "12",
    value: "12",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "13",
    value: "13",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "14",
    value: "14",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "15",
    value: "15",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "16",
    value: "16",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "17",
    value: "17",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "18",
    value: "18",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "19",
    value: "19",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "20",
    value: "20",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "21",
    value: "21",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "22",
    value: "22",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "23",
    value: "23",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "24",
    value: "24",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "25",
    value: "25",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "26",
    value: "26",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "27",
    value: "27",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "28",
    value: "28",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "29",
    value: "29",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "30",
    value: "30",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
  {
    label: "31",
    value: "31",
    bus: "",
    bicycle: "",
    car: "",
    rideShare: "",
    electricScooter: "",
    walk: "",
    train: "",
  },
];
