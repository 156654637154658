import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { themePalette } from "../../../constants/Theme/colors";
import ROUTES_PATH from "../../../constants/routes";
import ListItemIcon from "@mui/material/ListItemIcon";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import { mainSidebarItems } from "../../../constants/staticData/NavbarItems";
import { useNavigate } from "react-router";
import CommonDialog from "../../../common/commonDialog/CommonDialog";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { useStyles } from "./style";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authActions } from "../../../redux/slice/auth-slice";
import { useLocation } from "react-router";

const StyledListItem = withStyles(theme => ({
  root: {
    backgroundColor: "tranparent",
    marginBottom: "8px",
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
}))(ListItemButton);

const LeftSidebar = ({ isMobile }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tab = useSelector(state => state?.auth?.defaultTab);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const onSignoutClick = () => {
    handleClickClose();
    localStorage.clear();
    dispatch(authActions.resetLoggedInUser());
    navigate(ROUTES_PATH.LOG_IN);
  };

  const location = useLocation();

  return (
    <Box sx={{ height: "100vh", backgroundColor: "background.main" }}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        sx={{ height: "100%", width: 1 }}
        subheader={
          <ListSubheader
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            component="div"
            id="nested-list-subheader"
          >
            <Typography
              variant="heading3_large"
              sx={{
                color: themePalette.palette.primary.main,
              }}
            >
              TravelRite
            </Typography>
          </ListSubheader>
        }
      >
        <Divider sx={{ borderColor: themePalette.palette.secondary.main }} />
        <Box
          sx={{
            minHeight: "88vh",
            display: "flex",
            pt: 5,
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {mainSidebarItems?.map((item, index) => {
              return item.label !== "Resources" &&
                item.label !== "Notification Settings" ? (
                <StyledListItem
                  selected={
                    item?.label.toLocaleLowerCase() ===
                    location.pathname.split("/")[2]
                  }
                  onClick={() => {
                    navigate(`${item.path}`);
                  }}
                >
                  <ListItemIcon
                    sx={
                      item?.label.toLocaleLowerCase() ===
                      location.pathname.split("/")[2]
                        ? {
                            minWidth: "38px",
                            color: themePalette.palette.primary.main,
                          }
                        : {
                            minWidth: "38px",
                            color: themePalette.palette.common.notificationTime,
                          }
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={
                      item?.label.toLocaleLowerCase() ===
                      location.pathname.split("/")[2]
                        ? {
                            minWidth: "38px",
                            color: themePalette.palette.primary.main,
                          }
                        : {
                            minWidth: "38px",
                            color: themePalette.palette.common.notificationTime,
                          }
                    }
                  >
                    <Typography
                      sx={{
                        minWidth: "38px",
                      }}
                      variant="heading_regular"
                    >
                      {item.label}
                    </Typography>
                  </ListItemText>
                </StyledListItem>
              ) : (
                <Tooltip
                  title={
                    <Typography
                      fontSize={15}
                      fontFamily={"'ArchivoMedium', sans-serif"}
                    >
                      Coming Soon
                    </Typography>
                  }
                  key={item.id}
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Box sx={{ mb: 1 }}>
                    <ListItemButton
                      sx={{
                        opacity: "0.85 !important",
                      }}
                      disabled
                    >
                      <ListItemIcon
                        sx={
                          tab === item.label
                            ? {
                                color: themePalette.palette.primary.main,
                                minWidth: "38px",
                              }
                            : {
                                color: "common.notificationTime",
                                minWidth: "38px",
                              }
                        }
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          sx={
                            tab === item.label
                              ? {
                                  color: themePalette.palette.primary.main,
                                  minWidth: "38px",
                                }
                              : {
                                  color: "common.notificationTime",
                                  minWidth: "38px",
                                }
                          }
                          variant="heading_regular"
                        >
                          {item.label}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </Box>
                </Tooltip>
              );
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <ListItemButton
              key="7"
              onClick={() => {
                navigate("/admin/changePassword");
              }}
            >
              <ListItemIcon
                sx={
                  location.pathname === "/admin/changePassword"
                    ? {
                        color: themePalette.palette.primary.main,
                        minWidth: "38px",
                      }
                    : { color: "common.notificationTime", minWidth: "38px" }
                }
              >
                <LockIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={
                    location.pathname === "/admin/changePassword"
                      ? {
                          color: themePalette.palette.primary.main,
                          minWidth: "38px",
                        }
                      : { color: "common.notificationTime", minWidth: "38px" }
                  }
                  variant="heading_regular"
                >
                  Change Password
                </Typography>
              </ListItemText>
            </ListItemButton>

            <ListItemButton onClick={handleClickOpen}>
              <ListItemIcon sx={{ minWidth: "38px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{ color: "common.notificationTime" }}
                  variant="heading_regular"
                >
                  Logout
                </Typography>
              </ListItemText>
            </ListItemButton>

            <CommonDialog
              open={open}
              title={"Logout?"}
              subtext={"Are you sure you want to logout?"}
              iconSpacing={"24px"}
              dialogSize={"xs"}
              dialogIcon={
                <LoginOutlinedIcon
                  sx={{
                    fontSize: 28,
                    color: theme => theme.palette.primary.main,
                    opacity: 0.8,
                  }}
                />
              }
              dialogAction={
                <React.Fragment>
                  <Button
                    variant="outlined"
                    sx={{
                      border: `1px solid ${themePalette.palette.primary.main}`,
                      background: theme => theme.palette.common.white,
                      fontSize: 18,
                      py: 3,
                      mr: 3,
                    }}
                    onClick={handleClickClose}
                  >
                    No
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ fontSize: 18, py: 3 }}
                    onClick={onSignoutClick}
                  >
                    Yes
                  </Button>
                </React.Fragment>
              }
            />
          </Box>
        </Box>
      </List>
    </Box>
  );
};

export default LeftSidebar;
