import { Skeleton, TableCell, TableRow, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.tableEvenRow,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const SkeletonTable = ({ user }) => {
  const skeletonArray = Array(10).fill("");

  return skeletonArray.map((item, index) => (
    <StyledTableRow key={index}>
      <StyledTableCell component="th" scope="row">
        <Skeleton animation="wave" />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Skeleton animation="wave" />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Skeleton animation="wave" />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Skeleton animation="wave" />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Skeleton animation="wave" />
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        <Skeleton animation="wave" />
      </StyledTableCell>
      {user === "blocked" && (
        <StyledTableCell component="th" scope="row">
          <Skeleton animation="wave" />
        </StyledTableCell>
      )}
    </StyledTableRow>
  ));
};
