import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { authActions } from "../../../../redux/slice/auth-slice";
import ROUTES_PATH from "../../../../constants/routes";
import { Typography, Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CenterFlexBox } from "../../../../common/StyledComponents/Flexbox";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { themePalette } from "../../../../constants/Theme/colors";
import treesSaved from "../../../../assets/gif/savedTrees.gif";
import services from "../../../../services";
import TotalUsersAndTreesSkeleton from "../skeletons/TotalUsersSkeleton";
import { PropTypes } from "prop-types";
// import TotalUsersAndTreesSkeleton from "./skeletons/TotalUsersSkeleton";

/**
 * Here are two cards showing us the total number of Users as well as the Trees saved.
 */

const TotalUsersAndTrees = ({
  borderColor,
  borderWidth,
  borderRadius,
  backgroundColor,
  titleFontColor,
  bodyFontColor,
  avatarBoxColor,
  iconColor,
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [totalTreesUsers, setTotalTreesUsers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getTotalUsersTrees = () => {
    setIsLoading(true);
    services
      .getTotalUsersAndTrees()
      .then(res => {
        if (res?.status === 200) {
          setTotalTreesUsers(res?.data?.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        //console.log(error);
      });
  };

  useEffect(() => {
    getTotalUsersTrees();
  }, []);

  return (
    <Grid container sx={{ width: 1, height: "100%" }}>
      <Grid item xs={12} sx={{ height: "50%", pb: 1 }}>
        {isLoading ? (
          <TotalUsersAndTreesSkeleton />
        ) : (
          <CenterFlexBox
            sx={{
              // backgroundColor: "background.main",
              backgroundColor: `${backgroundColor}`,
              border: `${borderWidth} solid ${borderColor}`,
              justifyContent: "flex-start",
              height: "100%",
              width: 1,
              borderRadius: `${borderRadius}`,
            }}
          >
            <CenterFlexBox
              sx={{
                // backgroundColor: "background.avatar",
                backgroundColor: `${avatarBoxColor}`,
                ml: 3,
                p: 2,
                borderRadius: "100%",
              }}
            >
              <GroupOutlinedIcon
                sx={{
                  fontSize: 26,
                  // color: themePalette.palette.primary.main
                  color: `${iconColor}`,
                }}
              />
            </CenterFlexBox>
            <CenterFlexBox
              sx={{
                mx: 3,
                my: 2.1,
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="heading_semiBold"
                sx={{ color: `${titleFontColor}` }}
              >
                {totalTreesUsers?.totalUser || 0}
              </Typography>
              <Typography
                variant="heading_medium"
                sx={{
                  // color: "text.secondary"
                  color: `${bodyFontColor}`,
                }}
              >
                Total Users
              </Typography>
            </CenterFlexBox>
          </CenterFlexBox>
        )}
      </Grid>
      <Grid item xs={12} sx={{ height: "50%", pt: 1 }}>
        {isLoading ? (
          <TotalUsersAndTreesSkeleton />
        ) : (
          <CenterFlexBox
            sx={{
              // backgroundColor: "background.main",
              backgroundColor: `${backgroundColor}`,
              border: `${borderWidth} solid ${borderColor}`,
              borderRadius: `${borderRadius}`,
              justifyContent: "flex-start",
              height: "100%",
              width: 1,
            }}
          >
            <CenterFlexBox
              sx={{
                ml: 3,
              }}
            >
              <Box
                component="img"
                src={treesSaved}
                alt="savedTress"
                width="58px"
                height="58px"
                sx={{ borderRadius: "100%" }}
              />
            </CenterFlexBox>
            <CenterFlexBox
              sx={{
                ml: 3,
                my: 2.1,
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="heading_semiBold"
                sx={{ color: `${titleFontColor}` }}
              >
                {Math.floor(totalTreesUsers?.savedTrees) || 0}
              </Typography>
              <Typography
                variant="heading_medium"
                sx={{ color: `${bodyFontColor}` }}
              >
                Trees Saved
              </Typography>
            </CenterFlexBox>
          </CenterFlexBox>
        )}
      </Grid>
    </Grid>
  );
};

TotalUsersAndTrees.propTypes = {
  /**
   * change the border radius of the component
   */
  borderRadius: PropTypes.string,
  /**
   * change the border color of the component
   */
  borderColor: PropTypes.string,
  /**
   * change the border width of the component
   */
  borderWidth: PropTypes.string,
  /**
   * change the background color of the component
   */
  backgroundColor: PropTypes.string,
  /**
   * change the title font color
   */
  titleFontColor: PropTypes.string,
  /**
   * change the color of body fonts
   */
  bodyFontColor: PropTypes.string,
  /**
   * change the color of body fonts
   */
  avatarBoxColor: PropTypes.string,
  /**
   * change the color of body fonts
   */
  iconColor: PropTypes.string,
};

TotalUsersAndTrees.defaultProps = {
  borderRadius: "0px",
  borderColor: "transparent",
  borderWidth: "0px",
  backgroundColor: "background.main",
  titleFontColor: "#000",
  bodyFontColor: "text.secondary",
  iconColor: `${themePalette.palette.primary.main}`,
  avatarBoxColor: "background.avatar",
};

export default TotalUsersAndTrees;
