import * as React from "react";
import theme from "../../constants/Theme";
import zendesklogo from "./assets/zendesklogo.png";
import virtualassistant from "./assets/virtualassistant.png";
import { Box, Typography, Grid, Stack, Button } from "@mui/material";
import { PasswordFieldWrapper } from "../ChangePassword/styles";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const Support = () => {
  return (
    <DashboardLayout>
      <Box>
        <Stack sx={styles.centeredContent}>
          <Stack
            pt={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 3 }}
            px={2}
            sx={{
              width: 1,
              maxWidth: theme.breakpoints.values.lg,
            }}
          >
            <Box>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      backgroundColor: theme =>
                        theme.palette.common.tabTableBackground,
                      px: { xl: 12, lg: 12, md: 6, sm: 6, xs: 4 },
                      pt: { xl: 12, lg: 12, md: 8, sm: 6, xs: 4 },
                      pb: { xl: 5, lg: 5, md: 4, sm: 3, xs: 3 },
                    }}
                  >
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box
                          sx={{
                            border: `1px solid ${theme.palette.text.greenValue}`,
                            py: { xl: 3, lg: 3, md: 3, sm: 2, xs: 2 },
                            pl: { xl: 4, lg: 4, md: 4, sm: 3, xs: 2 },
                            pr: { xl: 2, lg: 2, md: 4, sm: 3, xs: 2 },
                            width: 1,
                            borderRadius: "4px",
                          }}
                        >
                          <Grid container>
                            <Grid item xl={7} lg={7} md={8} sm={12} xs={12}>
                              <Box
                                component="img"
                                sx={{ width: "200px" }}
                                alt="Zendesk Logo"
                                src={zendesklogo}
                              />
                              <Typography
                                variant="subtitle"
                                component="div"
                                sx={{
                                  color: theme =>
                                    theme.palette.text.darkSecondary,
                                  mt: 1,
                                }}
                              >
                                All the queries will be viewed in Zendesk
                              </Typography>
                            </Grid>
                            <Grid item xl={1} lg={1}></Grid>
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={10}>
                              <PasswordFieldWrapper>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: "120px",
                                    mx: { xl: 1, lg: 1, md: 1 },
                                  }}
                                >
                                  <Button
                                    target="_blank"
                                    variant="contained"
                                    color="primary"
                                    href="https://www.zendesk.com/in/login/#login"
                                    sx={{
                                      ml: 1,
                                      width: { xl: "190px", lg: "190px" },
                                      py: "29px",
                                    }}
                                  >
                                    Redirect to Zendesk
                                  </Button>
                                </Box>
                              </PasswordFieldWrapper>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box
                          sx={{
                            backgroundImage: `url(${virtualassistant})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            height: "400px",
                            mt: { xl: 8, lg: 7, md: 7, sm: 5, xs: 0 },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </DashboardLayout>
  );
};

export default Support;
