import React from "react";
import PropTypes from "prop-types";
import theme from "../../constants/Theme";
import { themePalette } from "../../constants/Theme/colors";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  AvatarGroup,
  Divider,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  DialogContentText,
  ListItemButton,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  CommunityWrapper,
  DeleteTextWrapper,
  LikedPersonTextWrapper,
} from "../../pages/Users/Profile/styles";
import { likedPeople } from "../../constants/likedPeople/people";
import { AddFAQWrapper } from "../../pages/FAQ/styles";
import UserProfileDialog from "../../common/userProfileDialog/UserProfileDialog";
import userOne from "../../pages/Users/assets/likedUsers/userOne.svg";
import userTwo from "../../pages/Users/assets/likedUsers/userTwo.svg";
import userThree from "../../pages/Users/assets/likedUsers/userThree.svg";
import userFour from "../../pages/Users/assets/likedUsers/userFour.svg";
import userFive from "../../pages/Users/assets/likedUsers/userFive.svg";
import { allPosts } from "../../constants/profilePosts/posts";

/**
  Documentation and Settings for various posts made by community of users
  consisting of User Image, Full Name, Days ago, Post content and image,
  Total no of users who liked and commented on post. 
*/

const CommunityPosts = ({
  backgroundColor,
  borderRadius,
  heading,
  dividerColor,
  deleteOptionText,
  deleteIconColor,
  deleteModalSize,
  deleteModalTitle,
  deleteModalHeading,
  deleteModalInputLabel,
  deletePostButtonText,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openLikedDialog, setOpenLikedDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleLikedDialog = () => {
    setOpenLikedDialog(true);
  };

  const closeLikedDialog = () => {
    setOpenLikedDialog(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        mt: 3,
        borderRadius: `${borderRadius}px`,
        height: "97%",
      }}
    >
      <Box
        sx={{
          mb: 1,
          px: 3,
          pt: "12px",
        }}
      >
        <Typography variant="titleMedium">{heading}</Typography>
      </Box>
      <Divider
        sx={{
          border: `1px solid ${dividerColor}`,
          opacity: "0.7",
        }}
      />

      <Grid
        container
        //sx={{
        //  maxHeight: "721px",
        //  overflowY: "auto",
        //}}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 1,
            height: "700px",
            textAlign: "center",

            [theme.breakpoints.down("md")]: {
              height: "300px",
            },
          }}
        >
          <Typography
            variant="heading_regular"
            sx={{ color: themePalette.palette.text.secondary }}
          >
            {"Coming soon!"}
          </Typography>
        </Box>

        {/*{allPosts.map(post => (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container>
              <Grid item xl={7} lg={7} md={7} sm={6} xs={6}>
                <CommunityWrapper>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="User Icon" src={post.postAvatar} />
                    </ListItemAvatar>

                    <ListItemText
                      primary={post.userName}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              mt: 1,
                            }}
                            component="span"
                            variant="body2"
                          >
                            {post.postTime}
                            {post.postTime > 1 ? " days ago" : " day ago"}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </CommunityWrapper>
              </Grid>
              <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
                <DeleteTextWrapper>
                  <Box sx={{ float: "right" }}>
                    <ListItem
                      sx={{ pr: 4, height: "75px", cursor: "pointer" }}
                      onClick={handleClickOpen}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "unset",
                          color: deleteIconColor,
                        }}
                      >
                        <DeleteOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={deleteOptionText} sx={{ ml: 1 }} />
                    </ListItem>
                  </Box>
                </DeleteTextWrapper>
              </Grid>
            </Grid>

            <UserProfileDialog
              open={open}
              title={deleteModalTitle}
              dialogSize={deleteModalSize}
              dialogContent={
                <React.Fragment>
                  <DialogContentText sx={{ pr: 4 }}>
                    <Typography
                      variant="body_xlarge"
                      sx={{
                        color: theme => theme.palette.text.darkSecondary,
                      }}
                    >
                      {deleteModalHeading}
                    </Typography>
                  </DialogContentText>
                  <FormControl sx={{ width: 1, mt: 1 }}>
                    <Typography
                      variant="heading_regular"
                      sx={{
                        mb: "5px",
                        mt: 2,
                        color: theme => theme.palette.text.darkSecondary,
                      }}
                    >
                      {deleteModalInputLabel}
                    </Typography>

                    <TextField
                      variant="outlined"
                      type="text"
                      name="deleteAnswer"
                      multiline
                      rows={7}
                      maxRows={7}
                      sx={{ mb: 6 }}
                    />

                    <AddFAQWrapper>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          py: "26px",
                          mt: 1,
                          mb: 3,
                          float: "right",
                          width: "170px",
                          fontSize: "17px !important",
                        }}
                      >
                        {deletePostButtonText}
                      </Button>
                    </AddFAQWrapper>
                  </FormControl>
                </React.Fragment>
              }
              close={handleClickClose}
            />

            <CommunityWrapper>
              <React.Fragment>
                <Box sx={{ pl: 3, pr: 3, pb: 1 }}>
                  <Typography
                    sx={{
                      display: "inline",
                      mt: 1,
                      fontSize: "19px !important",
                    }}
                    component="span"
                    variant="body2"
                  >
                    {post.postText}
                  </Typography>

                  <Box
                    component="img"
                    alt="Community Image"
                    sx={{ mt: 2, width: 1 }}
                    src={post.postImage}
                  />

                  <AvatarGroup
                    total={11}
                    max={6}
                    sx={{
                      mb: 2,
                      display: "inline-flex",
                      cursor: "pointer",
                    }}
                    className="userGroup"
                    onClick={handleLikedDialog}
                  >
                    <Avatar
                      className="likedUsers"
                      alt="Remy Sharp"
                      src={userOne}
                    />
                    <Avatar
                      className="likedUsers"
                      alt="Travis Howard"
                      src={userTwo}
                    />
                    <Avatar
                      className="likedUsers"
                      alt="Cindy Baker"
                      src={userThree}
                    />
                    <Avatar
                      className="likedUsers"
                      alt="Agnes Walker"
                      src={userFour}
                    />
                    <Avatar
                      className="likedUsers"
                      alt="Trevor Henderson"
                      src={userFive}
                    />
                  </AvatarGroup>

                  <Typography
                    variant="body2"
                    sx={{ display: "inline", mt: 1, float: "right" }}
                  >
                    {post.commentCount}
                    {post.commentCount > 1 ? " Comments" : " Comment"}
                  </Typography>
                </Box>
              </React.Fragment>
            </CommunityWrapper>

            <UserProfileDialog
              open={openLikedDialog}
              title={"People who liked"}
              dialogSize={"sm"}
              dialogContent={
                <React.Fragment>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <List
                        dense
                        sx={{
                          mt: 1,
                        }}
                      >
                        {likedPeople.map(person => (
                          <ListItem
                            key={person.userId}
                            secondaryAction={
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                  width: "140px",
                                  float: "right",
                                  mt: 1,
                                }}
                              >
                                View Profile
                              </Button>
                            }
                            disablePadding
                            sx={{ mb: 4 }}
                          >
                            <LikedPersonTextWrapper>
                              <ListItemButton sx={{ px: 0 }}>
                                <ListItemAvatar>
                                  <Avatar
                                    alt="Liked Person"
                                    src={person.userAvatar}
                                  />
                                </ListItemAvatar>

                                <ListItemText primary={person.userName} />
                              </ListItemButton>
                            </LikedPersonTextWrapper>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              close={closeLikedDialog}
            />

            <Divider
              sx={{
                border: `1px solid ${dividerColor}`,
                opacity: "0.7",
              }}
            />
          </Grid>
        ))}*/}
      </Grid>
    </Box>
  );
};

CommunityPosts.defaultProps = {
  backgroundColor: theme => theme.palette.common.tabTableBackground,
  borderRadius: 5,
  heading: "Community",
  dividerColor: themePalette.palette.secondary.main,
  deleteOptionText: "Delete",
  deleteIconColor: theme => theme.palette.button.secondary,
  deleteModalSize: "sm",
  deleteModalTitle: "Delete Post",
  deleteModalHeading:
    "Mention the reason for which you want to delete the post?",
  deleteModalInputLabel: "Reason",
  deletePostButtonText: "Delete Post",
};

CommunityPosts.propTypes = {
  /**
  Change background color of posts component
  */
  backgroundColor: PropTypes.string.isRequired,

  /**
  Change border radius of posts component
  */
  borderRadius: PropTypes.number.isRequired,

  /**
  Change heading of Component
  */
  heading: PropTypes.string.isRequired,

  /**
  Change color of divider
  */
  dividerColor: PropTypes.string.isRequired,

  /**
  Change text of delete option
  */
  deleteOptionText: PropTypes.string.isRequired,

  /**
  Change color of delete icon
  */
  deleteIconColor: PropTypes.string.isRequired,

  /**
  Change size of delete modal
  */
  deleteModalSize: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),

  /**
  Change title of delete modal
  */
  deleteModalTitle: PropTypes.string.isRequired,

  /**
  Change heading of delete modal
  */
  deleteModalHeading: PropTypes.string.isRequired,

  /**
  Change input label of delete modal
  */
  deleteModalInputLabel: PropTypes.string.isRequired,

  /**
  Change button text of delete modal
  */
  deletePostButtonText: PropTypes.string.isRequired,
};

export default CommunityPosts;
