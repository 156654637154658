import styled from "@emotion/styled";
import { themePalette } from "../../constants/Theme/colors";

export const GridWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiGrid-root.MuiGrid-item": {
      paddingLeft: 0,
      paddingTop: 0,
    },

    "& .MuiTypography-body1": {
      "@media (max-width:1150px)": {
        fontSize: "14px !important",
      },
    },

    "& .MuiTab-root.Mui-selected": {
      color: themePalette.palette.primary.main,
    },

    "& .MuiTableCell-root": {
      borderBottomColor: themePalette.palette.secondary.main,
    },
  };
});

export const BoxWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiBox-root": {
      paddingRight: "0 !important",
    },
  };
});

export const DropdownWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiSvgIcon-root": {
      color: themePalette.palette.primary.main,
      fontSize: 26,
      top: "calc(50% - 0.55em)",
    },

    "& .MuiOutlinedInput-input": {
      fontFamily: '"ArchivoMedium", "sans-serif" !important',
    },
  };
});

export const SearchFieldWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root fieldset": {
      border: "unset",
    },

    "& .MuiTextField-root": {
      fontFamily: "'AsapRegular', sans-serif",
      background: themePalette.palette.background.tertiary,
      paddingTop: "3px",
      paddingBottom: "3px",
      borderRadius: "10px",
    },

    "& .MuiOutlinedInput-root": {
      fontFamily: "'AsapRegular', sans-serif",
    },

    "& .MuiSvgIcon-root": {
      color: themePalette.palette.primary.main,
    },

    "& .MuiInputBase-input": {
      "&::placeholder": {
        fontSize: 15,
      },
    },
  };
});

export const ModalBlockButton = styled.div(({ theme }) => {
  return {
    "& .MuiButton-contained": {
      backgroundColor: themePalette.palette.primary.main,
      borderRadius: "4px",
      textTransform: "capitalize",
      fontSize: 16,

      "&:hover": {
        backgroundColor: themePalette.palette.primary.main,
      },
    },
  };
});

export const UserEmail = styled.div(({ theme }) => {
  return {
    display: "block",
    maxWidth: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [theme.breakpoints.down("xl")]: {
      maxWidth: "135px",
    },
  };
});
