import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeInputs = createTheme(themePalette, {
  components: {
    MuiOutlinedInput: {
      defaultProps: {
        fullWidth: true,
        variant: "outlined",
        //Chromes autofill css not getting overriden we can change bg-color but cant set spacing and zindex so making autocomplete off
        autoComplete: "off",
      },
      
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            fontFamily: "'AsapMedium', sans-serif",

            "::placeholder": {
              fontFamily: "'AsapRegular', sans-serif",
            },
          },
          
        },
      },
    },
    MuiInputBase:{
      styleOverrides:{
        input:{
          fontFamily:"'AsapRegular', sans-serif",
          
        }
      }
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "'ArchivoMedium', sans-serif",
          color: themePalette.palette.error.main,
          fontSize: "0.8rem",
          margin: "3px",
        },
      },
    },
  },
});
