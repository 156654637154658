import React from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import theme from "../../constants/Theme";
import { allTerms } from "../../constants/Terms&Services/terms";

const TermsServices = () => {
  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.tertiary,
        px: { xl: 5, lg: 5, md: 5, sm: 4, xs: 3 },
        py: { lg: 3, md: 4, sm: 4, xs: 4 },
      }}
    >
      <Stack
        sx={{
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 1,
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            width: 1,
            [theme.breakpoints.up("xl")]: {
              width: "1300px",
            },
          }}
        >
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="label_xlarge"
                sx={{
                  mb: 1,
                }}
              >
                Terms of Service
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  mt: { xl: 3, lg: 3, md: 3, sm: 3, xs: 1 },
                  mb: 0,
                  borderRadius: "5px",
                  width: 1,
                }}
              >
                <Box>
                  <List>
                    {allTerms.map(item => (
                      <React.Fragment>
                        <ListItem alignItems="flex-start" sx={{ px: 0 }}>
                          <ListItemText
                            primary={item.termId + ". " + item.termText}
                          />
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default TermsServices;
