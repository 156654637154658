import React from "react";
import { Box } from "@mui/system";
import { Skeleton, Typography, Avatar } from "@mui/material";

const TopPerformersSkeleton = () => {
  const performersSkeleton = [1, 2, 3, 4, 5];
  return (
    <Box sx={{ width: 1, height: "100%", px: 2 }}>
      <Skeleton animation="wave" width="100%" sx={{ mb: 2.1 }}>
        <Typography variant="heading3">.</Typography>
      </Skeleton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {performersSkeleton.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 1.5,
            }}
          >
            <Skeleton variant="circular" animation="wave">
              <Avatar sx={{ width: 24, height: 24 }} />
            </Skeleton>
            <Skeleton
              animation="wave"
              sx={{
                width: {
                  xs: "90%",
                  sm: "90%",
                  md: "85%",
                  lg: "85%",
                  xl: "85%",
                },
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TopPerformersSkeleton;
// height="16px" width="85%"
