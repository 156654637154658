import styled from "@emotion/styled";
import { themePalette } from "../../constants/Theme/colors";

export const ModalContentWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiDialogTitle-root": {
      fontFamily: '"ArchivoMedium", "sans-serif"',
      fontSize: 23,
      paddingTop: "12px",
    },

    "& .MuiDialogContentText-root": {
      fontFamily: '"AsapMedium", "sans-serif"',
      fontSize: 18,
      color: themePalette.palette.text.secondary,
    },

    "& .MuiButton-contained": {
      background: themePalette.palette.primary.main,
      textTransform: "capitalize",

      "&:hover": {
        background: themePalette.palette.primary.main,
      },
    },
  };
});
