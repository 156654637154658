import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
export const mainSidebarItems = [
  {
    id: 1,
    icon: <DashboardOutlinedIcon />,
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    id: 2,
    icon: <GroupOutlinedIcon />,
    label: "Users",
    path: "/admin/users",
  },
  {
    id: 3,
    icon: <TopicOutlinedIcon />,
    label: "Resources",
    path: "/admin/resources",
  },
  {
    id: 4,
    icon: <SupportAgentOutlinedIcon />,
    label: "Support",
    path: "/admin/support",
  },
  {
    id: 5,
    icon: <QuestionAnswerOutlinedIcon />,
    label: "FAQ",
    path: "/admin/faq",
  },
  {
    id: 6,
    icon: <NotificationsActiveOutlinedIcon />,
    label: "Notification Settings",
    path: "/admin/notification",
  },
];
