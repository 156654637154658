import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: [],
  isLoggedIn: false,
  token: "",
  defaultTab: "Dashboard",
  startDate: "",
  endDate: "",
  tokenValid: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggedInUser(state, action) {
      const userInfo = action.payload;

      state.isLoggedIn = true;
      state.token = userInfo?.token;
      state.userData.push({
        email: userInfo?.emailAddress,
        fullName: userInfo?.fullName,
      });
    },

    selectedTab(state, action) {
      state.defaultTab = action.payload;
    },

    weekRangeDate(state, action) {
      const dateValues = action.payload;
      state.startDate = dateValues?.begin;
      state.endDate = dateValues?.end;
    },

    validToken(state, action) {
      const tokenVal = action.payload;
      state.tokenValid = tokenVal?.currToken;
    },

    resetLoggedInUser: () => initialState,
  },
});

export const authActions = authSlice.actions;
export default authSlice;
