import React from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Button,
  TextField,
  Container,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CommonDialog from "../../common/commonDialog/CommonDialog";
import { messages } from "../../constants/message/messages";
import { themePalette } from "../../constants/Theme/colors";
import { PasswordFieldWrapper } from "../ChangePassword/styles";
import login_poster from "../../assets/images/City-driver-pana.svg";
import { isValidEmail } from "../../utils/inputvalidation";
import services from "../../services";

const styles = {
  centeredContent: {
    minHeight: "100vh",
    display: "grid",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    flexFlow: "row wrap",
    width: 1,
    webkitBoxPack: "center",
    justifyContent: "center",
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ForgotPasswordPage = () => {
  const [loader, setLoader] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
    error: true,
    isEmailError: false,
    emailErrorMsg: "",
    role: "",
  });

  const [open, setOpen] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setValid(false);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoader(true);
      let { email } = values;
      let params = {
        email: email.trim(),
        role: "admin",
      };

      services
        .forgotPassword(params)
        .then(res => {
          if (res.data.statusCode === 200) {
            setLoader(false);
            setOpen(true);
            setValues({
              email: "",
            });
          } else {
            setLoader(false);
            setValid(true);
          }
        })
        .catch(error => console.log(error));
    }
  };

  const validate = () => {
    let { email } = values;
    if (email.trim() == "") {
      handleChangeValue("isEmailError", true);
      handleChangeValue("emailErrorMsg", messages.emailRequired);
      return false;
    } else if (!isValidEmail(email)) {
      handleChangeValue("isEmailError", true);
      handleChangeValue("emailErrorMsg", messages.validEmailRequired);
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: theme => theme.palette.common.white,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          display={{
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            xs: "none",
          }}
        >
          <Box
            sx={{
              background: themePalette.palette.secondary.main,
              minHeight: "100vh",
              display: "grid",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              flexFlow: "row wrap",
              width: 1,
              webkitBoxPack: "center",
              justifyContent: "center",
            }}
          >
            <Container maxWidth="sm">
              <Box
                sx={{
                  px: { xl: 6, lg: 6, md: 6 },
                  pt: { xl: 7, lg: 7, md: 5 },
                  pb: { xl: 8, lg: 8, md: 5 },
                }}
              >
                <Typography
                  sx={{
                    color: themePalette.palette.primary.main,
                  }}
                  variant="heading1_xlarge"
                >
                  TravelRite
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "16px",
                    backgroundColor: "background.main",
                    display: "block",
                    m: "0 auto",
                    width: "63%",
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${login_poster})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "340px",
                    }}
                  ></Box>
                </Box>

                <Box sx={{ mt: 8 }}>
                  <Typography
                    variant="heading2"
                    sx={{
                      color: "text.main",
                    }}
                  >
                    Track Users, Emissions etc.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "block",
                    m: "0 auto",
                    width: "80%",
                    marginTop: 2,
                    marginBottom: 7,
                  }}
                >
                  <Typography
                    variant="body_large"
                    sx={{
                      color: themePalette.palette.text.secondary,
                    }}
                  >
                    Lorem ipsum dolor sit amet. In lobortis ex id ante maximus,
                    quis varius elit suscipit.
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          xs={12}
          sx={{
            backgroundColor: themePalette.palette.background.tertiary,
            minHeight: "100vh",
            display: "grid",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            flexFlow: "column wrap",
            width: 1,
            webkitBoxPack: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: themePalette.palette.primary.main,
              my: 3,
              display: { xs: "block", md: "none" },
            }}
            variant="heading1_xlarge"
          >
            TravelRite
          </Typography>
          <Container maxWidth="sm" sx={{ mt: { xs: 3, md: 0 } }}>
            <Box
              sx={{
                pb: { xl: 0, lg: 0, md: 0, sm: 6, xs: 5 },
                pl: { xl: 9, lg: 9, md: 7, sm: 0, xs: 2 },
                pr: { xl: 13, lg: 13, md: 7, sm: 0, xs: 2 },
                mt: { xs: 2, md: 0 },
              }}
            >
              <Typography
                sx={{ mb: 2 }}
                variant="heading3_large"
                component="div"
                color="text.main"
              >
                Forgot Password
              </Typography>
              <Typography
                sx={{ mb: 5, color: themePalette.palette.common.subtextColor }}
                variant="body_regular"
                component="div"
              >
                Enter the registered email address, later you will sent an
                email.
              </Typography>

              <form onSubmit={handleSubmit}>
                <FormControl variant="standard" sx={{ width: 1 }}>
                  <PasswordFieldWrapper>
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        color="text.darkSecondary"
                        variant="heading_regular"
                      >
                        Email Address
                      </Typography>
                    </Box>
                    <TextField
                      variant="outlined"
                      placeholder="Enter email address here"
                      sx={{ mb: 5 }}
                      type="email"
                      value={values.email}
                      fullWidth
                      onFocus={() => {
                        handleChangeValue("isEmailError", false);
                        handleChangeValue("emailErrorMsg", "");
                      }}
                      onChange={handleChange("email")}
                      error={values.isEmailError}
                      helperText={values.emailErrorMsg}
                    />
                  </PasswordFieldWrapper>

                  <Button type="submit" variant="contained">
                    Send Link
                  </Button>
                </FormControl>
              </form>

              <CommonDialog
                open={open}
                title={"Email Sent Successfully!"}
                subtext={
                  "We have sent the email to your registered email id for the verification."
                }
                dialogSize={"xs"}
                iconSpacing={"5px"}
                dialogAction={
                  <React.Fragment>
                    <Button
                      variant="contained"
                      onClick={handleClickClose}
                      sx={{ fontSize: 19, py: "25px" }}
                    >
                      Close
                    </Button>
                  </React.Fragment>
                }
              />
            </Box>
          </Container>

          <Snackbar
            open={valid}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {messages.validEmailRequired}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPasswordPage;
