import {
  Skeleton,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
} from "@mui/material";
import theme from "../../../../constants/Theme";
import { ProfileInfoWrapper } from "../styles";
import { themePalette } from "../../../../constants/Theme/colors";

export const ProfileCardSkeleton = () => {
  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Box
        sx={{
          backgroundColor: themePalette.palette.common.tabTableBackground,
          px: { xl: 4, lg: 4, md: 4, sm: 4, xs: 0 },
          py: 12,
          mt: 3,
          borderRadius: "5px",
        }}
      >
        <Grid container>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Skeleton
              variant="circular"
              width={"125px"}
              height={"125px"}
              animation="wave"
              sx={{
                [theme.breakpoints.up("xs")]: {
                  display: "block",
                  m: "0 auto",
                  my: 1,
                },
              }}
            />
          </Grid>
          <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
            <List>
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        variant="circular"
                        height="36px"
                        width="36px"
                        animation="wave"
                      />
                    </ListItemAvatar>
                    <Skeleton animation="wave" width="100%" sx={{ ml: 1 }}>
                      <Typography variant="titleLarge">.</Typography>
                    </Skeleton>
                  </ListItem>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        variant="circular"
                        height="36px"
                        width="36px"
                        animation="wave"
                      />
                    </ListItemAvatar>
                    <Skeleton animation="wave" width="100%" sx={{ ml: 1 }}>
                      <Typography variant="titleLarge">.</Typography>
                    </Skeleton>
                  </ListItem>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        variant="circular"
                        height="36px"
                        width="36px"
                        animation="wave"
                      />
                    </ListItemAvatar>
                    <Skeleton animation="wave" width="100%" sx={{ ml: 1 }}>
                      <Typography variant="titleLarge">.</Typography>
                    </Skeleton>
                  </ListItem>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        variant="circular"
                        height="36px"
                        width="36px"
                        animation="wave"
                      />
                    </ListItemAvatar>
                    <Skeleton animation="wave" width="100%" sx={{ ml: 1 }}>
                      <Typography variant="titleLarge">.</Typography>
                    </Skeleton>
                  </ListItem>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        variant="circular"
                        height="36px"
                        width="36px"
                        animation="wave"
                      />
                    </ListItemAvatar>
                    <Skeleton animation="wave" width="100%" sx={{ ml: 1 }}>
                      <Typography variant="titleLarge">.</Typography>
                    </Skeleton>
                  </ListItem>
                </Grid>
              </Grid>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
