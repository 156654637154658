import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { graphType } from "../../../constants/staticData/graphData";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { themePalette } from "../../../constants/Theme/colors";
import CarbonOffestGraph from "./CarbonOffestGraph";
import UserAdminGraph from "./UserAdminGraph";
import ModeGraph from "./ModeGraph";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DropdownWrapper } from "../../Users/styles";
import BasicDateRangePicker from "./StaticDateRangePicker";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useSelector } from "react-redux";
import services from "../../../services";
import UserAndModeGraphSkeleton from "./skeletons/UserAndModeGraphSkeleton";

const UserAndMode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [weekLoading, setWeekLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const graphInterval = ["Weekly", "Monthly", "Yearly"];
  const [graph, setGraph] = useState("Co2 Saved");

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = item => {
    setAnchorEl(null);
    if (typeof item === "string") setGraph(item);
  };

  const [interval, setInterval] = useState("Weekly");
  const [value, setValue] = useState(dayjs());
  const [yearVal, setYearVal] = useState(new Date().getFullYear());
  const [monthVal, setMonthVal] = useState(new Date().getMonth());

  const [carbonOffsetData, setCarbonOffsetData] = useState([{}]);
  const [modesData, setModesData] = useState([{}]);

  const allData = [];
  const weekStartDate = useSelector(state => state?.auth?.startDate);
  const weekEndDate = useSelector(state => state?.auth?.endDate);

  const handleChange = event => {
    setInterval(event.target.value);
  };

  const onKeyDown = e => {
    e.preventDefault();
  };

  useEffect(() => {
    if (interval == "Weekly") {
      setWeekLoading(true);
    }
  }, [interval]);

  useEffect(() => {
    var params = {};

    if (graph == "Co2 Saved") {
      if (interval == "Yearly") {
        params = {
          adminChart: true,
          year: value["$y"],
        };
        setIsLoading(true);

        services
          .dashboardChart(params)
          .then(res => {
            res?.data?.data?.carbonOffsetAndPointDataOfYearOfAllUsers.map(
              item => {
                allData.push(item);
              }
            );
            setCarbonOffsetData(allData);
            setIsLoading(false);
          })
          .catch(e => {
            console.log(e);
          });
      } else if (interval == "Monthly") {
        params = {
          adminChart: true,
          month: value["$M"] + 1,
          year: value["$y"],
        };
        setMonthVal(value["$M"] + 1);
        setYearVal(value["$y"]);
        setIsLoading(true);

        services
          .dashboardChart(params)
          .then(res => {
            res?.data?.data?.carbonOffsetAndPointDataOfMonthOfAllUsers.map(
              item => {
                allData.push(item);
              }
            );
            setCarbonOffsetData(allData);
            setIsLoading(false);
          })
          .catch(e => {
            //console.log(e);
          });
      } else if (interval == "Weekly") {
        if (weekStartDate && weekEndDate) {
          params = {
            adminChart: true,
            weekStartDate: weekStartDate,
            weekEndDate: weekEndDate,
          };

          services
            .dashboardChart(params)
            .then(res => {
              if (res?.status === 200) {
                res?.data?.data?.carbonOffsetAndPointDataOfWeekOfAllUsers.map(
                  item => {
                    allData.push(item);
                  }
                );
                setWeekLoading(false);
                setCarbonOffsetData(allData);
              }
            })
            .catch(e => {
              console.log(e);
            });
        }
      }
    } else if (graph == "User") {
      if (interval == "Yearly") {
        params = {
          user: true,
          adminChart: true,
          year: value["$y"],
        };
        setIsLoading(true);

        services
          .dashboardChart(params)
          .then(res => {
            res?.data?.data?.userDataOfYear.map(item => {
              allData.push(item);
            });
            setCarbonOffsetData(allData);
            setIsLoading(false);
          })
          .catch(e => {
            console.log(e);
          });
      } else if (interval == "Monthly") {
        params = {
          user: true,
          adminChart: true,
          month: value["$M"] + 1,
          year: value["$y"],
        };

        setMonthVal(value["$M"] + 1);
        setYearVal(value["$y"]);
        setIsLoading(true);

        services
          .dashboardChart(params)
          .then(res => {
            res?.data?.data?.userDataOfMonth.map(item => {
              allData.push(item);
            });
            setCarbonOffsetData(allData);
            setIsLoading(false);
          })
          .catch(e => {
            console.log(e);
          });
      } else if (interval == "Weekly") {
        if (weekStartDate && weekEndDate) {
          params = {
            user: true,
            adminChart: true,
            weekStartDate: weekStartDate,
            weekEndDate: weekEndDate,
          };

          services
            .dashboardChart(params)
            .then(res => {
              res?.data?.data?.userDataOfWeek.map(item => {
                allData.push(item);
              });
              setWeekLoading(false);
              setCarbonOffsetData(allData);
            })
            .catch(e => {
              console.log(e);
            });
        }
      }
    } else if (graph == "Modes") {
      if (interval == "Yearly") {
        params = {
          mode: true,
          year: value["$y"],
        };
        setIsLoading(true);

        services
          .modeChart(params)
          .then(res => {
            setModesData(res?.data?.data?.yearData);
            setIsLoading(false);
          })
          .catch(e => {
            console.log(e);
          });
      } else if (interval == "Monthly") {
        params = {
          mode: true,
          month: value["$M"] + 1,
          year: value["$y"],
        };
        setMonthVal(value["$M"] + 1);
        setYearVal(value["$y"]);
        setIsLoading(true);

        services
          .modeChart(params)
          .then(res => {
            setModesData(res?.data?.data?.monthData);
            setIsLoading(false);
          })
          .catch(e => {
            console.log(e);
          });
      } else if (interval == "Weekly") {
        if (weekStartDate && weekEndDate) {
          params = {
            mode: true,
            weekStartDate: weekStartDate,
            weekEndDate: weekEndDate,
          };

          services
            .modeChart(params)
            .then(res => {
              setWeekLoading(false);
              setModesData(res?.data?.data?.weekData);
            })
            .catch(e => {
              console.log(e);
            });
        }
      }
    }
  }, [value, interval, graph, weekStartDate, weekEndDate]);

  return (
    <Box
      sx={{
        backgroundColor: "background.secondary",
        p: { xl: 3, lg: 3, md: 3, sm: 3, xs: 2 },
        width: 1,
        height: "100%",
      }}
    >
      {isLoading || weekLoading ? (
        <UserAndModeGraphSkeleton />
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid container>
              <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                <DropdownWrapper>
                  <Box
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      mb: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
                    }}
                  >
                    <Typography variant="titleLarge" sx={{ mr: 1 }}>
                      {graph}
                    </Typography>
                    <ArrowDropDownIcon sx={{ color: "text.darkSecondary" }} />
                  </Box>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {graphType?.map(item => {
                      return (
                        <MenuItem
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "primary.main",
                            },
                            //color: "text.lightSecondary",
                          }}
                          key={item.id}
                          onClick={() => handleClose(item.graph)}
                        >
                          {item.graph}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </DropdownWrapper>
              </Grid>

              <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                <DropdownWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      float: {
                        xl: "right",
                        lg: "right",
                        md: "right",
                        sm: "right",
                        xs: "none",
                      },
                    }}
                  >
                    <FormControl
                      sx={{
                        mr: 1,
                        background: theme => theme.palette.common.white,
                        width: "130px",
                      }}
                    >
                      <Select
                        sx={{
                          "&:focus": {
                            backgroundColor: "transparent",
                            color: "primary.main",
                          },
                        }}
                        value={interval}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={{ height: "42px" }}
                        MenuProps={{
                          sx: {
                            "& .Mui-selected": {
                              color: `${themePalette.palette.primary.main} !important`,
                            },
                          },
                        }}
                      >
                        {graphInterval.map((item, index) => {
                          return (
                            <MenuItem
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  //color: "primary.main",
                                },
                                //color: "text.lightSecondary",
                              }}
                              key={index}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {interval === "Weekly" ? <BasicDateRangePicker /> : null}

                    {interval === "Monthly" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          views={["year", "month"]}
                          minDate={new Date("2022-10-01")}
                          maxDate={new Date()}
                          value={value}
                          onChange={newValue => {
                            setValue(newValue);
                          }}
                          openTo={"month"}
                          renderInput={params => (
                            <TextField
                              sx={theme => ({
                                maxWidth: "190px",
                                borderColor: "containerBorder.secondary",
                                "& .MuiInputBase-root": {
                                  height: 42,
                                },

                                "& .MuiOutlinedInput-root": {
                                  fontFamily:
                                    '"ArchivoMedium", "sans-serif" !important',
                                  background: themePalette.palette.common.white,
                                },

                                "& .MuiOutlinedInput-root fieldset": {
                                  border: `2px solid ${themePalette.palette.common.dropdownBorder}`,
                                },
                              })}
                              onKeyDown={onKeyDown}
                              {...params}
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    ) : null}
                    {interval === "Yearly" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          views={["year"]}
                          minDate={new Date("2022-10-01")}
                          maxDate={new Date()}
                          value={value}
                          onChange={newValue => {
                            setValue(newValue);
                          }}
                          renderInput={params => (
                            <TextField
                              sx={theme => ({
                                maxWidth: "190px",
                                borderColor: `${theme.palette.containerBorder.lightSecondary}`,
                                "& .MuiInputBase-root": {
                                  height: 42,
                                },

                                "& .MuiOutlinedInput-root": {
                                  fontFamily:
                                    '"ArchivoMedium", "sans-serif" !important',
                                  background: themePalette.palette.common.white,
                                },

                                "& .MuiOutlinedInput-root fieldset": {
                                  border: `2px solid ${themePalette.palette.common.dropdownBorder}`,
                                },
                              })}
                              onKeyDown={onKeyDown}
                              {...params}
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    ) : null}
                  </Box>
                </DropdownWrapper>
              </Grid>
            </Grid>
          </Box>
          {graph === "Co2 Saved" ? (
            <CarbonOffestGraph
              info={carbonOffsetData}
              interval={interval}
              startWeek={weekStartDate}
              endWeek={weekEndDate}
              monthInfo={monthVal}
              yearInfo={yearVal}
            />
          ) : null}
          {graph === "User" ? (
            <UserAdminGraph
              info={carbonOffsetData}
              interval={interval}
              startWeek={weekStartDate}
              endWeek={weekEndDate}
              monthInfo={monthVal}
              yearInfo={yearVal}
            />
          ) : null}
          {graph === "Modes" ? (
            <ModeGraph
              info={modesData}
              interval={interval}
              startWeek={weekStartDate}
              endWeek={weekEndDate}
              monthInfo={monthVal}
              yearInfo={yearVal}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default UserAndMode;
