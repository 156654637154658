import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "../slice/dashboard-slice";
import authSlice from "../slice/auth-slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const authPersistedReducer = persistReducer(persistConfig, authSlice.reducer);

export const store = configureStore({
  reducer: {
    auth: authPersistedReducer,
  },
});

export const persistor = persistStore(store);
