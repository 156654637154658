export const isValidEmail = email => {
  if (email.length > 320) return false;
  let expression = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  return expression.test(String(email).toLowerCase());
};

export const isValidPassword = password => {
  if (password == "" || password == undefined || password == null) return false;
  if (password.length < 8 || password.length > 20) return false;
  var regularExpression = /^(?=.*[0-9])/;
  return regularExpression.test(String(password));
};

export const isEmptyOrNull = input => {
  return input === null || input === undefined || input === "";
};
