export const graphType = [
  {
    id: 0,
    graph: "Co2 Saved",
  },
  {
    id: 1,
    graph: "User",
  },
  {
    id: 2,
    graph: "Modes",
  },
];
