import { Button, Box } from "@mui/material";
import React from "react";

export default function VerifyEmail({ url, tokenMobile, keyMobile }) {
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }
  return (
    <>
      {/*<Box sx={{ textAlign: "center" }}>
        <Button
          sx={{ width: "200px", my: 5 }}
          onClick={() => {
            window.open(url);
          }}
        >
          Open Travelrite
        </Button>
      </Box>*/}
      <Box sx={{ textAlign: "center" }}>
        <Button
          sx={{ width: "200px", my: 5 }}
          onClick={() => {
            if (getMobileOperatingSystem() === "Android") {
              // const url = `intent://admin.dev.travelrite.org/#Intent;scheme=https;package=com.mb.travelrite.dev;end`;
              const url = process.env.REACT_APP_ANDROID_DEEP_LINK_PREFIX;
              console.log("Android", url);
              window.location.replace(url);
            } else if (getMobileOperatingSystem() === "iOS") {
              const url = `${process.env.REACT_APP_IOS_DEEP_LINK_PREFIX}://?token=${tokenMobile}&key=${keyMobile}`;
              console.log("Ios", url);
              window.location.replace(url);
            } else {
              console.log("error-detcting");
            }
          }}
        >
          Open Travelrite
        </Button>
      </Box>
    </>
  );
}
