import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import theme from "../../../constants/Theme";
import { PanelSkeleton } from "./Profile Skeleton/panelSkeleton";
import { ProfileCardSkeleton } from "./Profile Skeleton/profileCardSkeleton";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import UserProfileCard from "../../../components/UserProfileCard";
import CommunityPosts from "../../../components/CommunityPosts";
import PointsChart from "../../../components/PointsChart";
import ROUTES_PATH from "../../../constants/routes";
import { AddFAQWrapper } from "../../FAQ/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BlockIcon from "@mui/icons-material/Block";
import defaultuser from "../assets/defaultuser.png";
import DashboardLayout from "../../../layout/DashboardLayout/DashboardLayout";
import CommonDialog from "../../../common/commonDialog/CommonDialog";
import services from "../../../services";
import { messages } from "../../../constants/message/messages";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const UserProfile = ({}) => {
  const [singleUserData, setSingleUserData] = React.useState({});
  const [errorSnackbar, setErrorSnackBar] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [progressStatus, setProgressStatus] = React.useState(false);
  const [isPanelLoading, setPanelLoading] = React.useState(true);
  const [isCardLoading, setCardLoading] = React.useState(true);
  const userData = useLocation();

  const [values, setValues] = React.useState({
    reasonText: "",
    error: true,
    isReasonError: false,
    reasonErrorMsg: "",
  });

  const [blockedStatus, setBlockedStatus] = React.useState(
    singleUserData?.blocked
  );

  const handleClickOpen = e => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setValues({
      reasonText: "",
      error: true,
      isReasonError: false,
      reasonErrorMsg: "",
    });
  };

  const handleReasonChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleReasonChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const validate = () => {
    let { reasonText } = values;
    if (reasonText.trim() === "") {
      handleReasonChangeValue("isReasonError", true);
      handleReasonChangeValue("reasonErrorMsg", messages.reasonRequired);
      return false;
    } else if (reasonText.trim().length < 3) {
      handleReasonChangeValue("isReasonError", true);
      handleReasonChangeValue("reasonErrorMsg", messages.validReasonRequired);
      return false;
    } else if (reasonText.trim().length > 300) {
      handleReasonChangeValue("isReasonError", true);
      handleReasonChangeValue("reasonErrorMsg", messages.validReasonRequired);
      return false;
    } else {
      return true;
    }
  };

  const handleBlockstatus = () => {
    if (blockedStatus === false) {
      if (validate()) {
        setProgressStatus(true);
        let blockedUser = {
          id: `${userData?.state?.user}`,
          status: !blockedStatus,
          reason: values.reasonText,
        };

        services.changeUserActiveStatus(blockedUser).then(res => {
          if (res.data.statusCode === 200) {
            setBlockedStatus(!blockedStatus);
            setProgressStatus(false);
            setOpen(false);
            setValues(prev => ({ ...prev, reasonText: "" }));
          } else {
            setErrorSnackBar(true);
            setProgressStatus(false);
            setOpen(false);
            setValues(prev => ({ ...prev, reasonText: "" }));
          }
        });
      }
    } else {
      setProgressStatus(true);
      let blockedUser = {
        id: `${userData?.state?.user}`,
        status: !blockedStatus,
      };

      services.changeUserActiveStatus(blockedUser).then(res => {
        if (res.data.statusCode === 200) {
          setBlockedStatus(!blockedStatus);
          setProgressStatus(false);
          setOpen(false);
        } else {
          setErrorSnackBar(true);
          setProgressStatus(false);
          setOpen(false);
        }
      });
    }
  };

  const getSingleUser = () => {
    const userId = userData?.state?.user;
    services.singleUser(userId).then(res => {
      setPanelLoading(true);
      setCardLoading(true);

      if (res.status === 200) {
        setSingleUserData(res?.data?.data);
        setBlockedStatus(res?.data?.data.blocked);
        setPanelLoading(false);
        setCardLoading(false);
      }
    });
  };

  useEffect(() => {
    getSingleUser();
  }, []);

  return (
    <DashboardLayout>
      <Box
        sx={{
          p: 1,
          pt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
        }}
      >
        {isPanelLoading ? (
          <PanelSkeleton />
        ) : (
          <Grid container>
            <Grid item xl={9} lg={9} md={6} sm={6} xs={8}>
              <Link to={ROUTES_PATH.DASHBOARD + "/users"}>
                <ArrowBackIcon
                  sx={{
                    fontSize: 30,
                    position: "relative",
                    top: "5px",
                    color: theme => theme.palette.primary.main,
                  }}
                />
              </Link>
              <Typography
                variant="heading2"
                sx={{
                  mb: 1,
                  ml: 3,
                }}
              >
                {singleUserData?.firstName + " " + singleUserData?.lastName}
              </Typography>
            </Grid>

            <Grid item xl={3} lg={3} md={6} sm={6} xs={4}>
              <AddFAQWrapper>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{
                    py: 3,
                    width: "120px",
                    fontSize: 18,
                    float: {
                      xl: "right",
                      lg: "right",
                      md: "right",
                      sm: "right",
                      xs: "none",
                    },
                    mt: 0,
                  }}
                  onClick={e => {
                    handleClickOpen();
                  }}
                >
                  {!blockedStatus ? "Block" : "Unblock"}
                </Button>
              </AddFAQWrapper>
            </Grid>
          </Grid>
        )}

        <Stack>
          <Box
            sx={{
              [theme.breakpoints.up("xl")]: {
                maxWidth: theme.breakpoints.values.lg,
              },
              m: "0 auto",
              width: "100%",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <Grid container spacing={3}>
                  {isCardLoading ? (
                    <ProfileCardSkeleton />
                  ) : (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <UserProfileCard
                        profileImg={singleUserData?.profileImage || defaultuser}
                        fullName={
                          singleUserData?.firstName +
                          " " +
                          singleUserData?.lastName
                        }
                        contact={
                          singleUserData?.mobileNo || "+1-(524)-584-4587"
                        }
                        email={
                          singleUserData?.email || "kevincarroll@gmail.com"
                        }
                        questionAnswer={
                          singleUserData?.onBoradingAnswerModel?.length
                            ? singleUserData?.onBoradingAnswerModel
                            : "Skipped"
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <PointsChart userId={userData?.state?.user} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <CommunityPosts />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>

      <CommonDialog
        open={open}
        title={!blockedStatus ? "Block User" : "Unblock User"}
        subtext={
          !blockedStatus
            ? "Are you sure you want to block the user?"
            : "Are you sure you want to unblock the user?"
        }
        reasonTitle={
          !blockedStatus && (
            <TextField
              id="outlined-multiline-static"
              label="Reason"
              multiline
              rows={2}
              inputProps={{ maxLength: 300 }}
              sx={{
                mt: 3,
                width: 1,

                "& .MuiFormHelperText-root": {
                  textAlign: values.reasonErrorMsg ? "left" : "right",
                  color: values.reasonErrorMsg
                    ? theme => theme.palette.error.main
                    : theme => theme.palette.text.secondary,
                },
              }}
              value={values.reasonText}
              onFocus={() => {
                handleReasonChangeValue("isReasonError", false);
                handleReasonChangeValue("reasonErrorMsg", "");
              }}
              onChange={handleReasonChange("reasonText")}
              error={values.isReasonError}
              helperText={
                values.reasonErrorMsg
                  ? values.reasonErrorMsg
                  : `${values.reasonText.length}/300`
              }
            />
          )
        }
        iconSpacing={"24px"}
        dialogSize={"xs"}
        dialogIcon={
          <BlockIcon
            sx={{
              fontSize: 28,
              color: theme => theme.palette.primary.main,
              opacity: 0.8,
            }}
          />
        }
        dialogAction={
          <React.Fragment>
            <Button
              variant="outlined"
              disabled={progressStatus}
              sx={{
                border: `1px solid ${theme => theme.palette.primary.main}`,
                background: theme => theme.palette.common.white,
                fontSize: 18,
                py: 3,
                mr: 3,
              }}
              onClick={handleClickClose}
            >
              No
            </Button>

            <Button
              onClick={() => handleBlockstatus()}
              variant="contained"
              sx={{ fontSize: 18, py: 3 }}
            >
              {progressStatus ? (
                <>
                  <CircularProgress
                    size="1rem"
                    color="inherit"
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Yes
                </>
              ) : (
                "Yes"
              )}
            </Button>
          </React.Fragment>
        }
      />

      <Snackbar
        open={errorSnackbar}
        autoHideDuration={4000}
        onClose={() => setErrorSnackBar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorSnackBar(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {messages.somethingWentWrong}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
};

export default UserProfile;
