import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
  FormControl,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ROUTES_PATH from "../../../constants/routes";
import theme from "../../../constants/Theme";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { PasswordFieldWrapper } from "../../ChangePassword/styles";
import CommonDialog from "../../../common/commonDialog/CommonDialog";
import services from "../../../services";
import { isEmptyOrNull } from "../../../utils/inputvalidation";
import { messages } from "../../../constants/message/messages";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const EditFAQ = ({
  backgroundColor,
  borderRadius,
  faqFormHeading,
  labelColor,
  questionLabel,
  questionPlaceholder,
  answerLabel,
  answerPlaceholder,
  answerMaxRows,
  editFAQButton,
  faqModalTitle,
  faqModalSubText,
  faqModalSize,
  faqModalButton,
}) => {
  const [loader, setLoader] = React.useState(false);
  const [values, setValues] = React.useState({
    questionTitle: "",
    answerTitle: "",
    error: true,
    isQuestionError: false,
    isAnswerError: false,
    questionErrorMsg: "",
    answerErrorMsg: "",
  });
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const faqData = useLocation();
  const { faq } = faqData.state;

  useEffect(() => {
    setValues({
      questionTitle: faq.questionTitle,
      answerTitle: faq.answerTitle,
    });
  }, []);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoader(true);
      let { questionTitle, answerTitle } = values;
      let params = {
        id: faq.id,
        questionTitle: questionTitle.trim(),
        answerTitle: answerTitle.trim(),
      };

      services.updateFAQ(params).then(res => {
        setLoader(false);
        setOpen(true);
      });
    }
  };

  const validate = () => {
    let { questionTitle, answerTitle } = values;

    if (isEmptyOrNull(questionTitle)) {
      handleChangeValue("isQuestionError", true);
      handleChangeValue("questionErrorMsg", messages.faqQuestionRequired);
      return false;
    } else if (isEmptyOrNull(answerTitle)) {
      handleChangeValue("isAnswerError", true);
      handleChangeValue("answerErrorMsg", messages.faqAnswerRequired);
      return false;
    } else {
      return true;
    }
  };

  const handleClickClose = () => {
    setOpen(false);
    navigate(ROUTES_PATH.DASHBOARD + "/faq");
  };

  return (
    <>
      <Backdrop
        sx={{
          color: theme => theme.palette.common.white,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack sx={styles.centeredContent}>
            <Box
              sx={{
                backgroundColor: backgroundColor,
                px: { xl: 10, lg: 10, md: 6, sm: 3, xs: 0 },
                pt: 4,
                pb: { xl: 6, lg: 6, md: 4, sm: 3, xs: 3 },
                mt: 4,
                mb: 0,
                borderRadius: `${borderRadius}px`,
                width: 1,
                maxWidth: theme.breakpoints.values.lg,
              }}
            >
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography
                      variant="label_xlarge"
                      sx={{
                        mb: 1,
                        ml: 3,
                      }}
                    >
                      {faqFormHeading}
                    </Typography>

                    <Grid container>
                      <Grid item xl={8} lg={8} md={8} sm={10} xs={10}>
                        <PasswordFieldWrapper>
                          <form onSubmit={handleSubmit}>
                            <FormControl sx={{ width: 1, mt: 5, ml: 3 }}>
                              <Typography
                                variant="heading_regular"
                                sx={{
                                  mb: "5px",
                                  color: labelColor,
                                }}
                              >
                                {questionLabel}
                              </Typography>

                              <TextField
                                variant="outlined"
                                type="text"
                                placeholder={questionPlaceholder}
                                value={values.questionTitle}
                                onFocus={() => {
                                  handleChangeValue("isQuestionError", false);
                                  handleChangeValue("questionErrorMsg", "");
                                }}
                                onChange={handleChange("questionTitle")}
                                error={values.isQuestionError}
                                helperText={values.questionErrorMsg}
                                sx={{ mb: 2 }}
                              />

                              <Typography
                                variant="heading_regular"
                                sx={{
                                  mb: "5px",
                                  mt: 1,
                                  color: labelColor,
                                }}
                              >
                                {answerLabel}
                              </Typography>

                              <TextField
                                variant="outlined"
                                type="text"
                                multiline
                                rows={answerMaxRows}
                                maxRows={answerMaxRows}
                                placeholder={answerPlaceholder}
                                value={values.answerTitle}
                                onFocus={() => {
                                  handleChangeValue("isAnswerError", false);
                                  handleChangeValue("answerErrorMsg", "");
                                }}
                                onChange={handleChange("answerTitle")}
                                error={values.isAnswerError}
                                helperText={values.answerErrorMsg}
                                sx={{ mb: 5 }}
                              />

                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                  py: "28px",
                                  mt: 11,
                                  width: "185px",
                                }}
                              >
                                {editFAQButton}
                              </Button>

                              <CommonDialog
                                open={open}
                                title={faqModalTitle}
                                subtext={faqModalSubText}
                                iconSpacing={"24px"}
                                dialogSize={faqModalSize}
                                dialogIcon={
                                  <ForumOutlinedIcon
                                    sx={{
                                      fontSize: 28,
                                      color: theme =>
                                        theme.palette.primary.main,
                                      opacity: 0.8,
                                    }}
                                  />
                                }
                                dialogAction={
                                  <React.Fragment>
                                    <Button
                                      variant="contained"
                                      onClick={handleClickClose}
                                      sx={{ fontSize: 19, py: 3 }}
                                    >
                                      {faqModalButton}
                                    </Button>
                                  </React.Fragment>
                                }
                              />
                            </FormControl>
                          </form>
                        </PasswordFieldWrapper>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

EditFAQ.defaultProps = {
  backgroundColor: theme => theme.palette.common.tabTableBackground,
  borderRadius: 5,
  faqFormHeading: "Add Question & Answer",
  labelColor: theme => theme.palette.text.darkSecondary,
  questionLabel: "Question",
  questionPlaceholder:
    "Enter a frequently asked question, e.g “What is Carbon Credits?",
  answerLabel: "Answer",
  answerPlaceholder: "Enter the answer here...",
  answerMaxRows: 10,
  editFAQButton: "Update FAQ",
  faqModalTitle: "FAQ Updated!",
  faqModalSubText: "You have successfully updated the FAQ.",
  faqModalSize: "xs",
  faqModalButton: "Close",
};

EditFAQ.propTypes = {
  /**
  Change background color of edit faq component
  */
  backgroundColor: PropTypes.string.isRequired,

  /**
  Change border radius of edit faq component
  */
  borderRadius: PropTypes.number.isRequired,

  /**
  Change form heading of Component
  */
  faqFormHeading: PropTypes.string.isRequired,

  /**
  Change color of label(above input field)
  */
  labelColor: PropTypes.string.isRequired,

  /**
  Change text of Question Label
  */
  questionLabel: PropTypes.string.isRequired,

  /**
  Change placeholder text of Question Input Field
  */
  questionPlaceholder: PropTypes.string.isRequired,

  /**
  Change text of Answer Label
  */
  answerLabel: PropTypes.string.isRequired,

  /**
  Change placeholder text of Answer Input Field
  */
  answerPlaceholder: PropTypes.string.isRequired,

  /**
  Change max rows value for Answer Input Field
  */
  answerMaxRows: PropTypes.number.isRequired,

  /**
  Change text of FAQ button
  */
  editFAQButton: PropTypes.string.isRequired,

  /**
  Change title of faq modal
  */
  faqModalTitle: PropTypes.string.isRequired,

  /**
  Change subtext of faq modal
  */
  faqModalSubText: PropTypes.string.isRequired,

  /**
  Change size of faq modal
  */
  faqModalSize: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),

  /**
  Change button text of faq modal
  */
  faqModalButton: PropTypes.string.isRequired,
};

export default EditFAQ;
