export const allTerms = [
  {
    termId: 1,
    termText:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry and has been the industry's standard dummy text ever since the 1500s",
  },
  {
    termId: 2,
    termText:
      "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. .",
  },
  {
    termId: 3,
    termText:
      "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software",
  },
  {
    termId: 4,
    termText:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
  },
  {
    termId: 5,
    termText:
      "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
  },

  {
    termId: 6,
    termText:
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
  },
  {
    termId: 7,
    termText:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
  },
  {
    termId: 8,
    termText:
      "Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of 'de Finibus Bonorum et Malorum' (The Extremes of Good and Evil) by Cicero, written in 45 BC.",
  },
  {
    termId: 9,
    termText:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
  },

  {
    termId: 10,
    termText:
      "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  },
];
