import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultSelected: "Dashboard",
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    selectedTab(state, action) {
      state.defaultSelected = action.payload;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice;
