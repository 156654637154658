import React from "react";
import { Box, Skeleton } from "@mui/material";
const TodaysStatsSkeleton = () => {
  //const statsSkeleton = [1, 2, 3, 4, 5];
  const statsSkeleton = [1, 2, 3, 4];
  return (
    <Box sx={{ width: 1, display: "flex", flexDirection: "column" }}>
      <Skeleton width="60%" height="30px" animation="wave" />
      {statsSkeleton.map((item, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width="100%"
          height="56px"
          animation="wave"
          sx={{ my: 1, mt: 2.6, borderRadius: "4px" }}
        />
      ))}
    </Box>
  );
};

export default TodaysStatsSkeleton;
