import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";
import { themeTypography } from "./typography";
import { themeButtons } from "./button";
import { themeInputs } from "./input";
import { themeTabs } from "./tabs";
import { themeTable } from "./tables";
import { themeSelect } from "./select";
import { themeLayout } from "./layout";

const theme = createTheme(
  themeButtons,
  themePalette,
  themeTypography,
  themeInputs,
  themeTabs,
  themeTable,
  themeSelect,
  themeLayout
);

export default theme;
