import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeTypography = createTheme(themePalette, {
  typography: {
    brand: {
      "@media (max-width:1540px)": {
        fontFamily: "'ArchivoMedium', sans-serif",
        fontSize: "32px",
      },
    },
    heading1_xlarge: {
      fontFamily: "'ArchivoSemiBold', sans-serif",
      fontSize: "32px",
    },
    heading1: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "28px",
    },
    heading_semiBold: {
      fontFamily: "'ArchivoSemiBold', sans-serif",
      fontSize: "26px",
    },
    heading2: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "26px",
    },
    heading3_large: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "24px",
    },
    heading3: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "20px",

      "@media (max-width:1100px)": {
        fontSize: "18px",
      },
    },
    heading_medium: {
      fontFamily: "'ArchivoRegular', sans-serif",
      fontSize: "20px",
    },
    heading_default: {
      fontFamily: "'ArchivoRegular', sans-serif",
      fontSize: "18px",

      "@media (max-width:1100px)": {
        fontSize: "16px",
      },
    },
    heading_regular: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "18px",
    },
    heading_small: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "16px",
    },
    heading4: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "16px",
    },
    heading5: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "14px",
    },
    heading6: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "13px",
    },
    titleLarge: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "24px",
    },
    titleMedium: {
      fontFamily: "'ArchivoMedium', sans-serif",
      fontSize: "22px",
    },
    subtitle: {
      fontFamily: "'ArchivoRegular', sans-serif",
      fontSize: "24px",
    },
    label_xlarge: {
      fontFamily: "'AsapMedium', sans-serif",
      fontSize: "26px",
    },
    label_small: {
      fontFamily: "'AsapMedium', sans-serif",
      fontSize: "16px",
    },
    body_xlarge: {
      fontFamily: "'AsapRegular', sans-serif",
      fontSize: "22px",
    },
    body_large: {
      fontFamily: "'AsapRegular', sans-serif",
      fontSize: "20px",
    },
    body_regular: {
      fontFamily: "'AsapRegular', sans-serif",
      fontSize: "18px",
    },
    body_medium: {
      fontFamily: "'AsapRegular', sans-serif",
      fontSize: "16px",

      "@media (max-width:1100px)": {
        fontSize: "14px",
      },
    },
    body_small: {
      fontFamily: "'AsapRegular', sans-serif",
      fontSize: "14px",
    },
  },

  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
  },
});
